import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { admin_api } from "../../atoms/api";
import { Transactions as OrgTransaction } from "../../organisms/transactions";
import PageItem from "react-bootstrap/PageItem";
import { formatDate } from "../../atoms/formatDate";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";

export class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "complete",
      completeTransactions: [],
      completeCount: 0,
      completeSize: 20,
      completePage: 0,
      completeTotal: 0,
      getCompleteLoading: true,
      getCompleteError: null,
      incompleteTransactions: [],
      incompleteCount: 0,
      incompleteSize: 20,
      incompletePage: 0,
      incompleteTotal: 0,
      getIncompleteLoading: true,
      getIncompleteError: null,
      report: false,
      reportLoading: false,
      reportError: null,
    };
  }

  componentDidMount() {
    this.getCompleteTransactions(
      this.state.completeSize,
      this.state.completePage
    );
    this.getIncompleteTransactions(
      this.state.incompleteSize,
      this.state.incompletePage
    );
  }

  getCompleteTransactions = (size, page) => {
    this.setState({ getCompleteLoading: true, getCompleteError: null });
    admin_api
      .get(`transaction/all/paid?size=${size}&page=${page}`)
      .then((res) => {
        this.setState({
          getCompleteLoading: false,
          getCompleteError: null,
          completeTransactions: res.data.rows,
          completeCount: res.data.count,
          completeTotal: Math.ceil(res.data.count / size),
        });
      })
      .catch((err) => {
        this.setState({
          getCompleteLoading: false,
          getCompleteError:
            err?.response?.data?.message || "Error getting paid transactions",
        });
      });
  };

  getIncompleteTransactions = (size, page) => {
    this.setState({ getIncompleteLoading: true, getIncompleteError: null });
    admin_api
      .get(`transaction/all/unpaid?size=${size}&page=${page}`)
      .then((res) => {
        this.setState({
          getIncompleteLoading: false,
          getIncompleteError: null,
          incompleteTransactions: res.data.rows,
          incompleteCount: res.data.count,
          incompleteTotal: Math.ceil(res.data.count / size),
        });
      })
      .catch((err) => {
        this.setState({
          getIncompleteLoading: false,
          getIncompleteError:
            err?.response?.data?.message ||
            "Error getting incomplete enrolments",
        });
      });
  };

  changeCompletePage = (n) => {
    this.setState({ completePage: n });
    this.getCompleteTransactions(this.state.completeSize, n);
  };

  changeCompleteSize = (e) => {
    this.setState({ completeSize: e.currentTarget.value });
    this.getCompleteTransactions(
      e.currentTarget.value,
      this.state.completePage
    );
  };

  changeIncompletePage = (n) => {
    this.setState({ incompletePage: n });
    this.getIncompleteTransactions(this.state.incompleteSize, n);
  };

  changeIncompleteSize = (e) => {
    this.setState({ incompleteSize: e.currentTarget.value });
    this.getIncompleteTransactions(
      e.currentTarget.value,
      this.state.incompletePage
    );
  };

  setSelectedTab = (tab) => this.setState({ selectedTab: tab });
  dismissGetCompleteError = () => this.setState({ getCompleteError: null });
  dismissGetIncompleteError = () => this.setState({ getIncompleteError: null });
  dismissReportError = () => this.setState({ reportError: null });

  getProgress = (current, total) => {
    if (!total) return 0;
    return Math.ceil((current / total) * 100);
  };

  renderPageItems = (page, total, changePage) => {
    let items = [];
    for (let i = 0; i < total; i++) {
      items.push(
        <PageItem key={i} active={i === page} onClick={() => changePage(i)}>
          {i + 1}
        </PageItem>
      );
    }
    return items;
  };

  openReport = () => this.setState({ report: true });
  closeReport = () => this.setState({ report: false });

  generateReport = (monthYear) => {
    this.setState({ reportLoading: true });
    let date = new Date(monthYear),
      y = date.getFullYear(),
      m = date.getMonth();
    let startDate = new Date(y, m, 1),
      endDate = new Date(y, m + 1, 0);
    startDate.setDate(1);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    admin_api
      .post("/transaction/create/annual-report", { startDate, endDate })
      .then((res) => {
        this.setState({ reportLoading: false, reportError: null });
        if (res.data?.data?.length > 0) {
          let csvContent =
            "data:text/csv;charset=utf-8,Purchase date,Purchase time,Program name,Purchased by,Amount,Discount,Discount code used\r\n";
          res.data.data.forEach((row) => {
            let csvRow = Object.values(row).join(",");
            csvContent += csvRow + "\r\n";
          });
          csvContent += `,,,Total,${res.data?.total_amount},\r\n`;
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "my_data.csv");
          document.body.appendChild(link); // Required for FF
          link.click(); // This will download the data file named "my_data.csv".}
        } else alert("No transactions for this month");
      })
      .catch((err) => {
        this.setState({
          reportError: err?.response?.data?.message || "Error getting report",
          reportLoading: false,
        });
      });
  };

  render() {
    return (
      <Container fluid={true}>
        <Tabs
          activeKey={this.state.selectedTab}
          onSelect={(k) => this.setSelectedTab(k)}
          className="mb-3"
        >
          <Tab eventKey="complete" title="Paid">
            <OrgTransaction
              paid={true}
              openReport={this.openReport}
              loading={this.state.getCompleteLoading}
              error={this.state.getCompleteError}
              dismissGetError={this.dismissGetCompleteError}
              transactions={this.state.completeTransactions}
              count={this.state.completeCount}
              formatDate={formatDate}
              page={this.state.completePage}
              size={this.state.completeSize}
              total={this.state.completeTotal}
              changePage={this.changeCompletePage}
              changeSize={this.changeCompleteSize}
              renderPageItems={this.renderPageItems}
              getProgress={this.getProgress}
              reportLoading={this.state.reportLoading}
              reportError={this.state.reportError}
              dismissReportError={this.dismissReportError}
            />
          </Tab>
          <Tab eventKey="unpaid" title="Pending">
            <OrgTransaction
              loading={this.state.getIncompleteLoading}
              error={this.state.getIncompleteError}
              dismissGetError={this.dismissGetIncompleteError}
              transactions={this.state.incompleteTransactions}
              count={this.state.incompleteCount}
              formatDate={formatDate}
              page={this.state.incompletePage}
              size={this.state.incompleteSize}
              total={this.state.incompleteTotal}
              changePage={this.changeIncompletePage}
              changeSize={this.changeIncompleteSize}
              renderPageItems={this.renderPageItems}
              getProgress={this.getProgress}
              reportLoading={this.state.reportLoading}
              reportError={this.state.reportError}
            />
          </Tab>
        </Tabs>
        <Modal show={this.state.report} onHide={this.closeReport}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Select month</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                month: `${new Date().getFullYear()}-${(
                  "0" +
                  (new Date().getMonth() + 1)
                ).slice(-2)}`,
              }}
              onSubmit={(values) => {
                this.generateReport(values.month);
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                touched,
                errors,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <FloatingLabel
                    className="mb-2"
                    controlId="month"
                    label="YYYY-MM / MMM-YYYY"
                  >
                    <FormControl
                      type="month"
                      name="month"
                      value={values.month}
                      placeholder="YYYY-MM / MMM-YYYY"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2 form-control"
                      pattern="[0-9]{4}-[0-9]{2}"
                    />
                  </FloatingLabel>
                  <Button className="text-white float-end" type="submit">
                    Generate report
                  </Button>
                </form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}
