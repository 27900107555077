import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Programs } from "../../template/programs";
import Container from "react-bootstrap/Container";
import { School } from "../../template/school";
import { AllUsers } from "../../template/allUsers";
import { Enrolment } from "../../template/enrolment";
import { Webinar } from "../../template/webinar";
import { Affiliate } from "../../template/affiliate";
import { PromoCodes } from "../../template/promocode";
import { Transactions } from "../../template/transactions";
import { useLocation } from "react-router-dom";
export const Dashboard = (props) => {
  const [selectedTab, setSelectedTab] = useState("programs");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const last_tab = searchParams.get("tab");

  useEffect(() => {
    if (last_tab) {
      setSelectedTab(last_tab);
    }
  }, [last_tab]);

  document.title = "UnTaboo | Dashboard";

  return (
    <Container fluid={true} className="pt-3">
      <Tabs
        activeKey={selectedTab}
        onSelect={(k) => setSelectedTab(k)}
        className="mb-3"
      >
        <Tab eventKey="programs" title="Programs">
          <Programs />
        </Tab>
        <Tab eventKey="users" title="Users">
          <AllUsers />
        </Tab>
        <Tab eventKey="enrolments" title="Enrolments">
          <Enrolment />
        </Tab>
        <Tab eventKey="transactions" title="Transactions">
          <Transactions />
        </Tab>
        <Tab eventKey="school" title="School">
          <School />
        </Tab>
        <Tab eventKey="affiliate" title="Affiliate">
          <Affiliate />
        </Tab>
        <Tab eventKey="promocode" title="Promo codes">
          <PromoCodes />
        </Tab>
        <Tab eventKey="webinar" title="Miscellaneous">
          <Webinar />
        </Tab>
      </Tabs>
    </Container>
  );
};
