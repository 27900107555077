import React from "react";
import styled from "styled-components";

const SImg = styled.img``;

export const UtImg = ({ src, ...props }) => (
  <SImg
    {...props}
    src={
      !!src
        ? `${process.env.REACT_APP_BACKEND_URL}/${src}`
        : "https://via.placeholder.com/100x100?text=UnTaboo"
    }
  />
);

UtImg.propTypes = {};
