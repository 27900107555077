import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { admin_api } from "../../atoms/api";
import { UserEnrollments } from "../../organisms/user-enrolments";
import { SchoolEnrollments } from "../../organisms/school-enrolments";
import PageItem from "react-bootstrap/PageItem";
import { formatDate } from "../../atoms/formatDate";

export class Enrolment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "users",
      userEnrollments: [],
      userCount: 0,
      userSize: 20,
      userPage: 1,
      userTotal: 0,
      getUserLoading: true,
      getUserError: null,
      schoolEnrollments: [],
      schoolCount: 0,
      schoolSize: 20,
      schoolPage: 1,
      schoolTotal: 0,
      getSchoolLoading: true,
      getSchoolError: null,
    };
  }

  componentDidMount() {
    this.getUserEnrollments(this.state.userSize, this.state.userPage);
    this.getSchoolEnrollments(this.state.schoolSize, this.state.schoolPage);
  }

  getUserEnrollments = (size, page) => {
    this.setState({ getUserLoading: true, getUserError: null });
    admin_api
      .get(`enrollment/admin/user?size=${size}&page=${page}`)
      .then((res) => {
        this.setState({
          getUserLoading: false,
          getUserError: null,
          userEnrollments: res.data.rows,
          userCount: res.data.count,
          userTotal: Math.ceil(res.data.count / size),
        });
      })
      .catch((err) => {
        this.setState({
          getUserLoading: false,
          getUserError:
            err?.response?.data?.message || "Error getting user enrolments",
        });
      });
  };

  getSchoolEnrollments = (size, page) => {
    this.setState({ getSchoolLoading: true, getSchoolError: null });
    admin_api
      .get(`enrollment/admin/school?size=${size}&page=${page}`)
      .then((res) => {
        this.setState({
          getSchoolLoading: false,
          getSchoolError: null,
          schoolEnrollments: res.data.rows,
          schoolCount: res.data.count,
          schoolTotal: Math.ceil(res.data.count / size),
        });
      })
      .catch((err) => {
        this.setState({
          getSchoolLoading: false,
          getSchoolError:
            err?.response?.data?.message || "Error getting school enrolments",
        });
      });
  };

  changeUserPage = (n) => {
    this.setState({ userPage: n });
    this.getUserEnrollments(this.state.userSize, n);
  };

  changeUserSize = (e) => {
    this.setState({ userSize: e.currentTarget.value });
    this.getUserEnrollments(e.currentTarget.value, this.state.userPage);
  };

  changeSchoolPage = (n) => {
    this.setState({ schoolPage: n });
    this.getSchoolEnrollments(this.state.schoolSize, n);
  };

  changeSchoolSize = (e) => {
    this.setState({ schoolSize: e.currentTarget.value });
    this.getSchoolEnrollments(e.currentTarget.value, this.state.schoolPage);
  };

  setSelectedTab = (tab) => this.setState({ selectedTab: tab });
  dismissGetUserError = () => this.setState({ getUserError: null });
  dismissGetSchoolError = () => this.setState({ getSchoolError: null });

  getProgress = (current, total) => {
    if (!total) return 0;
    return Math.ceil((current / total) * 100);
  };

  renderPageItems = (page, total, changePage) => {
    let items = [];
    for (let i = 1; i <= total; i++) {
      items.push(
        <PageItem key={i} active={i === page} onClick={() => changePage(i)}>
          {i}
        </PageItem>
      );
    }
    return items;
  };

  render() {
    return (
      <Container fluid={true}>
        <Tabs
          activeKey={this.state.selectedTab}
          onSelect={(k) => this.setSelectedTab(k)}
          className="mb-3"
        >
          <Tab eventKey="users" title="B2C">
            <UserEnrollments
              loading={this.state.getUserLoading}
              error={this.state.getUserError}
              dismissGetError={this.dismissGetUserError}
              enrollments={this.state.userEnrollments}
              count={this.state.userCount}
              formatDate={formatDate}
              page={this.state.userPage}
              size={this.state.userSize}
              total={this.state.userTotal}
              changePage={this.changeUserPage}
              changeSize={this.changeUserSize}
              renderPageItems={this.renderPageItems}
              getProgress={this.getProgress}
            />
          </Tab>
          <Tab eventKey="schools" title="B2B">
            <SchoolEnrollments
              loading={this.state.getSchoolLoading}
              error={this.state.getSchoolError}
              dismissGetError={this.dismissGetSchoolError}
              enrollments={this.state.schoolEnrollments}
              count={this.state.schoolCount}
              formatDate={formatDate}
              page={this.state.schoolPage}
              size={this.state.schoolSize}
              total={this.state.schoolTotal}
              changePage={this.changeSchoolPage}
              changeSize={this.changeSchoolSize}
              renderPageItems={this.renderPageItems}
              getProgress={this.getProgress}
            />
          </Tab>
        </Tabs>
      </Container>
    );
  }
}
