import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useLocation, useParams } from "react-router-dom";
import { admin_api } from "../../atoms/api";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  PDFDownloadLink,
  Font,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

export const InvoiceView = (props) => {
  const params = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    admin_api
      .get(`transaction/invoices/${params.transaction_id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  document.title = "UnTaboo | Dashboard";
  const styles = StyleSheet.create({
    section1: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
    },
    taxInvoice: {
      letterSpacing: "15px",
      fontSize: "14px",
      color: "#4f83e0",
      fontWeight: "900",
    },
    originalForReceipients: {
      fontSize: "14px",
      color: "#504b47",
    },
    section2: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
    },
    section3: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
    },
    section4: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
    },
    section5: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
      marginTop: "10px",
    },
    untaboo: {
      fontSize: "14px",
      fontWeight: "600",
    },
    gst: {
      fontSize: "14px",
    },
    gstNumber: {
      fontWeight: "ultrabold",
    },
    table: {
      width: "100%",
      marginTop: "20px",
    },
    row: {
      display: "flex",
      height: "85px",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "14px",
    },
    header: {
      borderBottom: "2px solid #4f83e0",
      borderTop: "2px solid #4f83e0",
    },
    bold: {
      fontWeight: "bold",
    },
    // So Declarative and unDRY 👌
    col1: {
      width: "5%",
    },
    col2: {
      marginLeft: "10px",
      width: "25%",
    },
    col3: {
      paddingLeft: "15px",
      width: "15%",
    },
    col4: {
      paddingLeft: "15px",
      width: "20%",
    },
    col5: {
      width: "27%",
    },
    margin5: {
      marginTop: "5px",
    },
    margin10: {
      marginTop: "10px",
    },
    margin20: {
      marginTop: "5%",
    },
  });

  return (
    <Container fluid={true} className="pt-3">
      <PDFViewer width="100%" height="700px">
        <Document
          title={`${data.id}-invoice.pdf`}
          author="UnTaboo"
          style={{
            margin: 0,
            padding: 0,
            boxSizing: "border-box",
          }}
        >
          <Page size="A4" orientation="landscape">
            <View wrap={false}>
              <View
                style={{
                  padding: "10px",
                }}
              >
                <View style={styles.section1}>
                  <Text style={styles.taxInvoice}>TAX INVOICE</Text>
                  <Text style={styles.originalForReceipients}>
                    ORIGINAL FOR RECIPIENT
                  </Text>
                </View>
                <View style={styles.section2}>
                  <View style={{ marginTop: "20px" }}>
                    <Text style={styles.untaboo}>
                      UNTABOO EDUCATION PVT. LTD
                    </Text>
                    <Text style={[styles.gst, styles.margin10]}>
                      GSTIN{" "}
                      <Text style={styles.gstNumber}>27AACCU4744J1Z2</Text>
                    </Text>
                    <Text style={{ marginTop: "20px" }}>
                      Ruia Park{"\n"}
                      J.R Mhatre Road, Juhu{"\n"}
                      Mumbai, MAHARASHTRA, 400049 {"\n"}
                      Mobile 9819457204{"\n"}
                      Email official.untaboo@gmail.com
                    </Text>
                  </View>
                  <View style={{ width: "150px", marginTop: "20px" }}>
                    <Image
                      src={`https://api.untaboo.online/uploads/logo.png`}
                      style={{
                        width: "100%",
                      }}
                    />
                  </View>
                </View>

                {/* Invoice Number */}
                <View style={[styles.section3, styles.margin20]}>
                  <View style={{ marginTop: "20px", fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      Invoice #: INV-{data.id}
                    </Text>
                  </View>
                  <View style={{ marginTop: "20px", fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      Invoice Date: {data.today}
                    </Text>
                  </View>
                </View>

                {/* Customer Details */}
                <View style={{ marginTop: "20px", fontSize: "14px" }}>
                  <View>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      Customer Details:
                    </Text>
                  </View>
                  <View style={[styles.margin5]}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.user?.full_name}
                    </Text>
                  </View>
                  <View>
                    <Text style={[styles.margin5, { fontWeight: "ultrabold" }]}>
                      {data.user?.email_id}
                    </Text>
                  </View>
                </View>

                {/* Place of Supply */}
                <View style={{ marginTop: "20px", fontSize: "14px" }}>
                  <View>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      Place of Supply: {data.user?.location}
                    </Text>
                  </View>
                </View>

                {/* Table */}
                <View style={styles.table}>
                  <View style={[styles.row, styles.bold, styles.header]}>
                    <Text style={styles.col1}>#</Text>
                    <Text style={styles.col2}>Item</Text>
                    <Text style={styles.col3}>Rate/Item</Text>
                    <Text style={styles.col4}>Qty</Text>
                    <Text style={styles.col5}>Taxable Value</Text>
                    <Text style={styles.col5}>Tax Amount</Text>
                    <Text style={styles.col5}>Amount</Text>
                  </View>
                  <View style={styles.row} wrap={false}>
                    <Text style={styles.col1}>
                      <Text style={styles.bold}>1</Text>
                    </Text>
                    <Text style={styles.col2}>{data?.program?.name}</Text>
                    <Text style={styles.col3}>{data?.program?.cost}</Text>
                    <Text style={styles.col4}>1 Unit</Text>
                    <Text style={styles.col5}>{data.taxable_amount}</Text>
                    <Text style={styles.col5}>
                      {data.tax_amount} ({data.tax_perc})%
                    </Text>
                    <Text style={styles.col5}>{data.total_amount}</Text>
                  </View>
                </View>

                {/* HR tag */}
                <View
                  style={{ width: "100%", border: "1px solid #c9c9c9" }}
                ></View>

                {/* GST Details */}
                {data.hasAffiliatecode && <View style={styles.section3}>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.hasAffiliatecode}
                    </Text>
                  </View>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.hasAffiliatecode_percent}
                    </Text>
                  </View>
                </View>}
                {data.hasPromoCode_value &&
                  <View style={styles.section3}>
                    <View style={{ fontSize: "14px" }}>
                      <Text style={{ fontWeight: "ultrabold" }}>
                        {data.hasPromoCode}
                      </Text>
                    </View>
                    <View style={{ fontSize: "14px" }}>
                      <Text style={{ fontWeight: "ultrabold" }}>
                        {data.hasPromoCode_value} {data.hasPromoCode_type}
                      </Text>
                    </View>
                  </View>}
                <View style={[styles.section3, { marginTop: "10px" }]}>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      Taxable Amount
                    </Text>
                  </View>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.taxable_amount} INR
                    </Text>
                  </View>
                </View>
                <View style={[styles.section3, { marginTop: "10px" }]}>
                  <View style={{ fontSize: "14px" }}>
                    <Text
                      style={{ fontWeight: "ultrabold", textAlign: "right" }}
                    >
                      CGST {data.central_tax}%
                    </Text>
                  </View>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.central_tax_amount} INR
                    </Text>
                  </View>
                </View>
                <View style={[styles.section4, { marginTop: "10px" }]}>
                  <View style={{ fontSize: "14px" }}>
                    <Text
                      style={{ fontWeight: "ultrabold", textAlign: "right" }}
                    >
                      SGST {data.state_tax}%
                    </Text>
                  </View>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.state_tax_amount} INR
                    </Text>
                  </View>
                </View>
                <View style={[styles.section4, { marginTop: "10px" }]}>
                  <View style={{ fontSize: "14px" }}>
                    <Text
                      style={{ fontWeight: "ultrabold", textAlign: "right" }}
                    >
                      IGST 0%
                    </Text>
                  </View>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.international_tax_amount} INR
                    </Text>
                  </View>
                </View>

                {/* HR tag */}
                <View
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    border: "1px solid #c9c9c9",
                  }}
                ></View>

                {/* Total Section */}
                <View style={styles.section4}>
                  <Text style={{ fontWeight: "ultrabold", textAlign: "right" }}>
                    TOTAL
                  </Text>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      {data.total_amount} INR
                    </Text>
                  </View>
                </View>
                <View style={[styles.section5, { marginTop: "20px" }]}>
                  <Text
                    style={{
                      fontWeight: "normal",
                      fontSize: "12px",
                      color: "#c9c9c9",
                    }}
                  >
                    Total Items / Qty: 1 / {"\n"} 1.00 {data.taxable_amount}
                  </Text>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontSize: "12px", textTransform: 'capitalize' }}>
                      Total amount (in words): {data.total_amount_words} Rupees
                      and{" "}{data.total_amount_words_minor} Paisa Only.
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    border: "1px solid #4f83e0",
                  }}
                ></View>
                <View style={styles.section4}>
                  <View style={{ fontSize: "14px" }}></View>
                  <View style={{ fontSize: "14px" }}>
                    <Text style={{ fontWeight: "ultrabold" }}>
                      Amount Payable: {data.total_amount} INR
                    </Text>
                  </View>
                </View>
                <View style={{ marginTop: "40px" }}>
                  <Text style={{ fontSize: "12px" }}>Notes:</Text>
                  <Text
                    style={{
                      fontSize: "12px",
                      color: "#c9c9c9",
                      marginTop: "10px",
                    }}
                  >
                    This is a computer generated invoice. No signature is
                    required
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Container>
  );
};
