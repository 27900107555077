import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { admin_api } from "../../atoms/api";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalBody from "react-bootstrap/ModalBody";
import Spinner from "react-bootstrap/Spinner";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import { RegularPrograms } from "../../organisms/regular-program";
import { SchoolPrograms } from "../../organisms/school-program";

export class Programs extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedTab: "regular",
      selectedProgramId: null,
      addProgram: false,
      addSchool: false,
      addProgramSuccess: false,
      addSchoolSuccess: false,
      addProgramLoading: false,
      addSchoolLoading: false,
      addProgramError: null,
      addSchoolError: null,
      getProgramLoading: true,
      getProgramError: null,
      programs: [],
      editProgram: false,
      editSchool: false,
      editProgramId: null,
      editSchoolId: null,
      editProgramSuccess: false,
      editSchoolSuccess: false,
      editProgramLoading: false,
      editSchoolLoading: false,
      editProgramError: null,
      editSchoolError: null,
      deleteProgram: false,
      deleteProgramId: null,
      deleteProgramSuccess: false,
      deleteProgramLoading: false,
      deleteProgramError: null,
    };
  }

  componentDidMount() {
    admin_api
      .get("/programs")
      .then((res) => {
        this.setState({
          getProgramLoading: false,
          programs: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          getProgramLoading: false,
          getProgramError: err?.response?.data?.message || "Error",
        });
      });
  }

  changeSelectedTab = (tab) => this.setState({ selectedTab: tab });

  closeAddSuccess = () => this.setState({ addProgramSuccess: false });
  closeAddSchoolSuccess = () => this.setState({ addSchoolSuccess: false });
  closeEditSuccess = () => this.setState({ editProgramSuccess: false });
  closeEditSchoolSuccess = () => this.setState({ editSchoolSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteProgramSuccess: false });

  handleAddShow = () => this.setState({ addProgram: true });
  handleAddSchoolShow = () => this.setState({ addSchool: true });
  handleAddClose = () => this.setState({ addProgram: false });
  handleAddSchoolClose = () => this.setState({ addSchool: false });
  handleEditShow = (programId) =>
    this.setState({ editProgram: true, editProgramId: programId });
  handleEditSchoolShow = (programId) =>
    this.setState({ editSchool: true, editSchoolId: programId });
  handleEditClose = () =>
    this.setState({ editProgram: false, editProgramId: null });
  handleEditSchoolClose = () =>
    this.setState({ editSchool: false, editSchoolId: null });
  handleDeleteShow = (programId) =>
    this.setState({ deleteProgram: true, deleteProgramId: programId });
  handleDeleteClose = () =>
    this.setState({ deleteProgram: false, deleteProgramId: null });

  handleDelete = () => {
    this.setState({ deleteProgramLoading: true, deleteProgram: false });
    admin_api
      .delete(`/programs/${this.state.deleteProgramId}`)
      .then((res) => {
        let programs = this.state.programs.filter(
          (p) => p["id"] !== this.state.deleteProgramId
        );
        this.setState({
          deleteProgramId: null,
          deleteProgramLoading: false,
          deleteProgramSuccess: true,
          programs,
        });
      })
      .catch((err) =>
        this.setState({
          deleteProgramId: null,
          deleteProgramLoading: false,
          deleteProgramError: err?.response?.data?.message || "Error",
        })
      );
  };

  handleAdd = (values) => {
    this.setState({ addProgramLoading: true });
    let values_form_data = new FormData();
    Object.keys(values).forEach((key) =>
      values_form_data.append(key, values[key])
    );
    admin_api
      .post("/programs", values_form_data)
      .then((res) => {
        let programs = this.state.programs;
        programs.push(res.data);
        this.setState({
          addProgram: false,
          addProgramLoading: false,
          addProgramSuccess: true,
          programs,
        });
      })
      .catch((err) => {
        this.setState({
          addProgram: false,
          addProgramLoading: false,
          addProgramError: err?.response?.data?.message || "Error",
        });
      });
  };

  handleSchoolAdd = (values) => {
    this.setState({ addSchoolLoading: true });
    let values_form_data = new FormData();
    Object.keys(values).forEach((key) =>
      values_form_data.append(key, values[key])
    );
    admin_api
      .post("/programs", values_form_data)
      .then((res) => {
        let programs = this.state.programs;
        programs.push(res.data);
        this.setState({
          addSchool: false,
          addSchoolLoading: false,
          addSchoolSuccess: true,
          programs,
        });
      })
      .catch((err) => {
        this.setState({
          addSchool: false,
          addSchoolLoading: false,
          addSchoolError: err?.response?.data?.message || "Error",
        });
      });
  };

  handleDuplicateAdd = (values) => {
    this.setState({ addSchoolLoading: true });
    admin_api
      .post("/programs/duplicate", values)
      .then((res) => {
        let programs = this.state.programs;
        programs.push(res.data);
        this.setState({
          addSchool: false,
          addSchoolLoading: false,
          addSchoolSuccess: true,
          programs,
        });
      })
      .catch((err) => {
        this.setState({
          addSchool: false,
          addSchoolLoading: false,
          addSchoolError: err?.response?.data?.message || "Error",
        });
      });
  };

  handleEdit = (values) => {
    this.setState({ editProgramLoading: true });
    let values_form_data = new FormData();
    Object.keys(values)
      .filter((key) => key !== "grade")
      .forEach((key) => values_form_data.append(key, values[key]));
    admin_api
      .patch(`/programs/${this.state.editProgramId}`, values_form_data)
      .then((res) => {
        let programs = this.state.programs;
        programs = programs.map((p) => {
          if (p["id"] === this.state.editProgramId) {
            return { ...values, id: this.state.editProgramId };
          } else return p;
        });
        this.setState({
          editProgram: false,
          editProgramId: null,
          editProgramLoading: false,
          editProgramSuccess: true,
          programs,
        });
      })
      .catch((err) => {
        this.setState({
          editProgram: false,
          editProgramId: null,
          editProgramLoading: false,
          editProgramError: err?.response?.data?.message || "Error",
        });
      });
  };

  handleSchoolEdit = (values) => {
    this.setState({ editSchoolLoading: true });
    let values_form_data = new FormData();
    Object.keys(values).forEach((key) =>
      values_form_data.append(key, values[key])
    );
    admin_api
      .patch(`/programs/${this.state.editSchoolId}`, values_form_data)
      .then((res) => {
        let programs = this.state.programs;
        programs = programs.map((p) => {
          if (p["id"] === this.state.editSchoolId) {
            return { ...values, id: this.state.editSchoolId };
          } else return p;
        });
        this.setState({
          editSchool: false,
          editSchoolId: null,
          editSchoolLoading: false,
          editSchoolSuccess: true,
          programs,
        });
      })
      .catch((err) => {
        this.setState({
          editSchool: false,
          editSchoolId: null,
          editSchoolLoading: false,
          editSchoolError: err?.response?.data?.message || "Error",
        });
      });
  };

  dismissAddProgramError = () => this.setState({ addProgramError: null });
  dismissGetProgramError = () => this.setState({ getProgramError: null });
  dismissEditProgramError = () => this.setState({ editProgramError: null });
  dismissDeleteProgramError = () => this.setState({ deleteProgramError: null });
  dismissAddSchoolError = () => this.setState({ addSchoolError: null });
  dismissEditSchoolError = () => this.setState({ editSchoolError: null });

  seeProgram = (programId) => this.setState({ selectedProgramId: programId });

  render() {
    if (this.state.selectedProgramId !== null) {
      return <Navigate to={`/program/${this.state.selectedProgramId}`} />;
    } else {
      return (
        <Container fluid={true} className="position-relative">
          <ToastContainer position="top-end" className="pt-2 pe-md-1">
            <Toast show={this.state.getProgramLoading} bg="info">
              <ToastBody>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Getting programs
              </ToastBody>
            </Toast>
            <Toast
              show={this.state.getProgramError !== null}
              bg="danger"
              onClose={this.dismissGetProgramError}
            >
              <ToastHeader closeButton={true}>
                <strong className="me-auto">Fetch error</strong>
              </ToastHeader>
              <ToastBody>{this.state.getProgramError}</ToastBody>
            </Toast>
            <Toast
              show={this.state.deleteProgramError !== null}
              bg="danger"
              onClose={this.dismissDeleteProgramError}
            >
              <ToastHeader closeButton={true}>
                <strong className="me-auto">Deleting error</strong>
              </ToastHeader>
              <ToastBody>{this.state.deleteProgramError}</ToastBody>
            </Toast>
            <Toast
              show={this.state.deleteProgramSuccess}
              onClose={this.closeDeleteSuccess}
              bg="success"
              delay={2000}
              autohide={true}
            >
              <ToastBody className="text-white">
                Program deleted successfully
              </ToastBody>
            </Toast>
            <Toast show={this.state.deleteProgramLoading} bg="info">
              <ToastBody>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Deleting program
              </ToastBody>
            </Toast>
          </ToastContainer>
          <Tabs
            activeKey={this.state.selectedTab}
            onSelect={(k) => this.changeSelectedTab(k)}
            className="mb-3"
          >
            <Tab eventKey="regular" title="Regular programs">
              <RegularPrograms
                addProgram={this.state.addProgram}
                addProgramSuccess={this.state.addProgramSuccess}
                addProgramLoading={this.state.addProgramLoading}
                addProgramError={this.state.addProgramError}
                programs={this.state.programs.filter(
                  (p) => p.type === "regular"
                )}
                editProgram={this.state.editProgram}
                editProgramId={this.state.editProgramId}
                editProgramSuccess={this.state.editProgramSuccess}
                editProgramLoading={this.state.editProgramLoading}
                editProgramError={this.state.editProgramError}
                closeAddSuccess={this.closeAddSuccess}
                closeEditSuccess={this.closeEditSuccess}
                closeEditSchoolSuccess={this.closeEditSchoolSuccess}
                closeDeleteSuccess={this.closeDeleteSuccess}
                handleAddShow={this.handleAddShow}
                handleAddClose={this.handleAddClose}
                handleEditShow={this.handleEditShow}
                handleEditClose={this.handleEditClose}
                handleDeleteShow={this.handleDeleteShow}
                handleAdd={this.handleAdd}
                handleEdit={this.handleEdit}
                dismissAddProgramError={this.dismissAddProgramError}
                dismissEditProgramError={this.dismissEditProgramError}
                seeProgram={this.seeProgram}
              />
            </Tab>
            <Tab eventKey="school" title="School programs">
              <SchoolPrograms
                addProgram={this.state.addSchool}
                addProgramSuccess={this.state.addSchoolSuccess}
                addProgramLoading={this.state.addSchoolLoading}
                addProgramError={this.state.addSchoolError}
                programs={this.state.programs.filter(
                  (p) => p.type === "school"
                )}
                getLoading={this.state.getProgramLoading}
                getError={this.state.getProgramError}
                regularPrograms={this.state.programs.filter(
                  (p) => p.type === "regular"
                )}
                editProgram={this.state.editSchool}
                editProgramId={this.state.editSchoolId}
                editProgramSuccess={this.state.editSchoolSuccess}
                editProgramLoading={this.state.editSchoolLoading}
                editProgramError={this.state.editSchoolError}
                closeAddSuccess={this.closeAddSchoolSuccess}
                closeEditSuccess={this.closeEditSchoolSuccess}
                closeDeleteSuccess={this.closeAddSchoolSuccess}
                handleAddShow={this.handleAddSchoolShow}
                handleAddClose={this.handleAddSchoolClose}
                handleEditShow={this.handleEditSchoolShow}
                handleEditClose={this.handleEditSchoolClose}
                handleDeleteShow={this.handleDeleteShow}
                handleAdd={this.handleSchoolAdd}
                handleDuplicateAdd={this.handleDuplicateAdd}
                handleEdit={this.handleSchoolEdit}
                dismissAddProgramError={this.dismissAddSchoolError}
                dismissEditProgramError={this.dismissEditSchoolError}
                seeProgram={this.seeProgram}
              />
            </Tab>
          </Tabs>
          <Modal
            show={this.state.deleteProgram}
            onHide={this.handleDeleteClose}
          >
            <ModalHeader closeButton={true}>
              <ModalTitle>Delete program</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {`Are you sure you want to delete program: ${
                this.state.deleteProgramId !== null &&
                this.state.programs.length > 0
                  ? this.state.programs.find(
                      (p) => p["id"] === this.state.deleteProgramId
                    )["name"]
                  : "this"
              }?`}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                type="button"
                onClick={this.handleDeleteClose}
              >
                Close
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={this.handleDelete}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      );
    }
  }
}
