import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "react-bootstrap/Pagination";
import FormSelect from "react-bootstrap/FormSelect";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { Receipt as FileIcon } from "react-bootstrap-icons";

export const Transactions = (props) => {
  return (
    <Container fluid={true} className="position-relative">
      <ToastContainer position="top-end" className="pt-2 pe-md-1">
        <Toast show={props.loading} bg="info">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Getting all transactions
          </ToastBody>
        </Toast>
        <Toast show={props.reportLoading} bg="info">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Generating report
          </ToastBody>
        </Toast>
        <Toast
          show={props.error !== null}
          bg="danger"
          onClose={props.dismissError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Fetch error</strong>
          </ToastHeader>
          <ToastBody>{props.error}</ToastBody>
        </Toast>
        <Toast
          show={props.reportError !== null}
          bg="danger"
          onClose={props.dismissReportError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Report error</strong>
          </ToastHeader>
          <ToastBody>{props.reportError}</ToastBody>
        </Toast>
      </ToastContainer>
      <Row className="g-2">
        <Col className="col-auto">
          <Pagination>
            {props.renderPageItems(props.page, props.total, props.changePage)}
          </Pagination>
        </Col>
        <Col className="col-auto">
          <FormSelect value={props.size} onChange={props.changeSize}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </FormSelect>
        </Col>
        {props.paid && (
          <Col className="col-auto me-auto">
            <Button className="text-white" onClick={props.openReport}>
              Generate report
            </Button>
          </Col>
        )}
      </Row>
      <Table hover={true} striped={true} bordered={true} responsive="sm">
        <thead>
          <tr>
            <th>Email ID</th>
            <th>Program cost</th>
            <th>Amount</th>
            {props.paid && <th>Discount</th>}
            {props.paid && <th>Discount Code</th>}
            <th>Type of transaction</th>
            {props.paid && <th>Invoice</th>}
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          {props.transactions.map((e, index) => (
            <tr key={index}>
              <td>{e.email_id}</td>
              <td>{e.program_cost}</td>
              <td>{e.amount}</td>
              {props.paid && <td>{e?.discount}</td>}
              {props.paid && <td>{e?.affiliate_code || e?.promo_code}</td>}
              <td>{e.trans_type}</td>
              {props.paid && (
                <td>
                  {e?.invoice_path && (
                    <a
                      href={`invoice/${e?.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FileIcon />
                    </a>
                  )}
                </td>
              )}
              <td>{props.formatDate(e?.payment_made_on || e?.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="g-2">
        <Col className="col-auto">
          <Pagination>
            {props.renderPageItems(props.page, props.total, props.changePage)}
          </Pagination>
        </Col>
        <Col className="col-auto">
          <FormSelect value={props.size} onChange={props.changeSize}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </FormSelect>
        </Col>
      </Row>
    </Container>
  );
};
