import React, { Component } from "react";
import BtContainer from "react-bootstrap/Container";
import { admin_api } from "../../atoms/api";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import FormSelect from "react-bootstrap/FormSelect";
import FormCheck from "react-bootstrap/FormCheck";
import FormLabel from "react-bootstrap/FormLabel";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Badge from "react-bootstrap/Badge";
import { Formik, FieldArray } from "formik";
import {
  AnswerSchema,
  QuestionFooter,
  QuestionHeading,
  QuestionSchema,
  ResponseSchema,
} from "../../atoms/schemas";
import { InfoSpan } from "../../pages/program";
import { UtImg } from "../../atoms/img";
import styled from "styled-components";

const Container = styled(BtContainer)`
  & > .toast-container {
    z-index: 1;
  }
`;

export class Play extends Component {
  constructor(props) {
    super();
    this.state = {
      // Questions
      add: false,
      addLoading: false,
      addSuccess: false,
      addError: null,
      getLoading: true,
      getError: null,
      level: {},
      data: [],
      edit: false,
      editId: null,
      editLoading: false,
      editSuccess: false,
      editError: null,
      delete: false,
      deleteId: null,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: null,
      selectedQuestion: null,
      // Answers
      showAnswers: false,
      answers: [],
      // Responses
      showResponses: false,
      responses: [],
      gifRepository: [],
      // question categories
      categories: [],
      categoryLoading: true,
      categoryError: null,
    };
  }

  componentDidMount() {
    admin_api
      .get(`/levels/${this.props.levelId}/question`)
      .then((res) => {
        let { question, ...level } = res.data;
        question = question.sort(
          (a, b) =>
            level.question_sequence.indexOf(a["id"]) -
            level.question_sequence.indexOf(b["id"])
        );
        this.setState({ getLoading: false, data: question, level });
      })
      .catch((err) =>
        this.setState({
          getLoading: false,
          getError: err?.response?.data?.message || "Error",
        })
      );
    admin_api
      .get("/image/type/gif")
      .then((res) => this.setState({ gifRepository: res.data }))
      .catch((err) => {});
    admin_api
      .get("/question-category")
      .then((res) => {
        this.setState({ categories: res.data, categoryLoading: false });
      })
      .catch((err) => {
        this.setState({
          categoryLoading: false,
          categoryError:
            err?.response?.data?.message || "Error getting question categories",
        });
      });
  }

  closeAddSuccess = () => this.setState({ addSuccess: false });
  closeEditSuccess = () => this.setState({ editSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteSuccess: false });

  dismissAddError = () => this.setState({ addError: null });
  dismissGetError = () => this.setState({ getError: null });
  dismissEditError = () => this.setState({ editError: null });
  dismissDeleteError = () => this.setState({ deleteError: null });
  dismissCategoryError = () => this.setState({ categoryError: null });

  handleAddOpen = () => this.setState({ add: true });
  handleAddClose = () => this.setState({ add: false });
  handleEditOpen = (id) => this.setState({ editId: id, edit: true });
  handleEditClose = () => this.setState({ editId: null, edit: false });
  handleDeleteOpen = (id) => this.setState({ deleteId: id, delete: true });
  handleDeleteClose = () => this.setState({ deleteId: null, delete: false });

  handleDelete = () => {
    this.setState({ delete: false, deleteLoading: true });
    admin_api
      .delete(`/questions/${this.state.deleteId}`)
      .then((res) => {
        let data = this.state.data.filter(
          (q) => q["id"] !== this.state.deleteId
        );
        this.setState({
          deleteLoading: false,
          deleteSuccess: true,
          deleteId: null,
          data,
        });
      })
      .catch((err) =>
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteError: err?.response?.data?.message || "Error",
        })
      );
  };

  handleAnswerOpen = (id) => {
    let question = this.state.data.find((q) => q["id"] === id);
    this.setState({
      showAnswers: true,
      answers: question.answers,
      selectedQuestion: question,
    });
  };

  handleAnswerClose = () =>
    this.setState({
      showAnswers: false,
      answers: [],
      selectedQuestion: null,
    });

  handleResponseOpen = (id) => {
    let question = this.state.data.find((q) => q["id"] === id);
    this.setState({
      showResponses: true,
      responses: question.responses,
      selectedQuestion: question,
    });
  };

  handleResponseClose = () =>
    this.setState({
      showResponses: false,
      responses: [],
      selectedQuestion: null,
    });

  dragStartHandler = (e) => {
    e.dataTransfer.setData(
      "questionId",
      e.target.getAttribute("data-questionid")
    );
    e.dataTransfer.setData("pickIndex", e.target.getAttribute("data-rowindex"));
    e.dataTransfer.dropEffect = "move";
  };

  dragOverHandler = (e) => {
    e.preventDefault();
  };

  dropHandler = (e) => {
    e.preventDefault();
    this.setState({ editLoading: true });
    const dropRow = e.currentTarget.getAttribute("data-rowindex");
    const questionId = e.dataTransfer.getData("questionId");
    admin_api
      .patch(
        `/levels/${this.props.levelId}/question-sequence/question/${questionId}/index/${dropRow}`
      )
      .then((res) => {
        let newQuestionSequence = res.data;
        this.setState({
          editLoading: false,
          editSuccess: true,
          level: {
            ...this.state.level,
            question_sequence: newQuestionSequence,
          },
          data: this.state.data.sort(
            (a, b) =>
              newQuestionSequence.indexOf(a["id"]) -
              newQuestionSequence.indexOf(b["id"])
          ),
        });
      })
      .catch((err) =>
        this.setState({
          editLoading: false,
          editError: err?.response?.data?.message || "Error",
        })
      );
  };

  // handleAnswerEditChange = () => {};

  // handleAnswerEditSubmit = () => {};

  render() {
    return (
      <Container fluid={true} className="pt-3 position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
          <Toast show={this.state.getLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting questions
            </ToastBody>
          </Toast>
          <Toast show={this.state.categoryLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting question categories
            </ToastBody>
          </Toast>
          <Toast show={this.state.deleteLoading} bg="warning">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting question
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.addSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeAddSuccess}
          >
            <ToastBody className="text-white">
              Question added successfully
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.editSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeEditSuccess}
          >
            <ToastBody className="text-white">
              Changes saved successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteSuccess}
            bg="success"
            onClose={this.closeDeleteSuccess}
            delay={2000}
            autohide={true}
          >
            <ToastBody className="text-white">Deleted successfully.</ToastBody>
          </Toast>
          <Toast
            show={this.state.addError !== null}
            bg="danger"
            onClose={this.dismissAddError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Add error</strong>
            </ToastHeader>
            <ToastBody>{this.state.addError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.getError !== null}
            bg="danger"
            onClose={this.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.editError !== null}
            bg="danger"
            onClose={this.dismissEditError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Edit error</strong>
            </ToastHeader>
            <ToastBody>{this.state.editError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteError !== null}
            bg="danger"
            onClose={this.dismissDeleteError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Delete error</strong>
            </ToastHeader>
            <ToastBody>{this.state.deleteError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.categoryError !== null}
            bg="danger"
            onClose={this.dismissCategoryError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Question category error</strong>
            </ToastHeader>
            <ToastBody>{this.state.categoryError}</ToastBody>
          </Toast>
        </ToastContainer>
        <br />
        <Formik
          initialValues={{
            questionHeading: this.state.level?.questionHeading || "",
          }}
          validationSchema={QuestionHeading}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.setState({ editLoading: true });
            admin_api
              .patch(`/levels/${this.state.level?.id}`, values)
              .then((res) =>
                this.setState({
                  editLoading: false,
                  editSuccess: true,
                  level: res.data,
                })
              )
              .catch((err) =>
                this.setState({
                  editLoading: false,
                  editError: err?.response?.data?.message || "Error",
                })
              );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            initialValues,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate={true} onSubmit={handleSubmit}>
              <FloatingLabel
                label="Play section heading"
                controlId="level-questionHeading"
                className="mb-2"
              >
                <FormControl
                  as="textarea"
                  style={{ minHeight: "100px" }}
                  name="questionHeading"
                  value={values.questionHeading}
                  placeholder="Play section heading"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  isValid={touched.questionHeading && !errors.questionHeading}
                  isInvalid={
                    touched.questionHeading && !!errors.questionHeading
                  }
                />
                {touched.questionHeading && (
                  <FormControl.Feedback type="invalid">
                    {errors.questionHeading}
                  </FormControl.Feedback>
                )}
              </FloatingLabel>
              {initialValues.questionHeading !== values.questionHeading && (
                <>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="me-2"
                    disabled={this.state.editLoading}
                  >
                    {this.state.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save"}
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        "questionHeading",
                        initialValues.questionHeading || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
        <InfoSpan>Drag row to change questions sequence</InfoSpan>
        <Table hover={true} striped={true} bordered={true} responsive="sm">
          <thead>
            <tr>
              <th>Image</th>
              <th>Question</th>
              <th>Category</th>
              <th>Question type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                draggable={true}
                data-rowindex={rowIndex}
                data-questionid={row["id"]}
                onDragStart={this.dragStartHandler}
                onDragOver={this.dragOverHandler}
                onDrop={this.dropHandler}
              >
                <td>
                  <UtImg
                    src={row.image?.filePath}
                    alt={row.image?.file}
                    width="100px"
                    height="auto"
                  />
                </td>
                <td>{row.question}</td>
                <td>
                  <Badge pill bg="primary">
                    {row?.category}
                  </Badge>
                </td>
                <td>
                  <Badge
                    bg={
                      row.type === "single"
                        ? "dark"
                        : row.type === "multiple"
                        ? "secondary"
                        : "light"
                    }
                    text={
                      (row.type === "text" || row.type === "opinion") && "dark"
                    }
                  >
                    {row.type === "single"
                      ? "Single choice"
                      : row.type === "multiple"
                      ? "Multiple choice"
                      : row.type === "text"
                      ? "Type your answer"
                      : "Your opinion"}
                  </Badge>
                </td>
                <td>
                  <div className="d-flex flex-column align-items-start flex-md-row justify-content-md-start">
                    <Button
                      variant="secondary"
                      type="button"
                      className="mb-2 mb-md-0 me-md-2"
                      onClick={() => this.handleEditOpen(row["id"])}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="mb-2 mb-md-0 me-md-2"
                      onClick={() => this.handleAnswerOpen(row["id"])}
                    >
                      Answers
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="mb-2 mb-md-0 me-md-2"
                      onClick={() => this.handleResponseOpen(row["id"])}
                    >
                      Responses
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      onClick={() => this.handleDeleteOpen(row["id"])}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button
          type="button"
          variant="primary"
          className="text-white mb-3"
          onClick={this.handleAddOpen}
        >
          Add question
        </Button>
        <Formik
          initialValues={{
            questionFooter: this.state.level?.questionFooter || "",
          }}
          validationSchema={QuestionFooter}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.setState({ editLoading: true });
            admin_api
              .patch(`/levels/${this.state.level?.id}`, values)
              .then((res) =>
                this.setState({
                  editLoading: false,
                  editSuccess: true,
                  level: res.data,
                })
              )
              .catch((err) =>
                this.setState({
                  editLoading: false,
                  editError: err?.response?.data?.message || "Error",
                })
              );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            initialValues,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate={true} onSubmit={handleSubmit}>
              <FloatingLabel
                label="Play section footer"
                controlId="level-questionFooter"
                className="mb-2"
              >
                <FormControl
                  as="textarea"
                  style={{ minHeight: "100px" }}
                  name="questionFooter"
                  value={values.questionFooter}
                  placeholder="Play section footer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  isValid={touched.questionFooter && !errors.questionFooter}
                  isInvalid={touched.questionFooter && !!errors.questionFooter}
                />
                {touched.questionFooter && (
                  <FormControl.Feedback type="invalid">
                    {errors.questionFooter}
                  </FormControl.Feedback>
                )}
              </FloatingLabel>
              {initialValues.questionFooter !== values.questionFooter && (
                <>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="me-2"
                    disabled={this.state.editLoading}
                  >
                    {this.state.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save"}
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        "questionFooter",
                        initialValues.questionFooter || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
        <Modal show={this.state.add} onHide={this.handleAddClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Add a new question</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                question: "",
                type: "single",
                category: "",
                image: undefined,
                level_id: this.props.levelId,
              }}
              validationSchema={QuestionSchema}
              onSubmit={(values, { setStatus }) => {
                if (!values.image) {
                  setStatus({ image: "Image is required" });
                } else if (!values.image.type.match(/image/)) {
                  // do nothing
                } else {
                  this.setState({ addLoading: true });
                  let values_form_data = new FormData();
                  Object.keys(values).forEach((key) =>
                    values_form_data.append(key, values[key])
                  );
                  admin_api
                    .post("/questions", values_form_data)
                    .then((res) => {
                      let data = this.state.data;
                      data.push(res.data);
                      let categories = Array.from(this.state.categories);
                      if (values.category) {
                        categories.push({
                          name: values.category,
                          slug: values.category,
                        });
                      }
                      this.setState({
                        add: false,
                        addLoading: false,
                        addSuccess: true,
                        data,
                        categories,
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        add: false,
                        addLoading: false,
                        addError: err["response"]["data"]["messageF"],
                      })
                    );
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setTouched,
                setStatus,
                status,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    className="mb-2"
                    controlId="add-question.question"
                    label="Question text"
                  >
                    <FormControl
                      name="question"
                      placeholder="Question text"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.question}
                      required={true}
                      isValid={touched.question && !errors.question}
                      isInvalid={touched.question && !!errors.question}
                    />
                    {touched.question && (
                      <FormControl.Feedback type="invalid">
                        {errors.question}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Question type</InputGroup.Text>
                    <FormSelect
                      name="type"
                      id="add-question.type"
                      value={values.type}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.type && !errors.type}
                      isInvalid={touched.type && !!errors.type}
                    >
                      <option disabled={true}>select a question type</option>
                      <option value="single">Single</option>
                      <option value="multiple">Multiple</option>
                      <option value="text">Text</option>
                      <option value="opinion">Opinion</option>
                    </FormSelect>
                    {touched.type && (
                      <FormControl.Feedback type="invalid">
                        {errors.type}
                      </FormControl.Feedback>
                    )}
                  </InputGroup>
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Question category</InputGroup.Text>
                    <input
                      className="form-select"
                      list="add-question.category"
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.category && !errors.category}
                      isInvalid={touched.category && !!errors.category}
                      disabled={this.state.categoryLoading}
                      autoComplete="off"
                      placeholder={
                        this.state.categoryLoading
                          ? "Getting categories"
                          : "Select a category for question"
                      }
                    />

                    <datalist id="add-question.category">
                      {this.state.categories.map((c, idx) => (
                        <option value={c?.slug} label={c?.name} />
                      ))}
                    </datalist>
                    {touched.category && (
                      <FormControl.Feedback type="invalid">
                        {errors.category}
                      </FormControl.Feedback>
                    )}
                  </InputGroup>
                  <FormGroup controlId="add-question.image" className="mb-2">
                    <FormLabel>Image</FormLabel>
                    <FormControl
                      type="file"
                      name="image"
                      accept="image/*"
                      required={true}
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("image", file);
                        if (file.type.match(/image/)) {
                          setStatus({ image: null });
                        } else setStatus({ image: "File should be an image" });
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, image: true });
                      }}
                      isValid={touched.image && !status?.image}
                      isInvalid={touched.image && !!status?.image}
                    />
                    {touched.image && (
                      <FormControl.Feedback type="invalid">
                        {status?.image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <ModalFooter>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={this.handleAddClose}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      className="text-white"
                      type="submit"
                    >
                      Add question
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.edit} onHide={this.handleEditClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Edit question</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.data.find(
                (q) => q["id"] === this.state.editId
              )}
              onSubmit={(values, { setStatus }) => {
                if (!values.image) setStatus({ image: "Image is required" });
                else if (
                  ("fileType" in values.image &&
                    !values.image.fileType.match(/image/)) ||
                  ("type" in values.image && !values.image.type.match(/image/))
                ) {
                  // do nothing
                } else {
                  this.setState({ editLoading: true });
                  let values_form_data = new FormData();
                  Object.keys(values).forEach((key) =>
                    values_form_data.append(key, values[key])
                  );
                  admin_api
                    .patch(`/questions/${this.state.editId}`, values_form_data)
                    .then((res) => {
                      let data = this.state.data.map((q) => {
                        if (q["id"] === this.state.editId) return res.data;
                        return q;
                      });
                      let categories = Array.from(this.state.categories);
                      if (
                        values.category &&
                        this.state.categories
                          .map((c) => c?.slug)
                          .includes(values.category)
                      ) {
                        categories.push({
                          name: values.category,
                          slug: values.category,
                        });
                      }
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editSuccess: true,
                        data,
                        categories,
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editError: err?.response?.data?.message || "Error",
                      })
                    );
                }
              }}
              validationSchema={QuestionSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setTouched,
                setStatus,
                status,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    className="mb-2"
                    controlId="edit-question.question"
                    label="Question text"
                  >
                    <FormControl
                      name="question"
                      placeholder="Question text"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.question}
                      required={true}
                      isValid={touched.question && !errors.question}
                      isInvalid={touched.question && !!errors.question}
                    />
                    {touched.question && (
                      <FormControl.Feedback type="invalid">
                        {errors.question}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Question type</InputGroup.Text>
                    <FormSelect
                      name="type"
                      value={values.type}
                      required={true}
                      onChange={(e) => {
                        if (e.target?.value === "opinion") {
                          if (
                            window.confirm(
                              "Changing the question type to opinion deletes all the related answers. Are you sure you want to this?"
                            )
                          ) {
                            handleChange(e);
                          }
                        } else handleChange(e);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.type && !errors.type}
                      isInvalid={touched.type && !!errors.type}
                    >
                      <option disabled={true}>select a question type</option>
                      <option value="single">Single</option>
                      <option value="multiple">Multiple</option>
                      <option value="text">Text</option>
                      <option value="opinion">Opinion</option>
                    </FormSelect>
                    {touched.type && (
                      <FormControl.Feedback type="invalid">
                        {errors.type}
                      </FormControl.Feedback>
                    )}
                  </InputGroup>
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Question category</InputGroup.Text>
                    <input
                      className="form-select"
                      list="edit-question.category"
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.category && !errors.category}
                      isInvalid={touched.category && !!errors.category}
                      disabled={this.state.categoryLoading}
                      autoComplete="off"
                      placeholder={
                        this.state.categoryLoading
                          ? "Getting categories"
                          : "Select a category for question"
                      }
                    />

                    <datalist id="edit-question.category">
                      {this.state.categories.map((c, idx) => (
                        <option value={c?.slug} label={c?.name} />
                      ))}
                    </datalist>
                    {touched.category && (
                      <FormControl.Feedback type="invalid">
                        {errors.category}
                      </FormControl.Feedback>
                    )}
                  </InputGroup>
                  <FormGroup controlId="edit-question.image" className="mb-2">
                    <FormLabel>
                      Image
                      <br />
                      {values?.image?.file}
                    </FormLabel>
                    {values?.image?.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("image", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      type="file"
                      name="image"
                      required={true}
                      accept="image/*"
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("image", file);
                        if (file.type.match(/image/)) {
                          setStatus({ image: null });
                        } else setStatus({ image: "File should be an image" });
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, image: true });
                      }}
                      isValid={touched.image && !status?.image}
                      isInvalid={touched.image && !!status?.image}
                    />
                    {touched.image && (
                      <FormControl.Feedback type="invalid">
                        {status?.image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <ModalFooter>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={this.handleEditClose}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      className="text-white"
                      type="submit"
                    >
                      Save changes
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.delete} onHide={this.handleDeleteClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Delete question</ModalTitle>
          </ModalHeader>
          <ModalBody>Are you sure you want to delete this question?</ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={this.handleDeleteClose}
            >
              Close
            </Button>
            <Button variant="danger" type="button" onClick={this.handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          show={this.state.showAnswers}
          onHide={this.handleAnswerClose}
          size="lg"
        >
          <ModalHeader closeButton={true}>
            <ModalTitle>Answers</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              onSubmit={(values, { errors, setErrors }) => {
                if (
                  values.selectedAnswer.length < 1 ||
                  values.selectedAnswer === "-1"
                ) {
                  setErrors({
                    ...errors,
                    selectedAnswer: "Please select the correct answer",
                  });
                } else {
                  this.setState({ editLoading: true });
                  admin_api
                    .post("/answers/bulk", values)
                    .then((res) => {
                      let data = this.state.data.map((q) => {
                        if (q.id === this.state.selectedQuestion?.id) {
                          return { ...q, answers: res.data };
                        }
                        return q;
                      });
                      this.setState({
                        editLoading: false,
                        answers: res.data,
                        data,
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        editLoading: false,
                        editError: err?.response?.data?.message || "Error",
                      })
                    );
                }
              }}
              initialValues={{
                question_id: this.state.selectedQuestion?.id,
                answers: this.state.answers,
                selectedAnswer: !!this.state.selectedQuestion
                  ? this.state.selectedQuestion?.type === "multiple"
                    ? this.state.answers.reduce((acc, ele, index) => {
                        if (ele.is_correct) acc.push(index.toString());
                        return acc;
                      }, [])
                    : this.state.answers
                        .map((a) => a.is_correct)
                        .indexOf(true)
                        .toString()
                  : null,
              }}
              enableReinitialize={true}
              validationSchema={AnswerSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FieldArray
                    name="answers"
                    render={({ insert, remove, push }) => (
                      <div>
                        {values.answers && values.answers.length > 0 ? (
                          values.answers.map((ans, index) => {
                            return (
                              <Row
                                className="align-items-center mb-3 g-3"
                                key={index}
                              >
                                <FormGroup as={Col} md="5">
                                  <FloatingLabel
                                    label="Choice text"
                                    controlId={`answers.${index}.answer`}
                                  >
                                    <FormControl
                                      name={`answers.${index}.answer`}
                                      placeholder="Choice text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={ans.answer}
                                      required={true}
                                      isValid={
                                        "answers" in touched &&
                                        touched.answers[index]?.answer &&
                                        (!("answers" in errors) ||
                                          ("answers" in errors &&
                                            !errors.answers[index]?.answer))
                                      }
                                      isInvalid={
                                        "answers" in touched &&
                                        touched.answers[index]?.answer &&
                                        "answers" in errors &&
                                        !!errors.answers[index]?.answer
                                      }
                                    />
                                    {"answers" in touched &&
                                      touched.answers[index]?.answer && (
                                        <FormControl.Feedback type="invalid">
                                          {"answers" in errors &&
                                            errors.answers[index]?.answer}
                                        </FormControl.Feedback>
                                      )}
                                  </FloatingLabel>
                                </FormGroup>
                                <FormGroup as={Col} sm="8" md="4">
                                  <FormCheck
                                    type={
                                      this.state.selectedQuestion?.type ===
                                      "multiple"
                                        ? "checkbox"
                                        : "radio"
                                    }
                                    name="selectedAnswer"
                                    checked={
                                      this.state.selectedQuestion?.type ===
                                      "multiple"
                                        ? values.selectedAnswer?.includes(
                                            index.toString()
                                          )
                                        : values.selectedAnswer ===
                                          index.toString()
                                    }
                                    value={index}
                                    label="Is answer?"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    isValid={
                                      touched.selectedAnswer &&
                                      !errors.selectedAnswer
                                    }
                                    isInvalid={
                                      touched.selectedAnswer &&
                                      !!errors.selectedAnswer
                                    }
                                  />
                                  {touched.selectedAnswer && (
                                    <FormControl.Feedback type="invalid">
                                      {errors.selectedAnswer}
                                    </FormControl.Feedback>
                                  )}
                                </FormGroup>
                                {this.state.selectedQuestion?.type !==
                                  "text" && (
                                  <FormGroup as={Col} sm="4" md="3">
                                    <Button
                                      variant="secondary"
                                      type="button"
                                      className="me-2"
                                      onClick={() =>
                                        insert(index + 1, {
                                          answer: "",
                                        })
                                      }
                                    >
                                      Add
                                    </Button>
                                    <Button
                                      variant="danger"
                                      type="button"
                                      onClick={() => {
                                        remove(index);
                                        setFieldValue(
                                          "selectedAnswer",
                                          values.selectedAnswer.filter(
                                            (i) => i !== index.toString
                                          )
                                        );
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </FormGroup>
                                )}
                              </Row>
                            );
                          })
                        ) : (
                          <>
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={() =>
                                push({
                                  answer: "",
                                })
                              }
                              className="mb-2"
                            >
                              Add new answer
                            </Button>
                            <br />
                          </>
                        )}
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={this.state.editLoading}
                        >
                          {this.state.editLoading
                            ? [
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />,
                                " Saving...",
                              ]
                            : "Save changes"}
                        </Button>
                      </div>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.showResponses}
          onHide={this.handleResponseClose}
        >
          <ModalHeader closeButton={true}>
            <ModalTitle>Responses</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              onSubmit={(values) => {
                this.setState({ editLoading: true });
                let values_form_data = new FormData();
                values_form_data.append("question_id", values.question_id);
                let rspArray = values.responses.map((r) => {
                  let { gif, ...rest } = r;
                  values_form_data.append("gif", gif);
                  return rest;
                });
                rspArray.forEach((r) =>
                  values_form_data.append("responses[]", JSON.stringify(r))
                );
                admin_api
                  .post("/responses/bulk", values_form_data)
                  .then((res) => {
                    let data = this.state.data.map((q) => {
                      if (q.id === this.state.selectedQuestion?.id) {
                        return { ...q, responses: res.data };
                      }
                      return q;
                    });
                    this.setState({
                      editLoading: false,
                      responses: res.data,
                      data,
                    });
                  })
                  .catch((err) =>
                    this.setState({
                      editLoading: false,
                      editError: err?.response?.data?.message || "Error",
                    })
                  );
              }}
              initialValues={{
                question_id: this.state.selectedQuestion?.id,
                responses: this.state.responses || [],
              }}
              enableReinitialize={true}
              validationSchema={ResponseSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setStatus,
                status,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FieldArray
                    name="responses"
                    render={({ insert, remove, push }) => (
                      <div>
                        {values.responses && values.responses.length > 0 ? (
                          values.responses.map((rsp, index) => (
                            <div className="mb-3" key={index}>
                              <Row className="g-2 mb-2">
                                <Col md="8">
                                  <FloatingLabel
                                    label={
                                      rsp["is_correct"]
                                        ? "For correct answers, say..."
                                        : "For incorrect answers, say..."
                                    }
                                    controlId={`response.${index}.response`}
                                  >
                                    <FormControl
                                      as="textarea"
                                      name={`responses.${index}.response`}
                                      placeholder={
                                        rsp["is_correct"]
                                          ? "For correct answers, say..."
                                          : "For incorrect answers, say..."
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={rsp["response"]}
                                      required={true}
                                      className={`${
                                        rsp["is_correct"]
                                          ? "border-success"
                                          : "border-danger"
                                      }`}
                                      style={{ height: "100px" }}
                                      isValid={
                                        "responses" in touched &&
                                        touched.responses[index]?.response &&
                                        (!("responses" in errors) ||
                                          ("responses" in errors &&
                                            !errors.responses[index]?.response))
                                      }
                                      isInvalid={
                                        "responses" in touched &&
                                        touched.responses[index]?.response &&
                                        "responses" in errors &&
                                        !!errors.responses[index]?.response
                                      }
                                    />
                                    {"responses" in touched &&
                                      touched.responses[index]?.response && (
                                        <FormControl.Feedback type="invalid">
                                          {"responses" in errors &&
                                            errors.responses[index]?.response}
                                        </FormControl.Feedback>
                                      )}
                                  </FloatingLabel>
                                </Col>
                                <Col sm="6" md="4">
                                  <UtImg
                                    src={rsp.gif?.filePath}
                                    alt={rsp.gif?.file}
                                    width="100%"
                                    height="auto"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2 g-2">
                                <FormGroup as={Col} md="6">
                                  <FormLabel>New GIF</FormLabel>
                                  <FormControl
                                    type="file"
                                    name={`responses.${index}.gif`}
                                    accept="image/gif"
                                    onChange={(e) => {
                                      let file = e.currentTarget.files[0];
                                      setFieldValue(
                                        `responses.${index}.gif`,
                                        file
                                      );
                                      setFieldValue(
                                        `responses.${index}.gif_id`,
                                        ""
                                      );
                                      if (file.type === "image/gif")
                                        setStatus({
                                          ...status,
                                          [index]: null,
                                        });
                                      else
                                        setStatus({
                                          ...status,
                                          [index]: "File should be a GIF",
                                        });
                                    }}
                                    isInvalid={
                                      !!status &&
                                      index in status &&
                                      !!status[index]
                                    }
                                  />
                                  {!!status &&
                                    index in status &&
                                    !!status[index] && (
                                      <FormControl.Feedback type="invalid">
                                        {status[index]}
                                      </FormControl.Feedback>
                                    )}
                                </FormGroup>
                                <FormGroup as={Col} md="6">
                                  <FormLabel>GIF</FormLabel>
                                  <FormSelect
                                    name={`responses.${index}.gif_id`}
                                    value={rsp["gif_id"]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // disabled={!!rsp[`gif`]}
                                    isValid={
                                      "responses" in touched &&
                                      touched.responses[index]?.gif_id &&
                                      (!("responses" in errors) ||
                                        ("responses" in errors &&
                                          !errors.responses[index]?.gif_id))
                                    }
                                    isInvalid={
                                      "responses" in touched &&
                                      touched.responses[index]?.gif_id &&
                                      "responses" in errors &&
                                      !!errors.responses[index]?.gif_id
                                    }
                                  >
                                    <option value="">
                                      Select from existing GIFs
                                    </option>
                                    {this.state.gifRepository.map(
                                      (g, index) => (
                                        <option key={index} value={g.id}>
                                          {g.file}
                                        </option>
                                      )
                                    )}
                                  </FormSelect>
                                  {"responses" in touched &&
                                    touched.responses[index]?.gif_id && (
                                      <FormControl.Feedback type="invalid">
                                        {"responses" in errors &&
                                          errors.responses[index]?.gif_id}
                                      </FormControl.Feedback>
                                    )}
                                </FormGroup>
                              </Row>
                              <FormGroup>
                                <Button
                                  variant="primary"
                                  type="button"
                                  className="me-2"
                                  disabled={values.responses.length >= 2}
                                  onClick={() =>
                                    insert(index + 1, {
                                      response: "",
                                      is_correct: !rsp["is_correct"],
                                      gif: "",
                                      gif_id: "",
                                    })
                                  }
                                >
                                  Add
                                </Button>
                                <Button
                                  variant="danger"
                                  type="button"
                                  onClick={() => {
                                    remove(index);
                                    setStatus({
                                      ...status,
                                      [index]: null,
                                    });
                                  }}
                                >
                                  Delete
                                </Button>
                              </FormGroup>
                            </div>
                          ))
                        ) : (
                          <>
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={() =>
                                push({
                                  response: "",
                                  is_correct: true,
                                  gif: "",
                                  gif_id: "",
                                })
                              }
                              className="mb-2"
                            >
                              Add new response
                            </Button>
                            <br />
                          </>
                        )}
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={
                            this.state.editLoading ||
                            (!!status &&
                              Object.values(status).reduce(
                                (next, acc) => acc && !!next,
                                true
                              ))
                          }
                        >
                          {this.state.editLoading
                            ? [
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />,
                                " Saving...",
                              ]
                            : "Save changes"}
                        </Button>
                        {!!errors.responses &&
                          !Array.isArray(errors.responses) && (
                            <span
                              className="text-danger"
                              style={{
                                fontSize: "0.875em",
                                margin: "0.25rem 1rem",
                              }}
                            >
                              {errors.responses}
                            </span>
                          )}
                      </div>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}
