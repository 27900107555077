import * as yup from "yup";

export const ProgramSchema = yup.object().shape({
    name: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Program name is required"),
    descrition: yup.string().max(1000, "Cannot exceed 1000 characters"),
    image: yup.mixed(),
    // .required("Program title image is required")
    // .test(
    //   "fileSize",
    //   "File size is larger than accepted.",
    //   (value) => value && value.size <= this.FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && this.SUPPORTED_FORMATS.includes(value.type)
    // ),
    cost: yup
        .number()
        .min(0, "Program price cannot be lesser than INR 0")
        .required("Program price is required"),
    validity: yup
        .number()
        .min(1, "Program validity cannot be lesser than 1 months")
        .required("Program validity month is required"),
    learning_hours: yup
        .number()
        .min(1, "Program learning hour cannot be lesser than 1 hour")
        .required("Program learning hour is required"),
    primary_color: yup
        .string()
        .nullable()
        .required("Program primary color is required"),
    secondary_color: yup
        .string()
        .nullable()
        .required("Program secondary color is required"),
    directed_at: yup
        .string()
        .oneOf(["girls", "boys", "others"], "Should be girls, boys or others")
        .default("girls")
        .required(),
    age_group: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Age group is required"),
    creator: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Creator is required"),
});

export const SchoolProgramSchema = ProgramSchema.shape({
    grade: yup
        .number()
        .min(1, "Minimum grade can only be 1")
        .max(15, "Maximum grade can only be 15")
        .required("Grade is required"),
});

export const DuplicateProgramSchema = yup.object().shape({
    programId: yup.number().required("Program is required").nullable(),
    grade: yup
        .number()
        .min(1, "Minimum grade can only be 1")
        .max(15, "Maximum grade can only be 15")
        .required("Grade is required"),
    levelIds: yup
        .array(yup.number("Level Ids needs to be a number"))
        .min(1, "Please select atleast one level to duplicate")
        .required(),
});

export const UserSchema = yup.object().shape({
    first_name: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("First name is required"),
    last_name: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Last name is required"),
    date_of_birth: yup
        .date()
        .max(new Date(), "Date cannot exceed today")
        .optional()
        .nullable(),
    gender: yup.string().oneOf(["M", "F", "O"]).required("Gender is required"),
    location: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .nullable()
        .notRequired(),
    email_id: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .email("Invalid email")
        .required("Email ID is required"),
    password: yup.string().max(255, "Cannot exceed 255 characters").notRequired(),
    role: yup.string().oneOf(["editor", "admin"]).required("Role is required"),
});

export const RegisterUserSchema = UserSchema.shape({
    password: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Password is required"),
    cPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match")
        .required("Confirm your password"),
});

export const LevelSchema = yup.object().shape({
    title: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Level title is required"),
    description: yup
        .string()
        .max(1000, "Cannot exceed 1000 characters")
        .required("Description is required"),
    type: yup
        .string()
        .oneOf(
            ["level", "checkpoint"],
            "Level type should be either level or checkpoint"
        ),
});

export const LearnHeading = yup.object().shape({
    learnHeading: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .nullable()
        .notRequired(),
});

export const DiscussHeading = yup.object().shape({
    discussHeading: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .nullable()
        .notRequired(),
});

export const QuestionHeading = yup.object().shape({
    questionHeading: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .nullable()
        .notRequired(),
});

export const LearnFooter = yup.object().shape({
    learnFooter: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .nullable()
        .notRequired(),
});

export const DiscussFooter = yup.object().shape({
    discussFooter: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .nullable()
        .notRequired(),
});

export const QuestionFooter = yup.object().shape({
    questionFooter: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .nullable()
        .notRequired(),
});

export const WatchSchema = yup.object().shape({
    description: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .required("Video description is required"),
    video_url: yup.string().when("video", {
        is: (val) => !!val,
        then: yup
            .string()
            .url("URL needs to a valid URL")
            .nullable()
            .notRequired(),
        otherwise: yup
            .string()
            .url("URL needs to a valid URL")
            .required("Please enter a URL to a video"),
    }),
    video: yup.mixed().when("video_url", {
        is: (val) => !!val,
        then: yup.mixed().notRequired(),
        otherwise: yup.mixed().required("Video file is required"),
    }),
    subtitle: yup.mixed().required('Subtitles are required')
}, [
    ["video_url", "video"]
]);

export const LearnSchema = yup.object().shape({
    heading: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .nullable()
        .notRequired(),
    text: yup
        .string()
        .max(1000, "Cannot exceed 1000 characters")
        .required("Section content is required"),
    background_color: yup
        .string()
        .nullable()
        .required("Section background color is required"),
    background_accent_color: yup.string().nullable().notRequired(),

});

export const DiscussSchema = yup.object().shape({
    question: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Text for question is required"),
    response: yup
        .string()
        .max(1000, "Cannot exceep 1000 characters")
        .required("Response for question is required"),
});

export const QuestionSchema = yup.object().shape({
    question: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Question text is required"),
    type: yup
        .string()
        .oneOf(
            ["single", "multiple", "text", "opinion"],
            "Question type should be either single, multiple, text or opinion"
        )
        .required("Question type is required"),
    category: yup.string().optional().nullable(),
});

const SingleAnswerSchema = yup.object().shape({
    answer: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Answer text is required"),
});

export const AnswerSchema = yup.object().shape({
    answers: yup.array().of(SingleAnswerSchema),
    selectedAnswer: yup.lazy((val) =>
        Array.isArray(val) ?
            yup
                .array()
                .of(yup.string())
                .min(1, "Please select at least one correct answer") :
            yup.string().required("Please select the correct answer")
    ),
});

const SingleResponseSchema = yup.object().shape({
    response: yup
        .string()
        .max(800, "Cannot exceed 800 characters")
        .required("Response text is required"),
    is_correct: yup.bool().default(true).required("Correctness is required"),
    gif_id: yup.string().when("gif", {
        is: (val) => !!val,
        then: yup.string().notRequired(),
        otherwise: yup.string().required("Please select a GIF"),
    }),
    gif: yup.mixed().when("gif_id", {
        is: (val) => !!val,
        then: yup.mixed().notRequired(),
        otherwise: yup.mixed().required("GIF file is required"),
    }),
}, [
    ["gif", "gif_id"]
]);

export const ResponseSchema = yup.object().shape({
    responses: yup
        .array()
        .of(SingleResponseSchema)
        .length(2, "Correct and incorrect responses are both required."),
});

const SingleDictionaryWord = yup.object().shape({
    word: yup
        .string()
        .max(100, "Cannot exceed 100 characters")
        .required("Dictionary word is required"),
    meaning: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .required("Dictionary meaning is required"),
});

export const DictionarySchema = yup.object().shape({
    words: yup.array().of(SingleDictionaryWord),
});

export const DiscountCodeSchema = yup.object().shape({
    name: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Code name is required"),
    description: yup
        .string()
        .max(500, "Cannot exceed 500 characters")
        .optional()
        .nullable(),
    expire_on: yup
        .date()
        .min(new Date(), "Cannot be already expired")
        .optional()
        .nullable(),
    deduction_value: yup
        .number("Value has to be a number")
        .min(1, "Cannot be lesser than 1")
        .required("Deduction factor value is required"),
    deduction_type: yup
        .string()
        .oneOf(["perc", "abs"])
        .required("Deduction factor type is required"),
});

export const PromoCodeSchema = yup.object().shape({
    code: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Code name is required"),
    type: yup
        .string()
        .oneOf(["absolute", "relative"])
        .default("absolute")
        .required("Type of promo code is required"),
    expires_on: yup
        .date()
        .min(new Date(), "Cannot be already expired")
        .optional()
        .nullable(),
    value: yup
        .number("Value has to be a number")
        .min(1, "Cannot be lesser than 1")
        .required("Discount value is required"),
});

export const SchoolSchema = yup.object().shape({
    name: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("School Name is required"),
    location: yup
        .string()
        .max(500, "Enter Location")
        .required("Location is required"),
    username: yup
        .string()
        .max(255, "Enter username")
        .required("Username is required"),
    type: yup
        .string()
        .oneOf(["hybrid", "online"], "Can either be hybrid, or online")
        .optional(),
    contactEmail: yup
        .string()
        .email("Enter valid email ID")
        .required("Contact email for a school is required"),
});

export const RegisterSchoolSchema = SchoolSchema.shape({
    password: yup
        .string()
        .max(255, "Cannot exceed 255 characters")
        .required("Password is required"),
    cPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match")
        .required("Confirm your password"),
});

export const OnboardSchema = yup.object().shape({
    school_id: yup.number().required("School is required"),
    program_id: yup.number().required("Program is required").nullable(),
});

export const WebinarSchema = yup.object().shape({
    title_image: yup.mixed(),
    title: yup.string().required("Webinar title is required"),
    event_link: yup
        .string()
        .url("Link should be a valid URL")
        .optional()
        .nullable(),
    event_on: yup.date().optional().nullable(),
    registration_open_till: yup.date().when(["event_on"], {
        is: (date) => !!date,
        then: yup.date().required("Registration last date is required").nullable(),
        otherwise: yup.date().optional().nullable(),
    }),
    is_special: yup.bool().default(false),
});

export const UserQuestionsSchema = yup.object().shape({
    response: yup
        .string()
        .required("Response is required")
        .max(5000, "Response cannot be longer be 5000 character.")
        .nullable(),
    is_faq: yup.bool().optional(),
});

export const AdminGiftSchema = yup.object().shape({
    program_id: yup.number().required("Program is required").nullable(),
    count: yup
        .number()
        .min(1, "Cannot be less than 1")
        .required("Number of gifts is required"),
});

export const AdminGiftUpdateSchema = yup.object().shape({
    program_id: yup.number().required("Program is required").nullable(),
});

export const RevokeSchoolSchema = yup.object().shape({
    revokeReason: yup.string().required("Reason for revoke is required"),
});

export const teacherSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
});
export const UpdateteacherSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    emailId: yup.string().email("Invalid email").required("Email is required"),
});