import React from "react";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { VictoryPie, VictoryTheme, VictoryChart, VictoryBar } from "victory";

export const Survey = ({
  loading = true,
  error = null,
  data = {},
  dismissError,
}) => {
  return (
    <Container fluid={true} className="position-relative">
      <ToastContainer position="top-end" className="pt-2 pe-md-1">
        <Toast show={loading} bg="info">
          <ToastBody className="text-white">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Getting list of survey...
          </ToastBody>
        </Toast>
        <Toast show={error !== null} bg="danger" onClose={dismissError}>
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Fetch error</strong>
          </ToastHeader>
          <ToastBody>{error}</ToastBody>
        </Toast>
      </ToastContainer>
      <Row>
        <Col md={4}>
          <Card style={{ maxHeight: "25vh" }} className="overflow-auto mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Requested Changes
            </Card.Header>
            <Table className="mb-0" bordered={true} striped={true} hover={true}>
              <tbody>
                {data?.change?.value?.map((name, idx) => (
                  <tr key={idx}>
                    <td>{name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ maxHeight: "25vh" }} className="overflow-auto mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Suggestions
            </Card.Header>
            <Table className="mb-0" bordered={true} striped={true} hover={true}>
              <tbody>
                {data?.suggestions?.value?.map((name, idx) => (
                  <tr key={idx}>
                    <td>{name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ maxHeight: "25vh" }} className="overflow-auto mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Uncomfortable when
            </Card.Header>
            <Table className="mb-0" bordered={true} striped={true} hover={true}>
              <tbody>
                {data?.yesUncomfortable?.value?.map((name, idx) => (
                  <tr key={idx}>
                    <td>{name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <h3>Stats</h3>
      <Row>
        <Col md="4">
          <Card style={{ maxHeight: "25vh" }} className="overflow-auto mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Programs
            </Card.Header>
            <Table className="mb-0" bordered={true} striped={true} hover={true}>
              <tbody>
                {data?.programName?.map((row, idx) => (
                  <tr key={idx}>
                    <td>{row.x}</td>
                    <td>{row.y}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col md="4">
          <Card style={{ maxHeight: "30vh" }} className="overflow-auto mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Schools
            </Card.Header>
            <Table className="mb-0" bordered={true} striped={true} hover={true}>
              <tbody>
                {data?.school?.map((row, idx) => (
                  <tr key={idx}>
                    <td>{row.x}</td>
                    <td>{row.y}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <h3>Parent responses</h3>
      <Row>
        {/* relationship */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Relationship with learner
            </Card.Header>
            <VictoryPie
              data={data?.relationship}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* age */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Learner Age
            </Card.Header>
            <VictoryPie
              data={data?.age}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* sex */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Learner Sex
            </Card.Header>
            <VictoryPie
              data={data?.sex}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* school board */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Learner School Board
            </Card.Header>
            <VictoryPie
              data={data?.board}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* ease of navigation */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Ease of navigating website
            </Card.Header>
            <VictoryPie
              data={data?.easeOfNavigation}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
            {/* <VictoryChart theme={VictoryTheme.material} domainPadding={10}>
              <VictoryBar
                style={{ data: { fill: "#c43a31" } }}
                data={data?.difficulty}
              />
            </VictoryChart> */}
          </Card>
        </Col>
        {/* hand holding */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Was hand holding required?
            </Card.Header>
            <VictoryPie
              data={data?.handHolding}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* time taken */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              How much time did the learner take to complete?
            </Card.Header>
            <VictoryPie
              data={data?.timeTaken}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* impact */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Did learner have an impact?
            </Card.Header>
            <VictoryPie
              data={data?.impact}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* yes impact */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              List of impacts
            </Card.Header>
            <VictoryPie
              data={data?.yesImpact}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* program value */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Suggested value of program
            </Card.Header>
            <VictoryPie
              data={data?.programValue}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* recommendation */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Probability of recommending
            </Card.Header>
            <VictoryPie
              data={data?.recommendation}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* was uncomfortable */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Were you uncomfortable during the program?
            </Card.Header>
            <VictoryPie
              data={data?.wasUncomfortable}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
      </Row>
      <h3>Child responses</h3>
      <Row>
        {/* program likeness */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              How much did they like the program?
            </Card.Header>
            <VictoryPie
              data={data?.prorgramLikeness}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* difficulty */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              How difficult were the programs?
            </Card.Header>
            <VictoryPie
              data={data?.difficulty}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* character liked */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Which character did they like the most?
            </Card.Header>
            <VictoryPie
              data={data?.character}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* ease of understanding */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              How easy was the program content?
            </Card.Header>
            <VictoryPie
              data={data?.easyOfUnderstanding}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* ease of discussion */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              How easy was the discussion?
            </Card.Header>
            <VictoryPie
              data={data?.easeOfDiscussion}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
      </Row>
      <h4>Ratings</h4>
      <Row>
        {/* rating watch */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Watch
            </Card.Header>
            <VictoryPie
              data={data?.watch}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* rating learn */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Learn
            </Card.Header>
            <VictoryPie
              data={data?.learn}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* rating discuss */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Discuss
            </Card.Header>
            <VictoryPie
              data={data?.discuss}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
            {/* <VictoryChart theme={VictoryTheme.material} domainPadding={10}>
              <VictoryBar
                style={{ data: { fill: "#c43a31" } }}
                data={data?.difficulty}
              />
            </VictoryChart> */}
          </Card>
        </Col>
        {/* rating play */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Play
            </Card.Header>
            <VictoryPie
              data={data?.play}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* rating faceoff */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Faceoff
            </Card.Header>
            <VictoryPie
              data={data?.faceoff}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* rating scoreboard */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Scoreboard
            </Card.Header>
            <VictoryPie
              data={data?.scoreboard}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* rating moodboard */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Moodboard
            </Card.Header>
            <VictoryPie
              data={data?.moodboard}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
        {/* rating coupons */}
        <Col md="4">
          <Card style={{ maxHeight: "40vh" }} className="overflow-x-auto overflow-y-hidden mb-3">
            <Card.Header className="position-sticky top-0 bg-light">
              Gift coupons
            </Card.Header>
            <VictoryPie
              data={data?.coupons}
              startAngle={90}
              endAngle={-90}
              animate={{ duration: 2000 }}
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              theme={VictoryTheme.material}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
