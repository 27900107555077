import React from "react";
import { SignIn } from "../../template/signin";

export const Login = (props) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      <h1 className="mb-5">Admin login</h1>
      <SignIn />
    </div>
  );
};
