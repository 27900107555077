import React, { Component } from "react";
import { withRouter } from "../../atoms/withRouter_hoc";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BreadcrumbItem from "react-bootstrap/BreadcrumbItem";
import Spinner from "react-bootstrap/Spinner";
import { Play } from "../../template/play";
import { Watch } from "../../template/watch";
import { admin_api } from "../../atoms/api";
import { Learn } from "../../template/learn";
import { Discuss } from "../../template/discuss";

class Level extends Component {
  constructor(props) {
    super();
    document.title = "UnTaboo | Level " + props.params.levelId;
    this.state = {
      selectedTab: "watch",
      getLoading: true,
      getError: null,
      level: {},
      program: {},
    };
  }

  componentDidMount() {
    admin_api
      .get(`/levels/${this.props.params.levelId}`)
      .then((res) => {
        const { program, ...level } = res.data;
        this.setState({
          level,
          program,
          getLoading: false,
        });
      })
      .catch((err) =>
        this.setState({
          getLoading: false,
          getError: err?.response?.data?.message || "Error",
        })
      );
  }

  dismissGetError = () => this.setState({ getError: null });

  setSelectedTab = (tab) => this.setState({ selectedTab: tab });

  render() {
    return (
      <Container fluid={true} className="pt-3 position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
          <Toast show={this.state.getLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting level info
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.getError !== null}
            bg="danger"
            onClose={this.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getError}</ToastBody>
          </Toast>
        </ToastContainer>
        <Breadcrumb>
          <BreadcrumbItem href="/">Dashboard</BreadcrumbItem>
          <BreadcrumbItem href={`/program/${this.state.program?.id}`}>
            {this.state.program?.name}
          </BreadcrumbItem>
          <BreadcrumbItem active>{this.state.level?.title}</BreadcrumbItem>
        </Breadcrumb>
        <Tabs
          activeKey={this.state.selectedTab}
          onSelect={(k) => this.setSelectedTab(k)}
          className="mb-3"
        >
          <Tab eventKey="watch" title="Watch">
            <Watch levelId={this.props.params.levelId} />
          </Tab>
          {this.state.level?.type === "level" && (
            <Tab eventKey="learn" title="Learn">
              <Learn levelId={this.props.params.levelId} />
            </Tab>
          )}
          {this.state.level?.type === "level" && (
            <Tab eventKey="discuss" title="Discuss">
              <Discuss levelId={this.props.params.levelId} />
            </Tab>
          )}
          <Tab eventKey="play" title="Play">
            <Play levelId={this.props.params.levelId} />
          </Tab>
        </Tabs>
        <div></div>
      </Container>
    );
  }
}

export default withRouter(Level);
