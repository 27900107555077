import React from "react";
import { AdminGiftSchema, AdminGiftUpdateSchema } from "../../atoms/schemas";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormSelect from "react-bootstrap/FormSelect";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import { formatDate } from "../../atoms/formatDate";
import Badge from "react-bootstrap/Badge";
import { Clipboard2Check } from "react-bootstrap-icons";

export const AdminGift = (props) => {
  const copyToClipboard = (code) => {
    navigator.clipboard
      .writeText(`https://untaboo.online/gift/redeem/special/${code}`)
      .then(() => {
        alert("Copied");
      });
  };

  return (
    <Container fluid={true} className="position-relative">
      <ToastContainer position="top-end" className="pt-2 pe-md-1">
        <Toast show={props.getPLoading} bg="info">
          <ToastBody className="text-white">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Getting list of program...
          </ToastBody>
        </Toast>
        <Toast show={props.getLoading} bg="info">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Getting all gifts
          </ToastBody>
        </Toast>
        <Toast show={props.deleteLoading} bg="warning">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Deleting gift
          </ToastBody>
        </Toast>
        <Toast
          show={props.addSuccess}
          bg="success"
          delay={2000}
          autohide={true}
          onClose={props.closeAddSuccess}
        >
          <ToastBody className="text-white">Gift added successfully</ToastBody>
        </Toast>
        <Toast
          show={props.editSuccess}
          bg="success"
          delay={2000}
          autohide={true}
          onClose={props.closeEditSuccess}
        >
          <ToastBody className="text-white">Gift edited successfully</ToastBody>
        </Toast>
        <Toast
          show={props.deleteSuccess}
          bg="success"
          delay={2000}
          autohide={true}
          onClose={props.closeDeleteSuccess}
        >
          <ToastBody className="text-white">
            Gift deleted successfully
          </ToastBody>
        </Toast>
        <Toast
          show={props.addError !== null}
          bg="danger"
          onClose={props.dismissAddError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Adding error</strong>
          </ToastHeader>
          <ToastBody>{props.addError}</ToastBody>
        </Toast>
        <Toast
          show={props.getError !== null}
          bg="danger"
          onClose={props.dismissGetError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Fetch error</strong>
          </ToastHeader>
          <ToastBody>{props.getError}</ToastBody>
        </Toast>
        <Toast
          show={props.editError !== null}
          bg="danger"
          onClose={props.dismissEditError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Editing error</strong>
          </ToastHeader>
          <ToastBody>{props.editError}</ToastBody>
        </Toast>
        <Toast
          show={props.deleteError !== null}
          bg="danger"
          onClose={props.dismissDeleteError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Deleting error</strong>
          </ToastHeader>
          <ToastBody>{props.deleteError}</ToastBody>
        </Toast>
        <Toast
          show={props.getPError !== null}
          bg="danger"
          onClose={props.dismissGetProgramError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Program fetch error</strong>
          </ToastHeader>
          <ToastBody>{props.getPError}</ToastBody>
        </Toast>
      </ToastContainer>
      <Button
        onClick={props.handleAdd}
        variant="primary"
        type="button"
        className="mb-3 text-white"
      >
        Add gift
      </Button>
      <Table hover={true} striped={true} bordered={true} responsive="sm">
        <thead>
          <tr>
            <th>Gift code</th>
            <th>Program</th>
            <th>Status</th>
            <th>Created at</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.gifts.map((g, index) => (
            <tr key={index}>
              <td>
                {g.gift_code}{" "}
                <Clipboard2Check onClick={() => copyToClipboard(g.gift_code)} />
              </td>
              <td>{g.program?.name}</td>
              <td>
                {g.is_active ? (
                  <Badge pill={true} bg="info">
                    Active
                  </Badge>
                ) : (
                  <Badge pill={true} bg="success">
                    Redeemed
                  </Badge>
                )}
              </td>
              <td>{formatDate(g?.createdAt, "Invalid date")}</td>
              <td>
                {g.is_active && (
                  <div className="d-flex flex-column align-items-start flex-md-row justify-content-md-start">
                    <Button
                      variant="secondary"
                      type="button"
                      className="mb-2 mb-md-0 me-2"
                      onClick={() => props.handleEdit(g.gift_code)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      onClick={() => props.handleDelete(g.gift_code)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={props.add} onHide={props.handleAddClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Add a new gift</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              program_id: "",
              count: 1,
            }}
            onSubmit={(values) => {
              props.addSubmit(values);
            }}
            validationSchema={AdminGiftSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              values,
              touched,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Row className="g-2 mb-2">
                  <FormGroup>
                    <FloatingLabel
                      controlId="add-gift.program_id"
                      label="Program"
                    >
                      <FormSelect
                        name="program_id"
                        value={values.program_id}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.program_id && !errors.program_id}
                        isInvalid={touched.program_id && !!errors.program_id}
                        disabled={props.getPLoading || props.getPError !== null}
                      >
                        {[
                          <option value="" key="select_a_program">
                            Select a program
                          </option>,
                        ].concat(
                          ...props.programs.map((p, index) => (
                            <option key={index} value={p.id}>
                              {p.name}
                            </option>
                          ))
                        )}
                      </FormSelect>
                      {props.getPLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {touched.program_id && (
                        <FormControl.Feedback type="invalid">
                          {errors.program_id}
                        </FormControl.Feedback>
                      )}
                      {props.getPError && (
                        <span className="text-danger">{props.getPError}</span>
                      )}
                    </FloatingLabel>
                  </FormGroup>
                </Row>
                <Row className="g-2 mb-2">
                  <FloatingLabel
                    label="Number of gifts"
                    controlId="add-gift.count"
                  >
                    <FormControl
                      name="count"
                      type="number"
                      placeholder="Number of gifts"
                      value={values.count}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      isValid={touched.count && !errors.count}
                      isInvalid={touched.count && !!errors.count}
                    />
                    {touched.count && (
                      <FormControl.Feedback type="invalid">
                        {errors.count}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Row>
                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={props.handleAddClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    type="submit"
                    variant="primary"
                    disabled={props.addLoading}
                  >
                    {props.addLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Adding...",
                        ]
                      : "Add gift"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal show={props.edit} onHide={props.handleEditClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Edit gift</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={props.gifts.find(
              (w) => w["gift_code"] === props.editId
            )}
            onSubmit={(values) => {
              props.editSubmit(values);
            }}
            validationSchema={AdminGiftUpdateSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              values,
              touched,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Row className="g-2 mb-2">
                  <FormGroup>
                    <FloatingLabel
                      controlId="add-gift.program_id"
                      label="Program"
                    >
                      <FormSelect
                        name="program_id"
                        value={values.program_id}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.program_id && !errors.program_id}
                        isInvalid={touched.program_id && !!errors.program_id}
                        disabled={props.getPLoading || props.getPError !== null}
                      >
                        {[
                          <option value="" key="select_a_program">
                            Select a program
                          </option>,
                        ].concat(
                          ...props.programs.map((p, index) => (
                            <option key={index} value={p.id}>
                              {p.name}
                            </option>
                          ))
                        )}
                      </FormSelect>
                      {props.getPLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {touched.program_id && (
                        <FormControl.Feedback type="invalid">
                          {errors.program_id}
                        </FormControl.Feedback>
                      )}
                      {props.getPError && (
                        <span className="text-danger">{props.getPError}</span>
                      )}
                    </FloatingLabel>
                  </FormGroup>
                </Row>
                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={props.handleEditClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    type="submit"
                    variant="primary"
                    disabled={props.editLoading}
                  >
                    {props.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Edit gift"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal show={props.delete} onHide={props.handleDeleteClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Delete gift</ModalTitle>
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this gift?</ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            type="button"
            onClick={props.handleDeleteClose}
          >
            Close
          </Button>
          <Button variant="danger" type="button" onClick={props.deleteSubmit}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};
