import React from "react";
import { UserQuestionsSchema } from "../../atoms/schemas";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormCheck from "react-bootstrap/FormCheck";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import { InfoSpan } from "../../pages/program";
import Badge from "react-bootstrap/Badge";

export const UserQuestions = (props) => {
  return (
    <Container fluid={true} className="position-relative">
      <ToastContainer position="top-end" className="pt-2 pe-md-1">
        <Toast show={props.getLoading} bg="info">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Getting all questions
          </ToastBody>
        </Toast>
        <Toast
          show={props.editSuccess}
          bg="success"
          delay={2000}
          autohide={true}
          onClose={props.closeEditSuccess}
        >
          <ToastBody className="text-white">
            User question edited successfully
          </ToastBody>
        </Toast>
        <Toast
          show={props.getError !== null}
          bg="danger"
          onClose={props.dismissGetError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Fetch error</strong>
          </ToastHeader>
          <ToastBody>{props.getError}</ToastBody>
        </Toast>
        <Toast
          show={props.editError !== null}
          bg="danger"
          onClose={props.dismissEditError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Editing error</strong>
          </ToastHeader>
          <ToastBody>{props.editError}</ToastBody>
        </Toast>
      </ToastContainer>
      <Table hover={true} striped={true} bordered={true} responsive="sm">
        <thead>
          <tr>
            <th>Question</th>
            <th>Program</th>
            <th>Level</th>
            <th>Asked by</th>
            <th>Response</th>
            <th>Show in FAQ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.questions.map((q, index) => (
            <tr key={index}>
              <td>{q?.question_text}</td>
              <td>{q?.level?.program?.name}</td>
              <td>{q?.level?.title}</td>
              <td>
                {q?.is_anonymous ? (
                  <InfoSpan>Anonymous</InfoSpan>
                ) : (
                  `${q?.learner?.first_name} ${q?.learner?.last_name}`
                )}
              </td>
              <td>{q?.response}</td>
              <td>
                {q?.is_faq && (
                  <Badge bg="success" pill>
                    part of FAQ
                  </Badge>
                )}
              </td>
              <td>
                <div className="d-flex flex-column align-items-start flex-md-row justify-content-md-start">
                  <Button
                    variant="secondary"
                    type="button"
                    className="mb-2 mb-md-0 me-2"
                    onClick={() => props.handleEdit(q.id)}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={props.edit} onHide={props.handleEditClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Edit response</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={props.questions.find(
              (q) => q["id"] === props.editId
            )}
            onSubmit={(values) => {
              if ("question_text" in values) delete values.question_text;
              props.editSubmit(values);
            }}
            validationSchema={UserQuestionsSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              values,
              touched,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Row className="g-2 mb-2">
                  <FloatingLabel
                    label="Question text"
                    controlId="edit-question.question_text"
                  >
                    <FormControl
                      name="question_text"
                      type="text"
                      placeholder="Question text"
                      value={values.question_text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      isValid={touched.question_text && !errors.question_text}
                      isInvalid={
                        touched.question_text && !!errors.question_text
                      }
                    />
                    {touched.question_text && (
                      <FormControl.Feedback type="invalid">
                        {errors.question_text}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Row>
                <Row className="g-2 mb-2">
                  <FloatingLabel
                    label="Response"
                    controlId="edit-question.response"
                  >
                    <FormControl
                      type="text"
                      as="textarea"
                      value={values.response}
                      name="response"
                      placeholder="Response"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.response && !errors.response}
                      isInvalid={touched.response && !!errors.response}
                      style={{ minHeight: "100px" }}
                    />
                    {touched.response && (
                      <FormControl.Feedback type="invalid">
                        {errors.response}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Row>
                <Row className="g-2 mb-2">
                  <Col>
                    <FormCheck
                      type="checkbox"
                      name="is_faq"
                      id="is_faq"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.is_faq}
                      value={values.is_faq}
                      isValid={touched.is_faq && !errors.is_faq}
                      isInvalid={touched.is_faq && !!errors.is_faq}
                      label="Show in FAQ"
                      inline
                    />
                    {touched.is_faq && (
                      <FormControl.Feedback type="invalid">
                        {errors.is_faq}
                      </FormControl.Feedback>
                    )}
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={props.handleEditClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    type="submit"
                    variant="primary"
                    disabled={props.editLoading}
                  >
                    {props.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save response"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </Container>
  );
};
