import React from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";

export const Users = (props) => {
  return (
    <Container fluid={true} className="position-relative">
      <ToastContainer position="top-end" className="pt-2 pe-md-1">
        <Toast show={props.loading} bg="info">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Getting all users
          </ToastBody>
        </Toast>
        <Toast
          show={props.error !== null}
          bg="danger"
          onClose={props.dismissError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Fetch error</strong>
          </ToastHeader>
          <ToastBody>{props.error}</ToastBody>
        </Toast>
      </ToastContainer>
      <Table hover={true} striped={true} bordered={true} responsive="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Gender</th>
            <th>Date of birth</th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((u) => (
            <tr key={u["id"]}>
              <td>{`${u["first_name"]} ${u["last_name"]}`}</td>
              <td>{u["email_id"]}</td>
              <td>
                <Badge pill={true} bg="secondary">
                  {u["gender"]}
                </Badge>
              </td>
              <td>{u["date_of_birth"]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
