import React from "react";
import { WebinarSchema } from "../../atoms/schemas";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import { UtImg } from "../../atoms/img";
import { formatDate } from "../../atoms/formatDate";
import { InfoSpan } from "../../pages/program";
import FormLabel from "react-bootstrap/FormLabel";
import Badge from "react-bootstrap/Badge";

export const Webinar = (props) => {
  return (
    <Container fluid={true} className="position-relative">
      <ToastContainer position="top-end" className="pt-2 pe-md-1">
        <Toast show={props.getWebinarLoading} bg="info">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Getting all webinars
          </ToastBody>
        </Toast>
        <Toast show={props.deleteWebinarLoading} bg="warning">
          <ToastBody>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Deleting webinar
          </ToastBody>
        </Toast>
        <Toast
          show={props.addWebinarSuccess}
          bg="success"
          delay={2000}
          autohide={true}
          onClose={props.closeAddSuccess}
        >
          <ToastBody className="text-white">
            Webinar added successfully
          </ToastBody>
        </Toast>
        <Toast
          show={props.editWebinarSuccess}
          bg="success"
          delay={2000}
          autohide={true}
          onClose={props.closeEditSuccess}
        >
          <ToastBody className="text-white">
            Webinar edited successfully
          </ToastBody>
        </Toast>
        <Toast
          show={props.deleteWebinarSuccess}
          bg="success"
          delay={2000}
          autohide={true}
          onClose={props.closeDeleteSuccess}
        >
          <ToastBody className="text-white">
            Webinar deleted successfully
          </ToastBody>
        </Toast>
        <Toast
          show={props.addWebinarError !== null}
          bg="danger"
          onClose={props.dismissAddError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Adding error</strong>
          </ToastHeader>
          <ToastBody>{props.addWebinarError}</ToastBody>
        </Toast>
        <Toast
          show={props.getWebinarError !== null}
          bg="danger"
          onClose={props.dismissGetError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Fetch error</strong>
          </ToastHeader>
          <ToastBody>{props.getWebinarError}</ToastBody>
        </Toast>
        <Toast
          show={props.editWebinarError !== null}
          bg="danger"
          onClose={props.dismissEditError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Editing error</strong>
          </ToastHeader>
          <ToastBody>{props.editWebinarError}</ToastBody>
        </Toast>
        <Toast
          show={props.deleteWebinarError !== null}
          bg="danger"
          onClose={props.dismissDeleteError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Deleting error</strong>
          </ToastHeader>
          <ToastBody>{props.deleteWebinarError}</ToastBody>
        </Toast>
      </ToastContainer>
      <Button
        onClick={props.handleAddWebinar}
        variant="primary"
        type="button"
        className="mb-3 text-white"
      >
        Add webinar
      </Button>
      <Table hover={true} striped={true} bordered={true} responsive="sm">
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Event on</th>
            <th>Registrations open till</th>
            <th>Event Link</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.webinars.map((w) => (
            <tr key={w.id}>
              <td>
                <UtImg
                  src={w.title_image?.filePath}
                  alt={w.title_image?.file}
                  width="50px"
                  height="auto"
                />
              </td>
              <td>{w.title}</td>
              <td>{formatDate(w?.event_on, "Coming soon")}</td>
              <td>{formatDate(w?.registration_open_till, "No limit set")}</td>
              <td>
                {w?.event_link ? (
                  <a
                    type="button"
                    href={w?.event_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Recorded
                  </a>
                ) : (
                  <InfoSpan>Not recorded yet</InfoSpan>
                )}
              </td>
              <td>
                {w?.is_special && (
                  <Badge bg="success" pill>
                    Special
                  </Badge>
                )}
              </td>
              <td>
                <div className="d-flex flex-column align-items-start flex-md-row justify-content-md-start">
                  <Button
                    variant="secondary"
                    type="button"
                    className="mb-2 mb-md-0 me-2"
                    onClick={() => props.handleEditWebinar(w.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => props.handleDeleteWebinarOpen(w.id)}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={props.addWebinar} onHide={props.handleAddWebinarClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Add a new webinar</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              title_image: undefined,
              title: "",
              event_on: "",
              registration_open_till: "",
              event_link: "",
              is_special: false,
            }}
            onSubmit={(values, { setStatus }) => {
              if (!values.title_image) {
                setStatus({ title_image: "Title image is required." });
                return null;
              } else if (!values.title_image["type"].match(/image/)) {
                setStatus({
                  title_image: "Title image should be an image",
                });
                return null;
              } else {
                let values_form_data = new FormData();
                Object.keys(values).forEach((key) => {
                  if (!!values[key]) values_form_data.append(key, values[key]);
                });
                props.addWebinarSubmit(values_form_data);
              }
            }}
            validationSchema={WebinarSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setTouched,
              setStatus,
              errors,
              values,
              touched,
              status,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Row className="g-2 mb-2">
                  <FloatingLabel
                    label="Webinar title"
                    controlId="add-webinar.title"
                  >
                    <FormControl
                      name="title"
                      type="text"
                      placeholder="Webinar title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      isValid={touched.title && !errors.title}
                      isInvalid={touched.title && !!errors.title}
                    />
                    {touched.title && (
                      <FormControl.Feedback type="invalid">
                        {errors.title}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Row>
                <Row className="g-2 mb-2">
                  <Col md>
                    <FloatingLabel
                      label="Event on"
                      controlId="add-webinar.event_on"
                    >
                      <FormControl
                        type="date"
                        value={values.event_on}
                        name="event_on"
                        placeholder="Event on"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.event_on && !errors.event_on}
                        isInvalid={touched.event_on && !!errors.event_on}
                      />
                      {touched.event_on && (
                        <FormControl.Feedback type="invalid">
                          {errors.event_on}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <FloatingLabel
                      label="Registration ends on"
                      controlId="add-webinar.registration_open_till"
                    >
                      <FormControl
                        type="date"
                        value={values.registration_open_till}
                        name="registration_open_till"
                        placeholder="Registration ends on"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.registration_open_till &&
                          !errors.registration_open_till
                        }
                        isInvalid={
                          touched.registration_open_till &&
                          !!errors.registration_open_till
                        }
                      />
                      {touched.registration_open_till && (
                        <FormControl.Feedback type="invalid">
                          {errors.registration_open_till}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="g-2 mb-2">
                  <Col md>
                    <FloatingLabel
                      label="Event link"
                      controlId="add-webinar.event_link"
                    >
                      <FormControl
                        name="event_link"
                        type="url"
                        placeholder="Event link"
                        value={values.event_link}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        isValid={touched.event_link && !errors.event_link}
                        isInvalid={touched.event_link && !!errors.event_link}
                      />
                      {touched.event_link && (
                        <FormControl.Feedback type="invalid">
                          {errors.event_link}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="3">
                    <FormCheck
                      type="checkbox"
                      name="is_special"
                      id="is_special"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.is_special}
                      value={values.is_special}
                      isValid={touched.is_special && !errors.is_special}
                      isInvalid={touched.is_special && !!errors.is_special}
                      label="Is special"
                      inline
                    ></FormCheck>
                  </Col>
                </Row>
                <FormGroup className="mb-2">
                  <FormLabel htmlFor="add-webinar.title_image">
                    Title image
                  </FormLabel>
                  <FormControl
                    type="file"
                    accept="image/*"
                    name="title_image"
                    id="add-webinar.title_image"
                    onChange={(event) => {
                      setFieldValue(
                        "title_image",
                        event.currentTarget.files[0]
                      );
                      if (event.currentTarget.files[0]["type"].match(/image/)) {
                        setStatus({ title_image: null });
                      } else {
                        setStatus({
                          title_image: "Title image should be an image",
                        });
                      }
                    }}
                    onBlur={() => {
                      setTouched({ ...touched, title_image: true });
                      if (!values.title_image) {
                        setStatus({
                          title_image: "Title image is required",
                        });
                      }
                    }}
                    required={true}
                    isValid={touched.title_image && !status?.title_image}
                    isInvalid={touched.title_image && !!status?.title_image}
                  />
                  {touched.title_image && (
                    <FormControl.Feedback type="invalid">
                      {status?.title_image}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={props.handleAddWebinarClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    type="submit"
                    variant="primary"
                    disabled={props.addWebinarLoading}
                  >
                    {props.addWebinarLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Adding...",
                        ]
                      : "Add webinar"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal show={props.editWebinar} onHide={props.handleEditWebinarClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Edit webinar</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={props.webinars.find(
              (w) => w["id"] === props.editWebinarId
            )}
            onSubmit={(values, { setStatus }) => {
              if (
                (!!values?.title_image &&
                  "type" in values.title_image &&
                  !values.title_image?.type.match(/image/)) ||
                (!!values?.title_image &&
                  "fileType" in values &&
                  !values.title_image?.fileType.match(/image/))
              ) {
                setStatus({
                  title_image: "Title image should be an image",
                });
                return null;
              } else {
                let values_form_data = new FormData();
                Object.keys(values).forEach((key) => {
                  if (!!values[key]) values_form_data.append(key, values[key]);
                });
                props.editWebinarSubmit(values_form_data);
              }
            }}
            validationSchema={WebinarSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setTouched,
              setStatus,
              errors,
              values,
              touched,
              status,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Row className="g-2 mb-2">
                  <FloatingLabel
                    label="Webinar title"
                    controlId="edit-webinar.title"
                  >
                    <FormControl
                      name="title"
                      type="text"
                      placeholder="Webinar title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      isValid={touched.title && !errors.title}
                      isInvalid={touched.title && !!errors.title}
                    />
                    {touched.title && (
                      <FormControl.Feedback type="invalid">
                        {errors.title}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Row>
                <Row className="g-2 mb-2">
                  <Col md>
                    <FloatingLabel
                      label="Event on"
                      controlId="edit-webinar.event_on"
                    >
                      <FormControl
                        type="date"
                        value={values.event_on}
                        name="event_on"
                        placeholder="Event on"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.event_on && !errors.event_on}
                        isInvalid={touched.event_on && !!errors.event_on}
                      />
                      {touched.event_on && (
                        <FormControl.Feedback type="invalid">
                          {errors.event_on}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <FloatingLabel
                      label="Registration ends on"
                      controlId="edit-webinar.registration_open_till"
                    >
                      <FormControl
                        type="date"
                        value={values.registration_open_till}
                        name="registration_open_till"
                        placeholder="Registration ends on"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.registration_open_till &&
                          !errors.registration_open_till
                        }
                        isInvalid={
                          touched.registration_open_till &&
                          !!errors.registration_open_till
                        }
                      />
                      {touched.registration_open_till && (
                        <FormControl.Feedback type="invalid">
                          {errors.registration_open_till}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="g-2 mb-2">
                  <Col md>
                    <FloatingLabel
                      label="Event link"
                      controlId="edit-webinar.event_link"
                    >
                      <FormControl
                        name="event_link"
                        type="url"
                        placeholder="Event link"
                        value={values.event_link}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        isValid={touched.event_link && !errors.event_link}
                        isInvalid={touched.event_link && !!errors.event_link}
                      />
                      {touched.event_link && (
                        <FormControl.Feedback type="invalid">
                          {errors.event_link}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="3">
                    <FormCheck
                      type="checkbox"
                      name="is_special"
                      id="is_special"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.is_special}
                      value={values.is_special}
                      isValid={touched.is_special && !errors.is_special}
                      isInvalid={touched.is_special && !!errors.is_special}
                      label="Is special"
                      inline
                    ></FormCheck>
                  </Col>
                </Row>
                <FormGroup className="mb-2">
                  <FormLabel>
                    Title Image
                    <br />
                    {values?.title_image?.file}
                  </FormLabel>
                  {values?.title_image?.file && (
                    <InfoSpan
                      onClick={() => {
                        setFieldValue("title_image", "");
                      }}
                    >
                      &nbsp;Clear
                    </InfoSpan>
                  )}
                  <FormControl
                    type="file"
                    accept="image/*"
                    name="title_image"
                    id="edit-webinar.title_image"
                    onChange={(event) => {
                      setFieldValue(
                        "title_image",
                        event.currentTarget.files[0]
                      );
                      if (event.currentTarget.files[0]["type"].match(/image/)) {
                        setStatus({ title_image: null });
                      } else {
                        setStatus({
                          title_image: "Title image should be an image",
                        });
                      }
                    }}
                    onBlur={() => {
                      setTouched({ ...touched, title_image: true });
                      if (!values.title_image) {
                        setStatus({
                          title_image: "Title image is required",
                        });
                      }
                    }}
                    required={true}
                    isValid={touched.title_image && !status?.title_image}
                    isInvalid={touched.title_image && !!status?.title_image}
                  />
                  {touched.title_image && (
                    <FormControl.Feedback type="invalid">
                      {status?.title_image}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={props.handleEditWebinarClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    type="submit"
                    variant="primary"
                    disabled={props.editWebinarLoading}
                  >
                    {props.editWebinarLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Edit webinar"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal show={props.deleteWebinar} onHide={props.handleDeleteWebinarClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Delete webinar</ModalTitle>
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this webinar?</ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            type="button"
            onClick={props.handleDeleteWebinarClose}
          >
            Close
          </Button>
          <Button
            variant="danger"
            type="button"
            onClick={props.handleDeleteWebinar}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};
