import React, { Component } from "react";
import { withRouter } from "../../atoms/withRouter_hoc";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { admin_api } from "../../atoms/api";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BreadcrumbItem from "react-bootstrap/BreadcrumbItem";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalBody from "react-bootstrap/ModalBody";
import Form from "react-bootstrap/Form";
import FormSelect from "react-bootstrap/FormSelect";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import { Formik } from "formik";
import { LevelSchema } from "../../atoms/schemas";
import { Navigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { UtImg } from "../../atoms/img";

export const InfoSpan = styled.span`
  font-size: 12px;
  color: gray;
`;

class Program extends Component {
  constructor(props) {
    super();
    document.title = "UnTaboo | Program " + props.params.programId;
    this.state = {
      selectedLevelId: null,
      addLevel: false,
      addLevelSuccess: false,
      addLevelLoading: false,
      addLevelError: null,
      getLevelLoading: true,
      getLevelError: null,
      program: {
        name: "Loading...",
      },
      levels: [],
      editLevel: false,
      editLevelId: null,
      editLevelSuccess: false,
      editLevelLoading: false,
      editLevelError: null,
      deleteLevel: false,
      deleteLevelId: null,
      deleteLevelSuccess: false,
      deleteLevelLoading: false,
      deleteLevelError: null,
    };
  }

  componentDidMount() {
    admin_api
      .get(`/programs/${this.props.params.programId}`)
      .then((res) => {
        const { levels, ...program } = res.data;
        levels.sort(
          (a, b) =>
            program["level_sequence"].indexOf(a["id"]) -
            program["level_sequence"].indexOf(b["id"])
        );
        this.setState({
          getLevelLoading: false,
          program,
          levels,
        });
      })
      .catch((err) =>
        this.setState({
          getLevelLoading: false,
          getLevelError: err?.response?.data?.message || "Error",
        })
      );
  }

  closeAddSuccess = () => this.setState({ addLevelSuccess: false });
  closeEditSuccess = () => this.setState({ editLevelSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteLevelSuccess: false });

  dismissAddError = () => this.setState({ addLevelError: null });
  dismissGetError = () => this.setState({ getLevelError: null });
  dismissEditError = () => this.setState({ editLevelError: null });
  dismissDeleteError = () => this.setState({ deleteLevelError: null });

  handleAddLevel = () => this.setState({ addLevel: true });
  handleAddLevelClose = () =>
    this.setState({ addLevel: false, addLevelId: null });
  handleEditLevelOpen = (levelId) =>
    this.setState({ editLevel: true, editLevelId: levelId });
  handleEditLevelClose = () =>
    this.setState({ editLevel: false, editLevelId: null });
  handleDeleteLevelOpen = (levelId) =>
    this.setState({ deleteLevel: true, deleteLevelId: levelId });
  handleDeleteLevelClose = () =>
    this.setState({ deleteLevel: false, deleteLevelId: null });

  handleDeleteLevel = () => {
    this.setState({ deleteLevelLoading: true, deleteLevel: false });
    admin_api
      .delete("levels/" + this.state.deleteLevelId)
      .then((res) => {
        let level_sequence = this.state.program.level_sequence || [];
        let index = level_sequence.indexOf(this.state.deleteLevelId);
        level_sequence.splice(index, 1);
        let levels = this.state.levels.filter(
          (l) => l["id"] !== this.state.deleteLevelId
        );
        this.setState({
          deleteLevelId: null,
          deleteLevelLoading: false,
          deleteLevelSuccess: true,
          program: {
            ...this.state.program,
            level_sequence,
          },
          levels,
        });
      })
      .catch((err) =>
        this.setState({
          deleteLevelId: null,
          deleteLevelLoading: false,
          deleteLevelError: err?.response?.data?.message || "Error",
        })
      );
  };

  seeLevel = (id) => this.setState({ selectedLevelId: id });

  dragStartHandler = (e) => {
    e.dataTransfer.setData("levelId", e.target.getAttribute("data-levelid"));
    e.dataTransfer.setData("pickIndex", e.target.getAttribute("data-rowindex"));
    e.dataTransfer.dropEffect = "move";
  };

  dragOverHandler = (e) => {
    e.preventDefault();
  };

  dropHandler = (e) => {
    e.preventDefault();
    this.setState({ editLevelLoading: true });
    const dropRow = e.currentTarget.getAttribute("data-rowindex");
    const levelId = e.dataTransfer.getData("levelId");
    admin_api
      .patch(
        `/programs/${this.props.params.programId}/level-sequence/level/${levelId}/index/${dropRow}`
      )
      .then((res) => {
        let newLevelSequence = res.data;
        this.setState({
          editLevelLoading: false,
          editLevelSuccess: true,
          program: {
            ...this.state.program,
            level_sequence: newLevelSequence,
          },
          levels: this.state.levels.sort(
            (a, b) =>
              newLevelSequence.indexOf(a["id"]) -
              newLevelSequence.indexOf(b["id"])
          ),
        });
      })
      .catch((err) =>
        this.setState({
          editLevelLoading: false,
          editLevelError: err?.response?.data?.message || "Error",
        })
      );
  };

  render() {
    if (this.state.selectedLevelId !== null) {
      return <Navigate to={`/level/${this.state.selectedLevelId}`} />;
    } else {
      return (
        <Container fluid={true} className="pt-3 position-relative">
          <ToastContainer position="top-end" className="pt-2 pe-md-1">
            <Toast show={this.state.getLevelLoading} bg="info">
              <ToastBody className="text-white">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Getting levels
              </ToastBody>
            </Toast>
            <Toast show={this.state.deleteLevelLoading} bg="warning">
              <ToastBody>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Deleting level
              </ToastBody>
            </Toast>
            <Toast
              show={this.state.addLevelSuccess}
              bg="success"
              delay={2000}
              autohide={true}
              onClose={this.closeAddSuccess}
            >
              <ToastBody className="text-white">
                Level added successfully
              </ToastBody>
            </Toast>
            <Toast
              show={this.state.editLevelSuccess}
              bg="success"
              delay={2000}
              autohide={true}
              onClose={this.closeEditSuccess}
            >
              <ToastBody className="text-white">
                Level changes saved successfully
              </ToastBody>
            </Toast>
            <Toast
              show={this.state.deleteLevelSuccess}
              bg="success"
              delay={2000}
              autohide={true}
              onClose={this.closeDeleteSuccess}
            >
              <ToastBody className="text-white">
                Level deleted successfully
              </ToastBody>
            </Toast>
            <Toast
              show={this.state.addLevelError !== null}
              bg="danger"
              onClose={this.dismissAddError}
            >
              <ToastHeader closeButton={true}>
                <strong className="me-auto">Adding error</strong>
              </ToastHeader>
              <ToastBody>{this.state.addLevelError}</ToastBody>
            </Toast>
            <Toast
              show={this.state.getLevelError !== null}
              bg="danger"
              onClose={this.dismissGetError}
            >
              <ToastHeader closeButton={true}>
                <strong className="me-auto">Fetching error</strong>
              </ToastHeader>
              <ToastBody>{this.state.getLevelError}</ToastBody>
            </Toast>
            <Toast
              show={this.state.editLevelError !== null}
              bg="danger"
              onClose={this.dismissEditError}
            >
              <ToastHeader closeButton={true}>
                <strong className="me-auto">Editing error</strong>
              </ToastHeader>
              <ToastBody>{this.state.editLevelError}</ToastBody>
            </Toast>
            <Toast
              show={this.state.deleteLevelError !== null}
              bg="danger"
              onClose={this.dismissDeleteError}
            >
              <ToastHeader closeButton={true}>
                <strong className="me-auto">Deleting error</strong>
              </ToastHeader>
              <ToastBody>{this.state.deleteLevelError}</ToastBody>
            </Toast>
          </ToastContainer>
          <Breadcrumb>
            <BreadcrumbItem href="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem active>{this.state.program?.name}</BreadcrumbItem>
          </Breadcrumb>
          <h1>Program: {this.state.program?.name}</h1>
          <Button
            variant="primary"
            className="text-white my-2"
            type="button"
            onClick={this.handleAddLevel}
          >
            Add level
          </Button>
          <br />
          <InfoSpan>Drag row to change level sequence</InfoSpan>
          <Table hover={true} striped={true} responsive="sm" bordered={true}>
            <thead>
              <tr>
                <th>Banner</th>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th>Completion image</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.levels.map((l, index) => (
                <tr
                  key={l["id"]}
                  draggable={true}
                  data-rowindex={index}
                  data-levelid={l["id"]}
                  onDragStartCapture={this.dragStartHandler}
                  onDragOverCapture={this.dragOverHandler}
                  onDropCapture={this.dropHandler}
                >
                  <td>
                    <UtImg
                      src={l.banner?.filePath}
                      alt={l.banner?.file}
                      width="50px"
                      height="auto"
                    />
                  </td>
                  <td>{l["title"]}</td>
                  <td>{l["description"]}</td>
                  <td>
                    <Badge
                      pill={true}
                      bg={l["type"] === "level" ? "dark" : "secondary"}
                    >
                      {l["type"]}
                    </Badge>
                  </td>
                  <td>
                    <UtImg
                      src={l.completion_image?.filePath}
                      alt={l.completion_image?.file}
                      width="50px"
                      height="auto"
                    />
                  </td>
                  <td>
                    <div className="d-flex flex-column align-items-start flex-md-row justify-content-md-start">
                      <Button
                        variant="secondary"
                        type="button"
                        className="mb-2 mb-md-0 me-md-2"
                        onClick={() => this.handleEditLevelOpen(l["id"])}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="mb-2 mb-md-0 me-md-auto"
                        onClick={() => this.seeLevel(l["id"])}
                      >
                        W.L.D.P.
                      </Button>
                      <Button
                        variant="danger"
                        type="button"
                        onClick={() => this.handleDeleteLevelOpen(l["id"])}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={this.state.addLevel} onHide={this.handleAddLevelClose}>
            <ModalHeader closeButton={true}>
              <ModalTitle>Add a new level</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Formik
                initialValues={{
                  title: "",
                  description: "",
                  type: "level",
                  program_id: parseInt(this.props.params.programId),
                  banner: undefined,
                  completion_image: undefined,
                }}
                validationSchema={LevelSchema}
                onSubmit={(values, { setStatus }) => {
                  if (!values.banner) {
                    setStatus({ banner: "Banner is required." });
                    return null;
                  } else if (!values.completion_image) {
                    setStatus({
                      completion_image: "Completion image is required",
                    });
                    return null;
                  } else if (!values.banner["type"].match(/image/)) {
                    setStatus({
                      banner: "Banner image should be an image",
                    });
                    return null;
                  } else if (!values.completion_image["type"].match(/image/)) {
                    setStatus({
                      completion_image: "Completion image should be an image",
                    });
                    return null;
                  } else {
                    this.setState({ addLevelLoading: true });
                    let values_form_data = new FormData();
                    Object.keys(values).forEach((key) =>
                      values_form_data.append(key, values[key])
                    );
                    admin_api
                      .post("levels", values_form_data)
                      .then((res) => {
                        let level_sequence =
                          this.state.program.level_sequence || [];
                        let levels = this.state.levels;
                        levels.push(res.data);
                        level_sequence.push(res.data.id);
                        this.setState({
                          addLevel: false,
                          addLevelLoading: false,
                          addLevelSuccess: true,
                          levels,
                          program: {
                            ...this.state.program,
                            level_sequence,
                          },
                        });
                      })
                      .catch((err) =>
                        this.setState({
                          addLevel: false,
                          addLevelLoading: false,
                          addLevelError:
                            err?.response?.data?.message || "Error",
                        })
                      );
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setTouched,
                  setStatus,
                  values,
                  touched,
                  errors,
                  status,
                }) => (
                  <Form noValidate={true} onSubmit={handleSubmit}>
                    <FloatingLabel
                      controlId="add-level.title"
                      label="Title"
                      className="mb-2"
                    >
                      <FormControl
                        name="title"
                        placeholder="Title"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        required={true}
                        isValid={touched.title && !errors.title}
                        isInvalid={touched.title && !!errors.title}
                      />
                      {touched.title && (
                        <FormControl.Feedback type="invalid">
                          {errors.title}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                    <FloatingLabel
                      className="mb-2"
                      controlId="add-level.description"
                      label="Level description"
                    >
                      <FormControl
                        as="textarea"
                        name="description"
                        placeholder="Level description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.description && !errors.description}
                        isInvalid={touched.description && !!errors.description}
                        style={{ minHeight: "100px" }}
                      />
                      {touched.description && (
                        <FormControl.Feedback type="invalid">
                          {errors.description}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                    <InputGroup className="mb-2">
                      <InputGroup.Text>Level type</InputGroup.Text>
                      <FormSelect
                        name="type"
                        id="add-level.type"
                        value={values.type}
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.type && !errors.type}
                        isInvalid={touched.type && !!errors.type}
                      >
                        <option disabled={true}>select a level type</option>
                        <option value="level">Level</option>
                        <option value="checkpoint">Checkpoint</option>
                      </FormSelect>
                      {touched.type && (
                        <FormControl.Feedback type="invalid">
                          {errors.type}
                        </FormControl.Feedback>
                      )}
                    </InputGroup>
                    <FormGroup className="mb-2">
                      <FormLabel htmlFor="new-program.banner">Banner</FormLabel>
                      <FormControl
                        type="file"
                        accept="image/*"
                        name="banner"
                        id="new-program.banner"
                        // value={values.banner}
                        onChange={(event) => {
                          setFieldValue("banner", event.currentTarget.files[0]);
                          if (
                            event.currentTarget.files[0]["type"].match(/image/)
                          ) {
                            setStatus({ banner: null });
                          } else {
                            setStatus({
                              banner: "Banner image should be an image",
                            });
                          }
                        }}
                        onBlur={() => {
                          setTouched({ ...touched, banner: true });
                          if (!values.banner) {
                            setStatus({
                              banner: "Banner is required",
                            });
                          }
                        }}
                        required={true}
                        isValid={touched.banner && !status?.banner}
                        isInvalid={touched.banner && !!status?.banner}
                      />
                      {touched.banner && (
                        <FormControl.Feedback type="invalid">
                          {status?.banner}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <FormLabel htmlFor="new-program.completion_image">
                        Completion image
                      </FormLabel>
                      <FormControl
                        type="file"
                        accept="image/*"
                        name="completion_image"
                        id="new-program.completion_image"
                        // value={values.banner}
                        onChange={(event) => {
                          setFieldValue(
                            "completion_image",
                            event.currentTarget.files[0]
                          );
                          if (
                            event.currentTarget.files[0]["type"].match(/image/)
                          ) {
                            setStatus({ completion_image: null });
                          } else {
                            setStatus({
                              completion_image:
                                "Completion image should be an image",
                            });
                          }
                        }}
                        onBlur={() => {
                          setTouched({ ...touched, completion_image: true });
                          if (!values.completion_image) {
                            setStatus({
                              completion_image: "Completion image is required",
                            });
                          }
                        }}
                        required={true}
                        isValid={
                          touched.completion_image && !status?.completion_image
                        }
                        isInvalid={
                          touched.completion_image && !!status?.completion_image
                        }
                      />
                      {touched.completion_image && (
                        <FormControl.Feedback type="invalid">
                          {status?.completion_image}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <ModalFooter>
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={this.handleAddLevelClose}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        className="text-white"
                        disabled={this.state.addLevelLoading}
                        type="submit"
                      >
                        {this.state.addLevelLoading
                          ? [
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />,
                              " Adding...",
                            ]
                          : "Add level"}
                      </Button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </Modal>
          <Modal show={this.state.editLevel} onHide={this.handleEditLevelClose}>
            <ModalHeader closeButton={true}>
              <ModalTitle>Edit level</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Formik
                initialValues={this.state.levels.find(
                  (l) => l["id"] === this.state.editLevelId
                )}
                validationSchema={LevelSchema}
                onSubmit={(values, { setStatus }) => {
                  if (!values.banner) {
                    setStatus({ banner: "Banner is required." });
                    return null;
                  } else if (!values.completion_image) {
                    setStatus({
                      completion_image: "Completion image is required.",
                    });
                    return null;
                  } else if (
                    !("id" in values.banner) &&
                    !values.banner["type"].match(/image/)
                  ) {
                    setStatus({
                      banner: "Banner image should be an image",
                    });
                    return null;
                  } else if (
                    !("id" in values.completion_image) &&
                    !values.completion_image["type"].match(/image/)
                  ) {
                    setStatus({
                      completion_image: "Completion image should be an image",
                    });
                    return null;
                  } else {
                    this.setState({ editLevelLoading: true });
                    let values_form_data = new FormData();
                    Object.keys(values).forEach((key) => {
                      if (
                        [
                          "title",
                          "description",
                          "type",
                          "banner_id",
                          "banner",
                          "completion_image",
                          "completion_image_id",
                          "program_id",
                        ].includes(key)
                      )
                        values_form_data.append(key, values[key]);
                    });
                    admin_api
                      .patch(
                        `levels/${this.state.editLevelId}`,
                        values_form_data
                      )
                      .then((res) => {
                        let levels = this.state.levels.map((l) => {
                          if (l["id"] === this.state.editLevelId) {
                            return { ...l, ...res.data };
                          } else return l;
                        });
                        this.setState({
                          editLevel: false,
                          editLevelLoading: false,
                          editLevelId: null,
                          editLevelSuccess: true,
                          levels,
                        });
                      })
                      .catch((err) =>
                        this.setState({
                          editLevel: false,
                          editLevelId: null,
                          editLevelLoading: false,
                          editLevelError:
                            err?.response?.data?.message || "Error",
                        })
                      );
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setStatus,
                  setTouched,
                  touched,
                  errors,
                  values,
                  status,
                }) => (
                  <Form noValidate={true} onSubmit={handleSubmit}>
                    <FloatingLabel
                      controlId="edit-level.title"
                      label="Title"
                      className="mb-2"
                    >
                      <FormControl
                        name="title"
                        placeholder="Title"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        required={true}
                        isValid={touched.title && !errors.title}
                        isInvalid={touched.title && !!errors.title}
                      />
                      {touched.title && (
                        <FormControl.Feedback type="invalid">
                          {errors.title}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                    <FloatingLabel
                      className="mb-2"
                      controlId="edit-level.description"
                      label="Level description"
                    >
                      <FormControl
                        as="textarea"
                        name="description"
                        placeholder="Level description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.description && !errors.description}
                        isInvalid={touched.description && !!errors.description}
                        style={{ minHeight: "100px" }}
                      />
                      {touched.description && (
                        <FormControl.Feedback type="invalid">
                          {errors.description}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                    <InputGroup className="mb-2">
                      <InputGroup.Text>Level type</InputGroup.Text>
                      <FormSelect
                        name="type"
                        id="edit-level.type"
                        value={values.type}
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.type && !errors.type}
                        isInvalid={touched.type && !!errors.type}
                      >
                        <option disabled={true}>select a level type</option>
                        <option value="level">Level</option>
                        <option value="checkpoint">Checkpoint</option>
                      </FormSelect>
                      {touched.type && (
                        <FormControl.Feedback type="invalid">
                          {errors.type}
                        </FormControl.Feedback>
                      )}
                    </InputGroup>
                    <FormGroup className="mb-2">
                      <FormLabel htmlFor="edit-program.banner">
                        Banner
                        <br />
                        {values?.banner?.file}
                      </FormLabel>
                      {values?.banner?.file && (
                        <InfoSpan
                          onClick={() => {
                            setFieldValue("banner", "");
                          }}
                        >
                          &nbsp;Clear
                        </InfoSpan>
                      )}
                      <FormControl
                        type="file"
                        accept="image/*"
                        name="banner"
                        id="edit-program.banner"
                        // value={values.banner}
                        onChange={(event) => {
                          setFieldValue("banner", event.currentTarget.files[0]);
                          if (
                            event.currentTarget.files[0]["type"].match(/image/)
                          ) {
                            setStatus({ banner: null });
                          } else {
                            setStatus({
                              banner: "Banner image should be an image",
                            });
                          }
                        }}
                        onBlur={() => {
                          setTouched({ ...touched, banner_id: true });
                          if (!values.banner) {
                            setStatus({
                              banner: "Banner is required",
                            });
                          }
                        }}
                        required={true}
                        isValid={touched.banner_id && !status?.banner}
                        isInvalid={touched.banner_id && !!status?.banner}
                      />
                      {touched.banner_id && (
                        <FormControl.Feedback
                          type="invalid"
                          controlId="edit-program.banner"
                        >
                          {status?.banner}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <FormLabel htmlFor="edit-program.completion_image">
                        Completion image
                        <br />
                        {values?.completion_image?.file}
                      </FormLabel>
                      {values?.completion_image?.file && (
                        <InfoSpan
                          onClick={() => {
                            setFieldValue("completion_image", "");
                          }}
                        >
                          &nbsp;Clear
                        </InfoSpan>
                      )}
                      <FormControl
                        type="file"
                        accept="image/*"
                        name="completion_image"
                        id="edit-program.completion_image"
                        // value={values.banner}
                        onChange={(event) => {
                          setFieldValue(
                            "completion_image",
                            event.currentTarget.files[0]
                          );
                          if (
                            event.currentTarget.files[0]["type"].match(/image/)
                          ) {
                            setStatus({ completion_image: null });
                          } else {
                            setStatus({
                              completion_image:
                                "Completion image should be an image",
                            });
                          }
                        }}
                        onBlur={() => {
                          setTouched({ ...touched, completion_image_id: true });
                          if (!values.completion_image) {
                            setStatus({
                              completion_image: "Completion image is required",
                            });
                          }
                        }}
                        required={true}
                        isValid={
                          touched.completion_image_id &&
                          !status?.completion_image
                        }
                        isInvalid={
                          touched.completion_image_id &&
                          !!status?.completion_image
                        }
                      />
                      {touched.completion_image_id && (
                        <FormControl.Feedback
                          type="invalid"
                          controlId="edit-program.completion_image"
                        >
                          {status?.completion_image}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <ModalFooter>
                      <Button
                        variant="secondary"
                        onClick={this.handleEditLevelClose}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        className="text-white"
                        disabled={this.state.editLevelLoading}
                        type="submit"
                      >
                        {this.state.editLevelLoading
                          ? [
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />,
                              " Saving...",
                            ]
                          : "Save changes"}
                      </Button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </Modal>
          <Modal
            show={this.state.deleteLevel}
            onHide={this.handleDeleteLevelClose}
          >
            <ModalHeader closeButton={true}>
              <ModalTitle>Delete level</ModalTitle>
            </ModalHeader>
            <ModalBody>Are you sure you want to delete this level?</ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                type="button"
                onClick={this.handleDeleteLevelClose}
              >
                Close
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={this.handleDeleteLevel}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      );
    }
  }
}

export default withRouter(Program);
