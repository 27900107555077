import React, { Component } from "react";
import { admin_api } from "../../atoms/api";
import BtContainer from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import {
  DiscussFooter,
  DiscussHeading,
  DiscussSchema,
} from "../../atoms/schemas";
import { InfoSpan } from "../../pages/program";
import styled from "styled-components";
import { UtImg } from "../../atoms/img";

const Container = styled(BtContainer)`
  & > .toast-container {
    z-index: 1;
  }
`;

const AccHeader = styled(AccordionHeader)`
  & > .accordion-button::after {
    margin-left: 10px !important;
  }
`;

export class Discuss extends Component {
  constructor(prop) {
    super();
    this.state = {
      add: false,
      addLoading: false,
      addSuccess: false,
      addError: null,
      getLoading: true,
      getError: null,
      level: {},
      data: [],
      edit: false,
      editId: null,
      editLoading: false,
      editSuccess: false,
      editError: null,
      delete: false,
      deleteId: null,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: null,
    };
  }

  componentDidMount() {
    admin_api
      .get(`/levels/${this.props.levelId}/discuss`)
      .then((res) => {
        let { discuss, ...level } = res.data;
        discuss = discuss.sort(
          (a, b) =>
            level.discuss_sequence.indexOf(a["id"]) -
            level.discuss_sequence.indexOf(b["id"])
        );
        this.setState({ getLoading: false, level, data: discuss });
      })
      .catch((err) =>
        this.setState({
          getLoading: false,
          getError: err?.response?.data?.message || "Error",
        })
      );
  }

  closeAddSuccess = () => this.setState({ addSuccess: false });
  closeEditSuccess = () => this.setState({ editSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteSuccess: false });

  dismissAddError = () => this.setState({ addError: null });
  dismissGetError = () => this.setState({ getError: null });
  dismissEditError = () => this.setState({ editError: null });
  dismissDeleteError = () => this.setState({ deleteError: null });

  handleAddOpen = () => this.setState({ add: true });
  handleAddClose = () => this.setState({ add: false });
  handleEditOpen = (id) => this.setState({ edit: true, editId: id });
  handleEditClose = () => this.setState({ edit: false, editId: null });
  handleDeleteOpen = (id) => this.setState({ delete: true, deleteId: id });
  handleDeleteClose = () => this.setState({ delete: false, deleteId: null });

  handleDelete = () => {
    this.setState({ delete: false, deleteLoading: true });
    admin_api
      .delete(`/discuss/${this.state.deleteId}`)
      .then((res) => {
        let data = this.state.data.filter(
          (d) => d["id"] !== this.state.deleteId
        );
        let discuss_sequence = this.state.level?.discuss_sequence || [];
        let index = discuss_sequence.indexOf(this.state.deleteId);
        discuss_sequence = discuss_sequence.splice(index, 1);
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteSuccess: true,
          data,
          level: {
            ...this.state.level,
            discuss_sequence,
          },
        });
      })
      .catch((err) =>
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteError: err?.response?.data?.message || "Error",
        })
      );
  };

  dragStartHandler = (e) => {
    e.dataTransfer.setData(
      "discussId",
      e.target.getAttribute("data-discussid")
    );
    e.dataTransfer.setData("pickIndex", e.target.getAttribute("data-rowindex"));
    e.dataTransfer.dropEffect = "move";
  };

  dragOverHandler = (e) => {
    e.preventDefault();
  };

  dropHandler = (e) => {
    e.preventDefault();
    this.setState({ editLoading: true });
    const dropRow = e.currentTarget.getAttribute("data-rowindex");
    const discussId = e.dataTransfer.getData("discussId");
    admin_api
      .patch(
        `/levels/${this.props.levelId}/discuss-sequence/discuss/${discussId}/index/${dropRow}`
      )
      .then((res) => {
        let newDiscussSequence = res.data;
        this.setState({
          editLoading: false,
          editSuccess: true,
          level: {
            ...this.state.level,
            discuss_sequence: newDiscussSequence,
          },
          data: this.state.data.sort(
            (a, b) =>
              newDiscussSequence.indexOf(a["id"]) -
              newDiscussSequence.indexOf(b["id"])
          ),
        });
      })
      .catch((err) =>
        this.setState({
          editLoading: false,
          editError: err?.response?.data?.message || "Error",
        })
      );
  };

  render() {
    return (
      <Container fluid={true} className="pt-3 position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
          <Toast show={this.state.getLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting discuss questions
            </ToastBody>
          </Toast>
          <Toast show={this.state.deleteLoading} bg="warning">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting discuss question
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.addSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeAddSuccess}
          >
            <ToastBody className="text-white">
              Discuss question added successfully
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.editSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeEditSuccess}
          >
            <ToastBody className="text-white">
              Changes saved successfully
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteSuccess}
            bg="success"
            onClose={this.closeDeleteSuccess}
            delay={2000}
            autohide={true}
          >
            <ToastBody className="text-white">Deleted successfully</ToastBody>
          </Toast>
          <Toast
            show={this.state.addError !== null}
            bg="danger"
            onClose={this.dismissAddError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Add error</strong>
            </ToastHeader>
            <ToastBody>{this.state.addError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.getError !== null}
            bg="danger"
            onClose={this.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.editError !== null}
            bg="danger"
            onClose={this.dismissEditError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Edit error</strong>
            </ToastHeader>
            <ToastBody>{this.state.editError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteError !== null}
            bg="danger"
            onClose={this.dismissDeleteError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Delete error</strong>
            </ToastHeader>
            <ToastBody>{this.state.deleteError}</ToastBody>
          </Toast>
        </ToastContainer>
        <Button
          type="button"
          variant="primary"
          className="text-white mb-3"
          onClick={this.handleAddOpen}
        >
          Add discuss question
        </Button>
        <br />
        <Formik
          initialValues={{
            discussHeading: this.state.level?.discussHeading || "",
          }}
          validationSchema={DiscussHeading}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.setState({ editLoading: true });
            admin_api
              .patch(`/levels/${this.state.level?.id}`, values)
              .then((res) =>
                this.setState({
                  editLoading: false,
                  editSuccess: true,
                  level: res.data,
                })
              )
              .catch((err) =>
                this.setState({
                  editLoading: false,
                  editError: err?.response?.data?.message || "Error",
                })
              );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            initialValues,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate={true} onSubmit={handleSubmit}>
              <FloatingLabel
                label="Discuss section heading"
                controlId="level-discussHeading"
                className="mb-2"
              >
                <FormControl
                  as="textarea"
                  style={{ minHeight: "100px" }}
                  name="discussHeading"
                  value={values.discussHeading}
                  placeholder="Discuss section heading"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  isValid={touched.discussHeading && !errors.discussHeading}
                  isInvalid={touched.discussHeading && !!errors.discussHeading}
                />
                {touched.discussHeading && (
                  <FormControl.Feedback type="invalid">
                    {errors.discussHeading}
                  </FormControl.Feedback>
                )}
              </FloatingLabel>
              {initialValues.discussHeading !== values.discussHeading && (
                <>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="me-2"
                    disabled={this.state.editLoading}
                  >
                    {this.state.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save"}
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        "discussHeading",
                        initialValues.discussHeading || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
        <InfoSpan>
          Drag <strong>question text</strong> to change discuss question
          sequence
        </InfoSpan>
        <Accordion defaultActiveKey="0">
          {this.state.data.map((d, index) => (
            <AccordionItem key={index} eventKey={index}>
              <AccHeader
                draggable={true}
                data-rowindex={index}
                data-discussid={d["id"]}
                onDragStartCapture={this.dragStartHandler}
                onDragOverCapture={this.dragOverHandler}
                onDropCapture={this.dropHandler}
              >
                {d.question}
                <Button
                  type="button"
                  variant="secondary"
                  className="ms-auto"
                  onClick={() => this.handleEditOpen(d["id"])}
                >
                  Edit
                </Button>
                <Button
                  type="button"
                  variant="danger"
                  className="ms-2"
                  onClick={() => this.handleDeleteOpen(d["id"])}
                >
                  Delete
                </Button>
              </AccHeader>
              <AccordionBody>
                <div className="d-flex flex-column align-items-start flex-md-row g-3">
                  <UtImg
                    src={d.image?.filePath}
                    alt={d.image?.file}
                    width="100px"
                    height="auto"
                    className="mb-3 mb-md-0 me-md-3"
                  />
                  {d.response}
                </div>
              </AccordionBody>
            </AccordionItem>
          ))}
        </Accordion>
        <Formik
          initialValues={{
            discussFooter: this.state.level?.discussFooter || "",
          }}
          validationSchema={DiscussFooter}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.setState({ editLoading: true });
            admin_api
              .patch(`/levels/${this.state.level?.id}`, values)
              .then((res) =>
                this.setState({
                  editLoading: false,
                  editSuccess: true,
                  level: res.data,
                })
              )
              .catch((err) =>
                this.setState({
                  editLoading: false,
                  editError: err?.response?.data?.message || "Error",
                })
              );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            initialValues,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate={true} onSubmit={handleSubmit}>
              <FloatingLabel
                label="Discuss section footer"
                controlId="level-discussFooter"
                className="mb-2"
              >
                <FormControl
                  as="textarea"
                  style={{ minHeight: "100px" }}
                  name="discussFooter"
                  value={values.discussFooter}
                  placeholder="Discuss section footer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  isValid={touched.discussFooter && !errors.discussFooter}
                  isInvalid={touched.discussFooter && !!errors.discussFooter}
                />
                {touched.discussFooter && (
                  <FormControl.Feedback type="invalid">
                    {errors.discussFooter}
                  </FormControl.Feedback>
                )}
              </FloatingLabel>
              {initialValues.discussFooter !== values.discussFooter && (
                <>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="me-2"
                    disabled={this.state.editLoading}
                  >
                    {this.state.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save"}
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        "discussFooter",
                        initialValues.discussFooter || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
        <Modal show={this.state.add} onHide={this.handleAddClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Add a new discuss question</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                level_id: this.props.levelId,
                question: "",
                response: "",
                image: undefined,
              }}
              validationSchema={DiscussSchema}
              onSubmit={(values, { setStatus }) => {
                if (!values.image) {
                  setStatus({ image: "Image is required" });
                } else if (!values.image?.type.match(/image/)) {
                  // do nothing
                } else {
                  this.setState({ addLoading: true });
                  let values_form_data = new FormData();
                  Object.keys(values).forEach((key) =>
                    values_form_data.append(key, values[key])
                  );
                  admin_api
                    .post("/discuss", values_form_data)
                    .then((res) => {
                      let data = this.state.data;
                      data.push(res.data);
                      let discuss_sequence = this.state.level?.discuss_sequence;
                      discuss_sequence.push(res.data.id);
                      this.setState({
                        add: false,
                        addLoading: false,
                        addSuccess: true,
                        data,
                        level: {
                          ...this.state.level,
                          discuss_sequence,
                        },
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        add: false,
                        addLoading: false,
                        addError: err?.response?.data?.message || "Error",
                      })
                    );
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setTouched,
                setStatus,
                touched,
                errors,
                values,
                status,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="Question text"
                    controlId="add-discuss.question"
                    className="mb-2"
                  >
                    <FormControl
                      type="text"
                      name="question"
                      maxLength="255"
                      placeholder="Question text"
                      value={values.question}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.question && !errors.question}
                      isInvalid={touched.question && !!errors.question}
                    />
                    {touched.question && (
                      <FormControl.Feedback type="invalid">
                        {errors.question}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FloatingLabel
                    label="Response"
                    controlId="add-discuss.response"
                    className="mb-2"
                  >
                    <FormControl
                      style={{ minHeight: "100px" }}
                      as="textarea"
                      name="response"
                      placeholder="Response"
                      value={values.response}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.response && !errors.response}
                      isInvalid={touched.response && !!errors.response}
                    />
                    {touched.response && (
                      <FormControl.Feedback type="invalid">
                        {errors.response}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormGroup controlId="add-discuss.image" className="mb-2">
                    <FormLabel>Image</FormLabel>
                    <FormControl
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("image", file);
                        if (file["type"].match(/image/)) {
                          setStatus({ image: null });
                        } else {
                          setStatus({ image: "File should be an image" });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, image: true });
                      }}
                      required={true}
                      isValid={touched.image && !status?.image}
                      isInvalid={touched.image && !!status?.image}
                    />
                    {touched.image && (
                      <FormControl.Feedback type="invalid">
                        {status?.image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleAddClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="text-white"
                      disabled={this.state.addLoading}
                    >
                      {this.state.addLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Adding...",
                          ]
                        : "Add"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.edit} onHide={this.handleEditClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Edit discuss question</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.data.find(
                (d) => d["id"] === this.state.editId
              )}
              validationSchema={DiscussSchema}
              onSubmit={(values, { setStatus }) => {
                if (!values.image) {
                  setStatus({ image: "Image is required" });
                } else if (
                  ("type" in values.image &&
                    !values.image?.type.match(/image/)) ||
                  ("fileType" in values &&
                    !values.image?.fileType.match(/image/))
                ) {
                  // do nothing
                } else {
                  this.setState({ editLoading: true });
                  let values_form_data = new FormData();
                  Object.keys(values).forEach((key) =>
                    values_form_data.append(key, values[key])
                  );
                  admin_api
                    .patch(`/discuss/${this.state.editId}`, values_form_data)
                    .then((res) => {
                      let data = this.state.data.map((d) => {
                        if (d.id === this.state.editId) {
                          return res.data;
                        }
                        return d;
                      });
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editSuccess: true,
                        data,
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editError: err?.response?.data?.message || "Error",
                      })
                    );
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setStatus,
                setTouched,
                touched,
                values,
                errors,
                status,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="Question text"
                    controlId="edit-discuss.question"
                    className="mb-2"
                  >
                    <FormControl
                      name="question"
                      type="text"
                      placeholder="Question text"
                      maxLength="255"
                      value={values.question}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.question && !errors.question}
                      isInvalid={touched.question && !!errors.question}
                    />
                    {touched.question && (
                      <FormControl.Feedback type="invalid">
                        {errors.question}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FloatingLabel
                    label="Response"
                    controlId="edit-discuss.response"
                    className="mb-2"
                  >
                    <FormControl
                      style={{ minHeight: "100px" }}
                      name="response"
                      placeholder="Response"
                      as="textarea"
                      value={values.response}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.response && !errors.response}
                      isInvalid={touched.response && !!errors.response}
                    />
                    {touched.response && (
                      <FormControl.Feedback type="invalid">
                        {errors.response}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormGroup controlId="edit-discuss.image" className="mb-2">
                    <FormLabel>
                      Image
                      <br />
                      {values?.image.file}
                    </FormLabel>
                    {values?.image.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("image", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      type="file"
                      name="image"
                      required={true}
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("image", file);
                        if (
                          ("type" in file && file.type.match(/image/)) ||
                          ("fileType" in file && file.fileType.match(/image/))
                        ) {
                          setStatus({ image: null });
                        } else {
                          setStatus({ image: "File should be an image" });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, image: true });
                      }}
                      isValid={touched.image && !status?.image}
                      isInvalid={touched.image && !!status?.image}
                    />
                    {touched.image && (
                      <FormControl.Feedback type="invalid">
                        {status?.image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleEditClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={this.state.editLoading}
                      className="text-white"
                    >
                      {this.state.editLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Saving...",
                          ]
                        : "Save changes"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.delete} onHide={this.handleDeleteClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Delete this discuss question?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete this discuss question?
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              variant="secondary"
              onClick={this.handleDeleteClose}
            >
              Close
            </Button>
            <Button tyoe="button" variant="danger" onClick={this.handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
