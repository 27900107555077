import React, { Component } from "react";
import Cookie from "js-cookie";
import { admin_api } from "../../atoms/api";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { AdminUser } from "../adminUsers";
import { Users } from "../users";

export class AllUsers extends Component {
  constructor() {
    super();
    this.state = {
      getUserLoading: true,
      getUserError: null,
      users: [],
      selectedTab: "admin",
      logout: false,
      addUser: false,
      addUserLoading: false,
      addUserSuccess: false,
      addUserError: null,
      editUser: false,
      editUserId: null,
      editUserLoading: false,
      editUserSuccess: false,
      editUserError: null,
      deleteUser: false,
      deleteUserId: null,
      deleteUserLoading: false,
      deleteUserSuccess: false,
      deleteUserError: null,
    };
    this.user = JSON.parse(Cookie.get("ut_admin"));
  }

  setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  componentDidMount() {
    admin_api
      .get("/user")
      .then((res) => {
        this.setState({
          getUserLoading: false,
          users: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          getUserLoading: false,
          getUserError: err?.response?.data?.message || "Error",
        });
      });
  }

  closeAddSuccess = () => this.setState({ addUserSuccess: false });
  closeEditSuccess = () => this.setState({ editUserSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteUserSuccess: false });

  dismissAddError = () => this.setState({ addUserError: null });
  dismissGetError = () => this.setState({ getUserError: null });
  dismissEditError = () => this.setState({ editUserError: null });
  dismissDeleteError = () => this.setState({ deleteUserError: null });

  handleAddUser = () => this.setState({ addUser: true });
  handleAddUserClose = () => this.setState({ addUser: false });
  handleEditUser = (userId) =>
    this.setState({ editUser: true, editUserId: userId });
  handleEditUserClose = () =>
    this.setState({ editUser: false, editUserId: null });
  handleDeleteUserOpen = (userId) =>
    this.setState({ deleteUser: true, deleteUserId: userId });
  handleDeleteUserClose = () =>
    this.setState({ deleteUser: false, deleteUserId: null });

  handleDeleteUser = () => {
    this.setState({ deleteUser: false, deleteUserLoading: true });
    admin_api
      .delete(`/user/${this.state.deleteUserId}`)
      .then(() => {
        let users = this.state.users.filter(
          (u) => u["id"] !== this.state.deleteUserId
        );
        let logout = this.user?.id === this.state.deleteUserId;
        this.setState({
          deleteUserId: null,
          deleteUserLoading: false,
          deleteUserSuccess: true,
          logout,
          users,
        });
      })
      .catch((err) => {
        this.setState({
          deleteUserId: null,
          deleteUserLoading: false,
          deleteUserError: err?.response?.data?.message || "Error",
        });
      });
  };

  addUserSubmit = (values) => {
    this.setState({ addUserLoading: true });
    if (!values.date_of_birth) delete values.date_of_birth;
    admin_api
      .post("/user/admin", { ...values })
      .then((res) => {
        let users = this.state.users;
        users.push(res.data);
        this.setState({
          addUser: false,
          addUserLoading: false,
          addUserSuccess: true,
          users,
        });
      })
      .catch((err) =>
        this.setState({
          addUser: false,
          addUserLoading: false,
          addUserError: err?.response?.data?.message || "Error",
        })
      );
  };

  editUserSubmit = (values) => {
    this.setState({ editUserLoading: true });
    admin_api
      .patch(`/user/${this.state.editUserId}`, values)
      .then((res) => {
        let users = this.state.users;
        users = users.map((u) => {
          if (u["id"] === this.state.editUserId) {
            return res.data;
          }
          return u;
        });
        this.setState({
          editUser: false,
          editUserId: null,
          editUserLoading: false,
          editUserSuccess: true,
          users,
        });
      })
      .catch((err) =>
        this.setState({
          editUser: false,
          editUserId: null,
          editUserLoading: false,
          editUserError: err?.response?.data?.message || "Error",
        })
      );
  };

  render() {
    if (this.state.logout) {
      return <Navigate to="/logout" />;
    } else {
      return (
        <Container fluid={true} className="position-relative">
          <Tabs
            activeKey={this.state.selectedTab}
            onSelect={(k) => this.setSelectedTab(k)}
            className="mb-3"
          >
            <Tab eventKey="admin" title="Admins">
              <AdminUser
                users={this.state.users.filter(
                  (u) => u.role === "admin" || u.role === "editor"
                )}
                logout={this.state.logout}
                addUser={this.state.addUser}
                addUserLoading={this.state.addUserLoading}
                addUserSuccess={this.state.addUserSuccess}
                addUserError={this.state.addUserError}
                getUserLoading={this.state.getUserLoading}
                getUserError={this.state.getUserError}
                editUser={this.state.editUser}
                editUserId={this.state.editUserId}
                editUserLoading={this.state.editUserLoading}
                editUserSuccess={this.state.editUserSuccess}
                editUserError={this.state.editUserError}
                deleteUser={this.state.deleteUser}
                deleteUserId={this.state.deleteUserId}
                deleteUserLoading={this.state.deleteUserLoading}
                deleteUserSuccess={this.state.deleteUserSuccess}
                deleteUserError={this.state.deleteUserError}
                closeAddSuccess={this.closeAddSuccess}
                closeDeleteSuccess={this.closeDeleteSuccess}
                closeEditSuccess={this.closeEditSuccess}
                dismissAddError={this.dismissAddError}
                dismissGetError={this.dismissGetError}
                dismissEditError={this.dismissEditError}
                dismissDeleteError={this.dismissDeleteError}
                handleAddUser={this.handleAddUser}
                handleAddUserClose={this.handleAddUserClose}
                handleEditUser={this.handleEditUser}
                handleEditUserClose={this.handleEditUserClose}
                handleDeleteUser={this.handleDeleteUser}
                handleDeleteUserClose={this.handleDeleteUserClose}
                handleDeleteUserOpen={this.handleDeleteUserOpen}
                addUserSubmit={this.addUserSubmit}
                editUserSubmit={this.editUserSubmit}
              />
            </Tab>
            <Tab eventKey="users" title="Parents">
              <Users
                loading={this.state.getUserLoading}
                error={this.state.getUserError}
                dismissGetError={this.dismissGetError}
                users={this.state.users.filter((u) => u.role === "parent")}
              />
            </Tab>
            <Tab eventKey="schools" title="Schools">
              <Users
                loading={this.state.getUserLoading}
                error={this.state.getUserError}
                dismissGetError={this.dismissGetError}
                users={this.state.users.filter((u) => u.role === "learner")}
              />
            </Tab>
          </Tabs>
        </Container>
      );
    }
  }
}
