import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { admin_api } from "../../atoms/api";
import Spinner from "react-bootstrap/Spinner";
import { Table as TableIcon } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";

export const Affiliate = () => {
  const [affiliate, setAffiliate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const [email, setEmail] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const [checkIds, setCheckIds] = useState([]);
  const [payCommLoading, setPayCommLoading] = useState(false);
  const [payCommSuccess, setPayCommSuccess] = useState(false);
  const [payCommError, setPayCommError] = useState(null);

  useEffect(() => {
    admin_api
      .get("/affiliate")
      .then((res) => {
        setLoading(false);
        setError(null);
        setAffiliate(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error getting data");
        setAffiliate([]);
      });
  }, []);

  const onCheckTransaction = (email) => {
    setEmail(email);
    let allTransactions = [];
    affiliate
      .filter((a) => a?.email === email && a?.transactions)
      .forEach((a) => allTransactions.push(...a?.transactions));
    setTransactions(allTransactions);
  };

  const onTransactionClose = () => {
    setEmail(null);
    setTransactions([]);
  };

  const onCheckId = (transactionId) => {
    let ids = Array.from(checkIds);
    if (ids.includes(transactionId)) {
      ids = ids.filter((id) => id !== transactionId);
    } else ids.push(transactionId);
    setCheckIds(ids);
  };

  const paidCommission = () => {
    setPayCommLoading(true);
    admin_api
      .patch("/transaction/commission/mark-as-paid", {
        transactionIds: checkIds,
      })
      .then((res) => {
        setPayCommLoading(false);
        setPayCommError(null);
        let affiliates = Array.from(affiliate);
        let thisTransactions = Array.from(transactions);
        affiliates = affiliates.map((a) => {
          return {
            ...a,
            transactions: a?.transactions.map((t) => {
              if (checkIds.includes(t.id)) {
                return { ...t, commission_paid: true };
              } else {
                return t;
              }
            }),
          };
        });
        thisTransactions = thisTransactions.map((t) => {
          if (checkIds.includes(t.id)) {
            return { ...t, commission_paid: true };
          } else {
            return t;
          }
        });
        setAffiliate(affiliates);
        setTransactions(thisTransactions);
        setCheckIds([]);
        setPayCommSuccess(true);
        setTimeout(() => {
          setPayCommSuccess(false);
        }, 5000);
      })
      .catch((err) => {
        setPayCommLoading(false);
        setPayCommError(
          err?.response?.data?.message || "Error marking as paid"
        );
      });
  };

  const calculateCommissionRate = (length, amount) => {
    if (length && amount)
      if (length > 0 && length <= 50)
        return ["12", ((12 * amount) / 100).toFixed(2)];
      else if (length >= 51 && length <= 150)
        return ["15", ((15 * amount) / 100).toFixed(2)];
      else if (length >= 151 && length <= 250)
        return ["20", ((20 * amount) / 100).toFixed(2)];
      else if (length >= 251 && length <= 350)
        return ["25", ((25 * amount) / 100).toFixed(2)];
      else if (length > 351) return ["30", ((30 * amount) / 100).toFixed(2)];
      else return ["0", "0"];
    else return ["0", "0"];
  };

  return (
    <div>
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : error !== null ? (
        <span className="text-danger">{error}</span>
      ) : (
        <Table hover={true} striped={true} responsive="sm" bordered={true}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Contact No</th>
              <th>State</th>
              <th>Total unpaid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {affiliate.map((d, index) => (
              <tr key={index}>
                <td>{d?.firstName}</td>
                <td>{d?.lastName}</td>
                <td>{d?.email}</td>
                <td>{d?.contact_no}</td>
                <td>{d?.state}</td>
                <td>
                  {
                    calculateCommissionRate(
                      d?.transactions.length,
                      d?.transactions
                        .filter((t) => !t.commission_paid)
                        .map((t) =>
                          parseFloat((0.9 * t.program_cost).toFixed(2))
                        )
                        .reduce((acc, next) => acc + next, 0)
                    )[1]
                  }
                </td>
                <td>
                  <TableIcon onClick={() => onCheckTransaction(d?.email)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal show={email !== null} size="lg" onHide={onTransactionClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Transactions</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <h6>Email: {email}</h6>
          <h6>
            Total amount:{" "}
            {transactions
              .map((t) => parseFloat((0.9 * t.program_cost).toFixed(2)))
              .reduce((acc, next) => acc + next, 0)}
          </h6>
          <h6>
            Commission rate:{" "}
            {
              calculateCommissionRate(
                transactions.length,
                transactions
                  .map((t) => parseFloat((0.9 * t.program_cost).toFixed(2)))
                  .reduce((acc, next) => acc + next, 0)
              )[0]
            }
          </h6>
          <h6>
            Commission amount:{" "}
            {
              calculateCommissionRate(
                transactions.length,
                transactions
                  .map((t) => parseFloat((0.9 * t.program_cost).toFixed(2)))
                  .reduce((acc, next) => acc + next, 0)
              )[1]
            }
          </h6>
          <h6>
            Total unpaid commission:{" "}
            {
              calculateCommissionRate(
                transactions.length,
                transactions
                  .filter((t) => !t.commission_paid)
                  .map((t) => parseFloat((0.9 * t.program_cost).toFixed(2)))
                  .reduce((acc, next) => acc + next, 0)
              )[1]
            }
          </h6>
          <Table hover={true} striped={true} responsive="sm" bordered={true}>
            <thead>
              <tr>
                <th>Program Name</th>
                <th>Code</th>
                <th>Amount</th>
                <th>Commission amount</th>
                <th>Commission paid</th>
                <></>
              </tr>
            </thead>
            <tbody>
              {transactions.length === 0 ? (
                <tr>
                  <td colSpan="6">
                    No purchase has been made using these codes.
                  </td>
                </tr>
              ) : (
                transactions.map((a, index) => (
                  <tr key={index}>
                    <td>
                      {!!a?.temp_enrollment_id
                        ? a?.temp_enrollment?.program?.name
                        : a?.temp_gift?.program?.name}
                    </td>
                    <td>{a?.affiliate_code}</td>
                    <td>{(0.9 * a?.program_cost).toFixed(2)}</td>
                    <td>
                      {(
                        (calculateCommissionRate(
                          transactions.length,
                          transactions
                            .map((t) =>
                              parseFloat((0.9 * t.program_cost).toFixed(2))
                            )
                            .reduce((acc, next) => acc + next, 0)
                        )[0] /
                          100) *
                        (0.9 * a?.program_cost)
                      ).toFixed(2)}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={checkIds.includes(a?.id) || a?.commission_paid}
                        disabled={a?.commission_paid}
                        onChange={() => {
                          onCheckId(a?.id);
                        }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          {checkIds.length > 0 && (
            <Button
              type="button"
              className="text-white"
              onClick={paidCommission}
            >
              Save
            </Button>
          )}
          <Button
            type="button"
            variant="secondary"
            onClick={onTransactionClose}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
