import React from "react";
import Navbar from "react-bootstrap/Navbar";
import NavBrand from "react-bootstrap/NavbarBrand";
import NavLink from "react-bootstrap/NavLink";
import { Outlet } from "react-router-dom";
import pt from "prop-types";
import Cookie from "js-cookie";

export const AdminContainer = (props) => {
  let cookie = null;
  if (props.loggedIn) {
    cookie = JSON.parse(Cookie.get("ut_admin"));
  }
  return (
    <div>
      <Navbar className="px-5" bg="light">
        <NavBrand className="me-auto">UnTaboo Admin</NavBrand>
        {cookie?.first_name && (
          <span className="me-2">
            Hi {cookie?.first_name} {cookie?.last_name},
          </span>
        )}
        {props.loggedIn ? <NavLink href="/logout">Logout</NavLink> : null}
      </Navbar>
      <Outlet />
    </div>
  );
};

AdminContainer.propTypes = {
  loggedIn: pt.bool.isRequired,
};
