import React from "react";
import { RegisterUserSchema, UserSchema } from "../../atoms/schemas";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import FormGroup from "react-bootstrap/FormGroup";
import FormSelect from "react-bootstrap/FormSelect";
import FormCheck from "react-bootstrap/FormCheck";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import Badge from "react-bootstrap/Badge";
import { Navigate } from "react-router-dom";

export const AdminUser = (props) => {
  if (props.logout) {
    return <Navigate to="/logout" />;
  } else {
    return (
      <Container fluid={true} className="position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-md-1">
          <Toast show={props.getUserLoading} bg="info">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting all users
            </ToastBody>
          </Toast>
          <Toast show={props.deleteUserLoading} bg="warning">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting user
            </ToastBody>
          </Toast>
          <Toast
            show={props.addUserSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={props.closeAddSuccess}
          >
            <ToastBody className="text-white">
              User added successfully
            </ToastBody>
          </Toast>
          <Toast
            show={props.editUserSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={props.closeEditSuccess}
          >
            <ToastBody className="text-white">
              User edited successfully
            </ToastBody>
          </Toast>
          <Toast
            show={props.deleteUserSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={props.closeDeleteSuccess}
          >
            <ToastBody className="text-white">
              User deleted successfully
            </ToastBody>
          </Toast>
          <Toast
            show={props.addUserError !== null}
            bg="danger"
            onClose={props.dismissAddError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Adding error</strong>
            </ToastHeader>
            <ToastBody>{props.addUserError}</ToastBody>
          </Toast>
          <Toast
            show={props.getUserError !== null}
            bg="danger"
            onClose={props.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch error</strong>
            </ToastHeader>
            <ToastBody>{props.getUserError}</ToastBody>
          </Toast>
          <Toast
            show={props.editUserError !== null}
            bg="danger"
            onClose={props.dismissEditError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Editing error</strong>
            </ToastHeader>
            <ToastBody>{props.editUserError}</ToastBody>
          </Toast>
          <Toast
            show={props.deleteUserError !== null}
            bg="danger"
            onClose={props.dismissDeleteError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Deleting error</strong>
            </ToastHeader>
            <ToastBody>{props.deleteUserError}</ToastBody>
          </Toast>
        </ToastContainer>
        <Button
          onClick={props.handleAddUser}
          variant="primary"
          type="button"
          className="mb-3 text-white"
        >
          Add user
        </Button>
        <Table hover={true} striped={true} bordered={true} responsive="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Gender</th>
              <th>Date of birth</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.users.map((u) => (
              <tr key={u["id"]}>
                <td>{`${u["first_name"]} ${u["last_name"]}`}</td>
                <td>{u["email_id"]}</td>
                <td>
                  <Badge
                    pill={true}
                    bg={
                      u["role"] === "admin"
                        ? "primary"
                        : u["role"] === "editor"
                        ? "success"
                        : "secondary"
                    }
                  >
                    {u["role"]}
                  </Badge>
                </td>
                <td>
                  <Badge pill={true} bg="secondary">
                    {u["gender"]}
                  </Badge>
                </td>
                <td>{u["date_of_birth"]}</td>
                <td>
                  <div className="d-flex flex-column align-items-start flex-md-row justify-content-md-start">
                    <Button
                      variant="secondary"
                      type="button"
                      className="mb-2 mb-md-0 me-md-auto"
                      onClick={() => props.handleEditUser(u["id"])}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      onClick={() => props.handleDeleteUserOpen(u["id"])}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={props.addUser} size="lg" onHide={props.handleAddUserClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Add a new user</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                date_of_birth: "",
                gender: "",
                location: "",
                email_id: "",
                password: "",
                cPassword: "",
                role: "editor",
              }}
              onSubmit={props.addUserSubmit}
              validationSchema={RegisterUserSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                errors,
                values,
                touched,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-user.first_name"
                        label="First name"
                      >
                        <FormControl
                          placeholder="First name"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          type="text"
                          isValid={touched.first_name && !errors.first_name}
                          isInvalid={touched.first_name && !!errors.first_name}
                        />
                        {touched.first_name && (
                          <FormControl.Feedback type="invalid">
                            {errors.first_name}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="add-user.last_name"
                        label="Last name"
                      >
                        <FormControl
                          placeholder="Doe"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          type="text"
                          isValid={touched.last_name && !errors.last_name}
                          isInvalid={touched.last_name && !!errors.last_name}
                        />
                        {touched.last_name && (
                          <FormControl.Feedback type="invalid">
                            {errors.last_name}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-user.date_of_birth"
                        label="Date of birth"
                      >
                        <FormControl
                          type="date"
                          placeholder="D.O.B."
                          name="date_of_birth"
                          value={values.date_of_birth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={
                            touched.date_of_birth && !errors.date_of_birth
                          }
                          isInvalid={
                            touched.date_of_birth && !!errors.date_of_birth
                          }
                        />
                        {touched.date_of_birth && (
                          <FormControl.Feedback type="invalid">
                            {errors.date_of_birth}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <InputGroup>
                        <InputGroup.Text>Role</InputGroup.Text>
                        <FormSelect
                          name="role"
                          id="add-user.role"
                          value={values.role}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.role && !errors.role}
                          isInvalid={touched.role && !!errors.role}
                        >
                          <option value="">select a user role</option>
                          <option value="editor">Editor</option>
                          <option value="admin">Admin</option>
                        </FormSelect>
                        {touched.role && (
                          <FormControl.Feedback type="invalid">
                            {errors.role}
                          </FormControl.Feedback>
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="g-2 mb-2">
                    <FloatingLabel controlId="add-user.email_id" label="Email">
                      <FormControl
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        name="email_id"
                        value={values.email_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.email_id && !errors.email_id}
                        isInvalid={touched.email_id && !!errors.email_id}
                      />
                      {touched.email_id && (
                        <FormControl.Feedback type="invalid">
                          {errors.email_id}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Row>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-user.password"
                        label="Password"
                      >
                        <FormControl
                          type="password"
                          placeholder="Set password"
                          autoComplete="new-password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.password && !errors.password}
                          isInvalid={touched.password && !!errors.password}
                        />
                        {touched.password && (
                          <FormControl.Feedback type="invalid">
                            {errors.password}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="add-user.confirm-password"
                        label="Confirm password"
                      >
                        <FormControl
                          type="password"
                          placeholder="Confirm password"
                          autoComplete="new-password"
                          name="cPassword"
                          value={values.cPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.cPassword && !errors.cPassword}
                          isInvalid={touched.cPassword && !!errors.cPassword}
                          required={true}
                        />
                        {touched.cPassword && (
                          <FormControl.Feedback type="invalid">
                            {errors.cPassword}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-2 g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-user.location"
                        label="Location"
                      >
                        <FormControl
                          type="text"
                          name="location"
                          placeholder="Location"
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.location && !errors.location}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FormCheck
                        type="radio"
                        label="Male"
                        value="M"
                        name="gender"
                        inline={true}
                        required={true}
                        checked={values.gender === "M"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.gender && !errors.gender}
                        isInvalid={touched.gender && !!errors.gender}
                      />
                      <FormCheck
                        type="radio"
                        label="Female"
                        value="F"
                        name="gender"
                        inline={true}
                        required={true}
                        checked={values.gender === "F"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.gender && !errors.gender}
                        isInvalid={touched.gender && !!errors.gender}
                      />
                      <FormCheck
                        type="radio"
                        label="Other"
                        value="O"
                        name="gender"
                        inline={true}
                        required={true}
                        checked={values.gender === "O"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.gender && !errors.gender}
                        isInvalid={touched.gender && !!errors.gender}
                      />
                      <FormControl.Feedback controlId="gender" type="invalid">
                        {errors.gender}
                      </FormControl.Feedback>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={props.handleAddUserClose}
                    >
                      Close
                    </Button>
                    <Button
                      className="text-white"
                      type="submit"
                      variant="primary"
                      disabled={props.addUserLoading}
                    >
                      {props.addUserLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Adding...",
                          ]
                        : "Add user"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          show={props.editUser}
          size="lg"
          onHide={props.handleEditUserClose}
        >
          <ModalHeader closeButton={true}>
            <ModalTitle>Edit user</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={props.users.find(
                (u) => u["id"] === props.editUserId
              )}
              onSubmit={props.editUserSubmit}
              validationSchema={UserSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="edit-user.first_name"
                        label="First name"
                      >
                        <FormControl
                          placeholder="First name"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          type="text"
                          isValid={touched.first_name && !errors.first_name}
                          isInvalid={touched.first_name && !!errors.first_name}
                        />
                        {touched.first_name && (
                          <FormControl.Feedback type="invalid">
                            {errors.first_name}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="edit-user.last_name"
                        label="Last name"
                      >
                        <FormControl
                          placeholder="Doe"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          type="text"
                          isValid={touched.last_name && !errors.last_name}
                          isInvalid={touched.last_name && !!errors.last_name}
                        />
                        {touched.last_name && (
                          <FormControl.Feedback type="invalid">
                            {errors.last_name}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="edit-user.date_of_birth"
                        label="Date of birth"
                      >
                        <FormControl
                          type="date"
                          placeholder="D.O.B."
                          name="date_of_birth"
                          value={values.date_of_birth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={
                            touched.date_of_birth && !errors.date_of_birth
                          }
                          isInvalid={
                            touched.date_of_birth && !!errors.date_of_birth
                          }
                        />
                        {touched.date_of_birth && (
                          <FormControl.Feedback type="invalid">
                            {errors.date_of_birth}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FormCheck
                        type="radio"
                        label="Male"
                        value="M"
                        name="gender"
                        inline={true}
                        required={true}
                        checked={values.gender === "M"}
                        onChange={handleChange}
                      />
                      <FormCheck
                        type="radio"
                        label="Female"
                        value="F"
                        name="gender"
                        inline={true}
                        required={true}
                        checked={values.gender === "F"}
                        onChange={handleChange}
                      />
                      <FormCheck
                        type="radio"
                        label="Other"
                        value="O"
                        name="gender"
                        inline={true}
                        required={true}
                        checked={values.gender === "O"}
                        onChange={handleChange}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors.gender}
                      </FormControl.Feedback>
                    </Col>
                  </Row>
                  <Row className="g-2 mb-2">
                    <Col md="6">
                      <FloatingLabel
                        controlId="edit-user.email_id"
                        label="Email"
                      >
                        <FormControl
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          name="email_id"
                          value={values.email_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.email_id && !errors.email_id}
                          isInvalid={touched.email_id && !!errors.email_id}
                        />
                        {touched.email_id && (
                          <FormControl.Feedback type="invalid">
                            {errors.email_id}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md="6">
                      <FloatingLabel
                        controlId="edit-user.location"
                        label="Location"
                      >
                        <FormControl
                          type="text"
                          name="location"
                          placeholder="Location"
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.location && !errors.location}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-2 g-2">
                    <FormGroup as={Col} md="6">
                      <InputGroup>
                        <InputGroup.Text>Role</InputGroup.Text>
                        <FormSelect
                          name="role"
                          id="edit-user.role"
                          value={values.role}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.role && !errors.role}
                          isInvalid={touched.role && !!errors.role}
                        >
                          <option value="">select a user role</option>
                          <option value="editor">Editor</option>
                          <option value="admin">Admin</option>
                        </FormSelect>
                        {touched.role && (
                          <FormControl.Feedback type="invalid">
                            {errors.role}
                          </FormControl.Feedback>
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={props.handleEditUserClose}
                    >
                      Close
                    </Button>
                    <Button
                      className="text-white"
                      type="submit"
                      variant="primary"
                      disabled={props.editUserLoading}
                    >
                      {props.editUserLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Saving...",
                          ]
                        : "Save changes"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={props.deleteUser} onHide={props.handleDeleteUserClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Delete user</ModalTitle>
          </ModalHeader>
          <ModalBody>Are you sure you want to delete this user?</ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={props.handleDeleteUserClose}
            >
              Close
            </Button>
            <Button
              variant="danger"
              type="button"
              onClick={props.handleDeleteUser}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
};
