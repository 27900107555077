import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FormLabel from "react-bootstrap/FormLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Formik } from "formik";
import { WatchSchema } from "../../atoms/schemas";
import { admin_api } from "../../atoms/api";
import { InfoSpan } from "../../pages/program";
import { UtVideo } from "../../atoms/video";

export class Watch extends Component {
  constructor(props) {
    super();
    this.state = {
      add: false,
      addLoading: false,
      addSuccess: false,
      addError: null,
      getLoading: true,
      getError: null,
      level: {},
      data: [],
      edit: false,
      editLoading: false,
      editId: null,
      editSuccess: false,
      editError: null,
      delete: false,
      deleteId: null,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: null,
    };
  }

  componentDidMount() {
    admin_api
      .get(`/levels/${this.props.levelId}/watch`)
      .then((res) => {
        let { watch, ...level } = res.data;
        watch = watch.sort(
          (a, b) =>
            level.watch_sequence.indexOf(a["id"]) -
            level.watch_sequence.indexOf(b["id"])
        );
        this.setState({ getLoading: false, level, data: watch });
      })
      .catch((err) =>
        this.setState({
          getLoading: false,
          getError: err?.response?.data?.message || "Error",
        })
      );
  }

  closeAddSuccess = () => this.setState({ addSuccess: false });
  closeEditSuccess = () => this.setState({ editSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteSuccess: false });

  dismissAddError = () => this.setState({ addError: null });
  dismissGetError = () => this.setState({ getError: null });
  dismissEditError = () => this.setState({ editError: null });
  dismissDeleteError = () => this.setState({ deleteError: null });

  handleAddOpen = () => this.setState({ add: true });
  handleAddClose = () => this.setState({ add: false });
  handleEditOpen = (id) => this.setState({ edit: true, editId: id });
  handleEditClose = () => this.setState({ edit: false, editId: null });
  handleDeleteOpen = (id) => this.setState({ delete: true, deleteId: id });
  handleDeleteClose = () => this.setState({ delete: false, deleteId: null });

  handleDelete = () => {
    this.setState({ delete: false, deleteLoading: true });
    admin_api
      .delete(`/watch/${this.state.deleteId}`)
      .then((res) => {
        let data = this.state.data.filter(
          (w) => w["id"] !== this.state.deleteId
        );
        let watch_sequence = this.state.level?.watch_sequence || [];
        let index = watch_sequence.indexOf(this.state.deleteId);
        watch_sequence = watch_sequence.splice(index, 1);
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteSuccess: true,
          data,
          level: {
            ...this.state.level,
            watch_sequence,
          },
        });
      })
      .catch((err) =>
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteError: err?.response?.data?.message || "Error",
        })
      );
  };

  dragStartHandler = (e) => {
    e.dataTransfer.setData("watchId", e.target.getAttribute("data-watchid"));
    e.dataTransfer.setData(
      "pickIndex",
      e.target.getAttribute("data-cardindex")
    );
    e.dataTransfer.dropEffect = "move";
  };

  dragOverHandler = (e) => {
    e.preventDefault();
  };

  dropHandler = (e) => {
    e.preventDefault();
    this.setState({ editLoading: true });
    const dropRow = e.currentTarget.getAttribute("data-cardindex");
    const watchId = e.dataTransfer.getData("watchId");
    admin_api
      .patch(
        `/levels/${this.props.levelId}/watch-sequence/watch/${watchId}/index/${dropRow}`
      )
      .then((res) => {
        let newWatchSequence = res.data;
        this.setState({
          editLoading: false,
          editSuccess: true,
          level: {
            ...this.state.level,
            watch_sequence: newWatchSequence,
          },
          data: this.state.data.sort(
            (a, b) =>
              newWatchSequence.indexOf(a["id"]) -
              newWatchSequence.indexOf(b["id"])
          ),
        });
      })
      .catch((err) =>
        this.setState({
          editLoading: false,
          editError: err?.response?.data?.message || "Error",
        })
      );
  };

  render() {
    return (
      <Container fluid={true} className="pt-3 position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
          <Toast show={this.state.getLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting video info
            </ToastBody>
          </Toast>
          <Toast show={this.state.deleteLoading} bg="warning">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting video
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.addSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeAddSuccess}
          >
            <ToastBody className="text-white">
              Video added successfully
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.editSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeEditSuccess}
          >
            <ToastBody className="text-white">
              Changes saved successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteSuccess}
            bg="success"
            onClose={this.closeDeleteSuccess}
            delay={2000}
            autohide={true}
          >
            <ToastBody className="text-white">Deleted successfully.</ToastBody>
          </Toast>
          <Toast
            show={this.state.addError !== null}
            bg="danger"
            onClose={this.dismissAddError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Add error</strong>
            </ToastHeader>
            <ToastBody>{this.state.addError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.getError !== null}
            bg="danger"
            onClose={this.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.editError !== null}
            bg="danger"
            onClose={this.dismissEditError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Edit error</strong>
            </ToastHeader>
            <ToastBody>{this.state.editError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteError !== null}
            bg="danger"
            onClose={this.dismissDeleteError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Delete error</strong>
            </ToastHeader>
            <ToastBody>{this.state.deleteError}</ToastBody>
          </Toast>
        </ToastContainer>
        <Button
          type="button"
          variant="primary"
          className="text-white mb-3"
          onClick={this.handleAddOpen}
        >
          Add video
        </Button>
        <br />
        {this.state.data.map((w, index) => (
          <Card
            key={index}
            className="mb-3"
            body
            draggable={true}
            data-cardindex={index}
            data-watchid={w["id"]}
            onDragStartCapture={this.dragStartHandler}
            onDragOverCapture={this.dragOverHandler}
            onDropCapture={this.dropHandler}
          >
            <Row className="g-2">
              <Col md>
                <UtVideo
                  key={w?.id}
                  controls
                  width="100%"
                  height="auto"
                  src={w?.video_url || w?.video?.filePath}
                  subtitle={w?.subtitle?.filePath || null}
                />
              </Col>
              <Col md>
                <FormControl
                  as="textarea"
                  disabled={true}
                  value={w?.description}
                  style={{ minHeight: "100px" }}
                />
                <div className="mt-2 d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={() => this.handleEditOpen(w?.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    className="ms-2"
                    variant="danger"
                    onClick={() => this.handleDeleteOpen(w?.id)}
                  >
                    Delete
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        ))}
        <Modal show={this.state.add} onHide={this.handleAddClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Add a video</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                video_url: "",
                description: "",
                level_id: this.props.levelId,
                video: undefined,
                subtitle: undefined,
              }}
              validationSchema={WatchSchema}
              onSubmit={(values, { errors, status, setStatus, setErrors }) => {
                if (!("subtitle" in values && values.subtitle)) {
                  setErrors({
                    ...errors,
                    subtitle: "Subtitle is required",
                  });
                  return;
                }
                if (
                  "subtitle" in values &&
                  !values.subtitle["type"].match(/text/)
                ) {
                  return;
                }
                if (!values.video && !values.video_url) {
                  setStatus({ video: "You need to either upload a video" });
                  setErrors({
                    ...errors,
                    video_url: "Or, you need to enter a URL to a video ",
                  });
                } else if (
                  !values.video_url &&
                  "video" in values &&
                  !values.video["type"].match(/video/)
                ) {
                  return;
                } else {
                  this.setState({ addLoading: true });
                  let result;
                  if (!!values.video || !!values.subtitle) {
                    result = new FormData();
                    Object.keys(values).forEach((key) =>
                      result.append(key, values[key])
                    );
                  } else {
                    let { video, ...result1 } = values;
                    result = result1;
                  }
                  admin_api
                    .post("/watch", result)
                    .then((res) => {
                      let data = this.state.data;
                      data.push(res.data);
                      let watch_sequence = this.state.level?.watch_sequence;
                      watch_sequence.push(res.data?.id);
                      this.setState({
                        add: false,
                        addLoading: false,
                        addSuccess: true,
                        data,
                        level: {
                          ...this.state.level,
                          watch_sequence,
                        },
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        add: false,
                        addLoading: false,
                        addError: err?.response?.data?.message || "Error",
                      })
                    );
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setStatus,
                touched,
                errors,
                values,
                status,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FormGroup className="mb-2" controlId="add-video.video">
                    <FormLabel>Video</FormLabel>
                    <FormControl
                      type="file"
                      accept="video/*"
                      name="video"
                      onChange={(e) => {
                        setFieldValue("video", e.currentTarget.files[0]);
                        if (e.currentTarget.files[0]["type"].match(/video/)) {
                          setStatus({ video: null });
                        } else {
                          setStatus({
                            video: "File should be a video",
                          });
                        }
                      }}
                      onBlur={handleBlur}
                      disabled={!!values.video_url}
                      isValid={touched.video && !status?.video}
                      isInvalid={touched.video && !!status?.video}
                    />
                    {touched.video && (
                      <FormControl.Feedback type="invalid">
                        {status?.video}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FloatingLabel
                    className="mb-2"
                    label="Video URL"
                    controlId="add-video.video_url"
                  >
                    <FormControl
                      name="video_url"
                      placeholder="Video URL"
                      type="url"
                      value={values.video_url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!!values.video}
                      isValid={touched.video_url && !errors.video_url}
                      isInvalid={touched.video_url && !!errors.video_url}
                    />
                    {touched.video_url && (
                      <FormControl.Feedback type="invalid">
                        {errors.video_url}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormGroup className="mb-2" controlId="add-video.subtitle">
                    <FormLabel>Subtitle</FormLabel>
                    <FormControl
                      type="file"
                      accept="text/vtt"
                      name="subtitle"
                      onChange={(e) => {
                        setFieldValue("subtitle", e.currentTarget.files[0]);
                        if (e.currentTarget.files[0]["type"].match(/text/)) {
                          setStatus({ subtitle: null });
                        } else {
                          setStatus({
                            subtitle: "File should be a subtitle",
                          });
                        }
                      }}
                      onBlur={handleBlur}
                      isValid={touched.subtitle && !status?.subtitle}
                      isInvalid={touched.subtitle && !!status?.subtitle}
                    />
                    {touched.subtitle && (
                      <FormControl.Feedback type="invalid">
                        {status?.subtitle}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FloatingLabel
                    className="mb-2"
                    controlId="add-video.description"
                    label="Video description"
                  >
                    <FormControl
                      as="textarea"
                      name="description"
                      placeholder="Video description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.description && !errors.description}
                      isInvalid={touched.description && !!errors.description}
                      style={{ minHeight: "100px" }}
                    />
                    {touched.description && (
                      <FormControl.Feedback type="invalid">
                        {errors.description}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <ModalFooter>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={this.handleAddClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      disabled={this.state.addLoading}
                      variant="primary"
                      className="text-white"
                    >
                      {this.state.addLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Adding...",
                          ]
                        : "Add"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.edit} onHide={this.handleEditClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Edit video</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.data.find(
                (w) => w.id === this.state.editId
              )}
              validationSchema={WatchSchema}
              onSubmit={(values, { errors, setErrors, setStatus }) => {
                if (!("subtitle" in values && values.subtitle)) {
                  setErrors({
                    ...errors,
                    subtitle: "Subtitle is required",
                  });
                  return;
                }
                if (
                  "subtitle" in values &&
                  !values.subtitle["type"].match(/text/)
                ) {
                  return;
                }
                if (!values.video_url && !values.video) {
                  setStatus({ video: "You need to either upload a video " });
                  setErrors({
                    video_url: "Or, you need to enter the URL to a video",
                  });
                } else if (
                  !values.video_url &&
                  (("type" in values?.video &&
                    !values?.video["type"].match(/video/)) ||
                    ("fileType" in values?.video &&
                      !values?.video["fileType"].match(/video/)))
                ) {
                  // do nothing
                } else {
                  this.setState({ editLoading: true });
                  let result;
                  if (!!values.video || !!values.subtitle) {
                    result = new FormData();
                    Object.keys(values).forEach((key) =>
                      result.append(key, values[key])
                    );
                  } else {
                    values["video_id"] = null;
                    delete values["video"];
                    values["level_id"] = values.level_id.toString();
                    result = values;
                  }
                  admin_api
                    .patch(`/watch/${this.state.editId}`, result)
                    .then((res) => {
                      let data = this.state.data.map((w) => {
                        if (w.id === this.state.editId) {
                          return res.data;
                        }
                        return w;
                      });
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editSuccess: true,
                        data,
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editError: err?.response?.data?.message || "Error",
                      })
                    );
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setTouched,
                setStatus,
                touched,
                values,
                errors,
                status,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FormGroup className="mb-2" controlId="edit-watch.video">
                    <FormLabel htmlFor="edit-watch.video">
                      Video <br />
                      {values?.video?.file}
                    </FormLabel>
                    {values?.video?.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("video", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      name="video"
                      accept="video/*"
                      type="file"
                      disabled={!!values.video_url}
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("video", file);
                        if (file["type"].match(/video/)) {
                          setStatus({ video: null });
                        } else {
                          setStatus({ video: "File should be a video" });
                        }
                      }}
                      onBlur={() => {
                        setTouched({
                          ...touched,
                          video: true,
                        });
                      }}
                      isValid={touched.video && !status?.video}
                      isInvalid={touched.video && !!status?.video}
                    />
                    {touched.video && (
                      <FormControl.Feedback type="invalid">
                        {status?.video}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FloatingLabel
                    className="mb-2"
                    label="Video URL"
                    controlId="edit-watch.video_url"
                  >
                    <FormControl
                      name="video_url"
                      placeholder="Video URL"
                      type="url"
                      value={values.video_url}
                      disabled={!!values.video}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.video_url && !errors.video_url}
                      isInvalid={touched.video_url && !!errors.video_url}
                    />
                    {touched.video_url && (
                      <FormControl.Feedback type="invalid">
                        {errors.video_url}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormGroup className="mb-2" controlId="edit-watch.subtitle">
                    <FormLabel htmlFor="edit-watch.subtitle">
                      Subtitle <br />
                      {values?.subtitle?.file}
                    </FormLabel>
                    {values?.subtitle?.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("subtitle", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      name="subtitle"
                      accept="text/vtt"
                      type="file"
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        console.log(file["type"]);
                        setFieldValue("subtitle", file);
                        if (file["type"].match(/text/)) {
                          setStatus({ subtitle: null });
                        } else {
                          setStatus({
                            subtitle: "File should be a VTT format",
                          });
                        }
                      }}
                      onBlur={() => {
                        setTouched({
                          ...touched,
                          subtitle: true,
                        });
                      }}
                      isValid={touched.subtitle && !status?.subtitle}
                      isInvalid={touched.subtitle && !!status?.subtitle}
                    />
                    {touched.subtitle && (
                      <FormControl.Feedback type="invalid">
                        {status?.subtitle}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FloatingLabel
                    className="mb-2"
                    label="Video description"
                    controlId="edit-watch.description"
                  >
                    <FormControl
                      as="textarea"
                      name="description"
                      placeholder="Video description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.description && !errors.description}
                      isInvalid={touched.description && !!errors.description}
                    />
                    {touched.description && (
                      <FormControl.Feedback type="invalid">
                        {errors.description}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={this.handleEditClose}
                      variant="secondary"
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={this.state.editLoading}
                      className="text-white"
                    >
                      {this.state.editLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Saving...",
                          ]
                        : "Save changes"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.delete} onHide={this.handleDeleteClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Delete the video?</ModalTitle>
          </ModalHeader>
          <ModalBody>Are you sure you want to delete this video?</ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={this.handleDeleteClose}>
              Close
            </Button>
            <Button variant="danger" onClick={this.handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
