import React, { Component } from "react";
import { admin_api } from "../../atoms/api";
import BtContainer from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import FormLabel from "react-bootstrap/FormLabel";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import {
  OnboardSchema,
  RegisterSchoolSchema,
  RevokeSchoolSchema,
  SchoolSchema,
} from "../../atoms/schemas";
import styled from "styled-components";
import { StatesSelect } from "../../atoms/statesSelect";
import FormSelect from "react-bootstrap/FormSelect";
import { InfoSpan } from "../../pages/program";
import Accordion from "react-bootstrap/Accordion";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionBody from "react-bootstrap/AccordionBody";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import exampleCsv from "../../atoms/csv/onboard-student-example.csv";

const Container = styled(BtContainer)`
  & > .toast-container {
    z-index: 1;
  }
`;

export class School extends Component {
  constructor(props) {
    super();
    this.state = {
      add: false,
      addLoading: false,
      addSuccess: false,
      addError: null,
      getLoading: true,
      getError: null,
      getpLoading: true,
      getpError: null,
      data: [],
      programs: [],
      edit: false,
      editId: null,
      editLoading: false,
      editSuccess: false,
      editError: null,
      addStudent: false,
      onboardingLoading: false,
      onboardingError: null,
      onboardingSuccess: false,
      delete: false,
      deleteId: null,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: null,
      showStudentDetails: false,
      selectedSchoolId: null,
      showRatifyModal: false,
      showRevokeModal: false,
      accessUpdateLoading: false,
      accessUpdateError: null,
      studentsLoading: false,
      students: [],
      studentsError: null,
      teacherLoading: true,
      teacherError: null,
      teachers: [],
      selectedTeacherId: null,
      teacherSelectMode: null,
    };
  }

  componentDidMount() {
    admin_api
      .get(`/school`)
      .then((res) => {
        this.setState({ getLoading: false, data: res.data });
      })
      .catch((err) =>
        this.setState({
          getLoading: false,
          getError:
            err?.response?.data?.message || "Error fetching school list",
        })
      );
    admin_api
      .get("/programs/school")
      .then((res) => {
        this.setState({
          getpLoading: false,
          getpError: null,
          programs: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          getpLoading: false,
          getpError:
            err?.response?.data?.message || "Error fetching program list",
        });
      });
  }

  closeAddSuccess = () => this.setState({ addSuccess: false });
  closeEditSuccess = () => this.setState({ editSuccess: false });
  closeOnboardingSuccess = () => this.setState({ onboardingSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteSuccess: false });

  dismissAddError = () => this.setState({ addError: null });
  dismissGetError = () => this.setState({ getError: null });
  dismissGetProgramError = () => this.setState({ getpError: null });
  dismissEditError = () => this.setState({ editError: null });
  dismissOnboardingError = () => this.setState({ onboardingError: null });
  dismissDeleteError = () => this.setState({ deleteError: null });
  dismissAccessUpdateError = () => this.setState({ accessUpdateError: null });
  dismissTeacherError = () => this.setState({ teacherError: null });

  handleAddOpen = () => this.setState({ add: true });
  handleAddClose = () => this.setState({ add: false });
  handleEditOpen = (id) => this.setState({ edit: true, editId: id });
  handleEditClose = () => this.setState({ edit: false, editId: null });
  handleOnboardingOpen = (id) =>
    this.setState({ edit: false, editId: id, addStudent: true });
  handleOnboardingClose = () =>
    this.setState({ edit: false, editId: null, addStudent: false });
  handleDeleteOpen = (id) => this.setState({ delete: true, deleteId: id });
  handleDeleteClose = () => this.setState({ delete: false, deleteId: null });

  handleDelete = () => {
    this.setState({ delete: false, deleteLoading: true });
    admin_api
      .delete(`/school/${this.state.deleteId}`)
      .then((res) => {
        let data = this.state.data.filter(
          (d) => d["id"] !== this.state.deleteId
        );
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteSuccess: true,
          data,
        });
      })

      .catch((err) =>
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteError: err?.response?.data?.message || "Error deleting school",
        })
      );
  };

  handleCloseStudentDetails = () => {
    // showStudentDetails(false);
    // studentsLoading (false);
    // studentsError (null);
    this.setState({
      showStudentDetails: false,
      studentsLoading: false,
      studentsError: null,
    });
  };
  handleShowStudentDetails = (id) => {
    this.setState({ showStudentDetails: true, studentsLoading: true });
    admin_api
      .get(`/school/${id}/learners`)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          studentsLoading: false,
          students: res,
          studentsError: null,
        });
      })
      .catch((err) => {
        this.setState({
          showStudentDetails: false,
          studentsLoading: false,
          studentsError:
            err?.response?.data?.message ||
            "Error getting student details. Click again",
        });
      });
  };

  handleCloseTeacherModal = () => {
    this.setState({
      showTeacherModal: false,
      teachers: [],
      teacherError: null,
      teacherLoading: false,
      selectedTeacherId: null,
      teacherSelectMode: null,
    });
  };

  handleShowTeacherModal = (schoolId) => {
    this.setState({ showTeacherModal: true, teacherLoading: true });
    admin_api
      .get(`/teacher/school/${schoolId}`)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          teacherLoading: false,
          teacherError: null,
          teachers: res,
        });
      })
      .catch((err) => {
        this.setState({
          teacherLoading: false,
          teacherError:
            err?.response?.data?.message ||
            "Error getting teacher. Click again",
          showTeacherModal: false,
        });
      });
  };

  handleCloseRevokeModal = () => {
    this.setState({ showRevokeModal: false });
  };

  handleShowRevokeModal = (id) => {
    this.setState({ showRevokeModal: true, selectedSchoolId: id });
  };

  handleCloseRatifyModal = () => {
    this.setState({ showRatifyModal: false, selectedSchoolId: null });
  };

  handleShowRatifyModal = (id) => {
    this.setState({ showRatifyModal: true, selectedSchoolId: id });
  };

  handleRatify = () => {
    this.setState({ accessUpdateLoading: true });
    admin_api
      .patch(`/school/${this.state.selectedSchoolId}/ratify`)
      .then(() => {
        let schools = this.state.data.map((s) => {
          if (s.id === this.state.selectedSchoolId) {
            return { ...s, isRevoked: false, revokeReason: "", revokedAt: "" };
          } else return s;
        });
        this.setState({
          showRatifyModal: false,
          data: schools,
          selectedSchoolId: null,
          accessUpdateLoading: false,
          accessUpdateError: null,
        });
      })
      .catch((err) => {
        this.setState({
          accessUpdateLoading: false,
          accessUpdateError:
            err?.response?.data?.message || "Error ratifying access",
        });
      });
  };

  render() {
    return (
      <Container fluid={true} className="position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
          <Toast show={this.state.getLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Getting list of schools...
            </ToastBody>
          </Toast>
          <Toast show={this.state.getpLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Getting list of program...
            </ToastBody>
          </Toast>
          <Toast show={this.state.deleteLoading} bg="warning">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting school...
            </ToastBody>
          </Toast>
          <Toast show={this.state.accessUpdateLoading} bg="info">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Updating access
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.addSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeAddSuccess}
          >
            <ToastBody className="text-white">
              School added successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.editSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeEditSuccess}
          >
            <ToastBody className="text-white">
              Changes saved successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.onboardingSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeOnboardingSuccess}
          >
            <ToastBody className="text-white">
              Onboarding completed successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteSuccess}
            bg="success"
            onClose={this.closeDeleteSuccess}
            delay={2000}
            autohide={true}
          >
            <ToastBody className="text-white">Deleted successfully.</ToastBody>
          </Toast>
          <Toast
            show={this.state.addError !== null}
            bg="danger"
            onClose={this.dismissAddError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Add error</strong>
            </ToastHeader>
            <ToastBody>{this.state.addError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.getError !== null}
            bg="danger"
            onClose={this.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">School fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.getpError !== null}
            bg="danger"
            onClose={this.dismissGetProgramError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Program fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getpError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.editError !== null}
            bg="danger"
            onClose={this.dismissEditError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Edit error</strong>
            </ToastHeader>
            <ToastBody>{this.state.editError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.onboardingError !== null}
            bg="danger"
            onClose={this.dismissOnboardingError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Onboarding error</strong>
            </ToastHeader>
            <ToastBody>{this.state.onboardingError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteError !== null}
            bg="danger"
            onClose={this.dismissDeleteError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Delete error</strong>
            </ToastHeader>
            <ToastBody>{this.state.deleteError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.accessUpdateError !== null}
            bg="danger"
            onClose={this.dismissAccessUpdateError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Update error</strong>
            </ToastHeader>
            <ToastBody>{this.state.accessUpdateError}</ToastBody>
          </Toast>
        </ToastContainer>
        <div className="d-flex flex-column flex-sm-row align-items-start mb-3">
          <Button
            type="button"
            variant="primary"
            className="text-white me-2"
            onClick={this.handleAddOpen}
          >
            Add School
          </Button>
          <Button
            as="a"
            href={exampleCsv}
            download="onboard-students.csv"
            variant="primary"
            className="text-white mt-2 mt-sm-0 ms-sm-2"
          >
            Download exemplary CSV
          </Button>
        </div>
        <Table hover={true} striped={true} responsive="sm" bordered={true}>
          <thead>
            <tr>
              <th>School Name</th>
              <th>Location</th>
              <th>Username</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((d, index) => (
              <tr key={index}>
                <td>{d?.name}</td>
                <td>{d?.location}</td>
                <td>{d?.username}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row align-items-start">
                    <Button
                      variant="primary"
                      type="button"
                      className="text-white me-md-2 mt-2 mt-md-0"
                      onClick={() => this.handleOnboardingOpen(d["id"])}
                    >
                      Onboard students
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      className="text-white me-md-2 mt-2 mt-md-0"
                      onClick={() => this.handleShowStudentDetails(d.id)}
                    >
                      Total enrolments
                    </Button>
                    <a href={`school/teachers/${d.id}`}>
                      <Button
                        type="button"
                        variant="primary"
                        className="text-white me-md-2 mt-2 mt-md-0"
                      >
                        Teachers
                      </Button>
                    </a>
                    {!d.isRevoked ? (
                      <Button
                        variant="primary"
                        type="button"
                        className="text-white mt-2 mt-md-0 me-md-2"
                        onClick={() => this.handleShowRevokeModal(d.id)}
                      >
                        Revoke
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        type="button"
                        className="text-white mt-2 mt-md-0 me-md-2"
                        onClick={() => this.handleShowRatifyModal(d.id)}
                      >
                        Ratify
                      </Button>
                    )}

                    <Button
                      variant="secondary"
                      type="button"
                      className="mt-2 mt-md-0 me-md-2"
                      onClick={() => this.handleEditOpen(d["id"])}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="mt-2 mt-md-0"
                      onClick={() => this.handleDeleteOpen(d["id"])}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={this.state.add} onHide={this.handleAddClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Add a new school</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                name: "",
                username: "",
                location: "Maharashtra",
                password: "",
                cPassword: "",
                contactEmail: "",
                type: "online",
              }}
              validationSchema={RegisterSchoolSchema}
              onSubmit={(values) => {
                this.setState({ addLoading: true });
                admin_api
                  .post("/school", values)
                  .then((res) => {
                    let data = this.state.data;
                    data.push(res.data);
                    this.setState({
                      add: false,
                      addLoading: false,
                      addSuccess: true,
                      data,
                    });
                  })
                  .catch((err) => {
                    this.setState({
                      add: false,
                      addLoading: false,
                      addError: err?.response?.data?.message || "Error",
                    });
                  });
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="School Name"
                    controlId="new-school.name"
                    className="mb-2"
                  >
                    <FormControl
                      name="name"
                      placeholder="School Name"
                      required={true}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    {touched.name && (
                      <FormControl.Feedback type="invalid">
                        {errors.name}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="new-school.location"
                        label="Location"
                      >
                        <StatesSelect
                          name="location"
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.location && !errors.location}
                          isInvalid={touched.location && !!errors.location}
                          required
                        />
                        {touched.location && (
                          <FormControl.Feedback type="invalid">
                            {errors.location}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        label="Set username"
                        controlId="school.name"
                      >
                        <FormControl
                          name="username"
                          placeholder="username"
                          required={true}
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.username && !errors.username}
                          isInvalid={touched.username && !!errors.username}
                        />
                        {touched.name && (
                          <FormControl.Feedback type="invalid">
                            {errors.username}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="new-school.password"
                        label="Password"
                      >
                        <FormControl
                          type="password"
                          placeholder="Set password"
                          autoComplete="new-password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.password && !errors.password}
                          isInvalid={touched.password && !!errors.password}
                        />
                        {touched.password && (
                          <FormControl.Feedback type="invalid">
                            {errors.password}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="new-school.confirm-password"
                        label="Confirm password"
                      >
                        <FormControl
                          type="password"
                          placeholder="Confirm password"
                          autoComplete="new-password"
                          name="cPassword"
                          value={values.cPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.cPassword && !errors.cPassword}
                          isInvalid={touched.cPassword && !!errors.cPassword}
                          required={true}
                        />
                        {touched.cPassword && (
                          <FormControl.Feedback type="invalid">
                            {errors.cPassword}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <FloatingLabel
                    label="School contact email"
                    controlId="new-school.contactEmail"
                    className="mb-2"
                  >
                    <FormControl
                      type="email"
                      name="contactEmail"
                      placeholder="School contact email"
                      required={true}
                      value={values.contactEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.contactEmail && !errors.contactEmail}
                      isInvalid={touched.contactEmail && !!errors.contactEmail}
                    />
                    {touched.contactEmail && (
                      <FormControl.Feedback type="invalid">
                        {errors.contactEmail}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormSelect
                    className="mb-2"
                    name="type"
                    required={true}
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.type && !errors.type}
                    isInvalid={touched.type && !!errors.type}
                  >
                    <option value="hybrid">Hybrid</option>
                    <option value="online">Online</option>
                  </FormSelect>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleAddClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="text-white"
                      disabled={this.state.addLoading}
                    >
                      {this.state.addLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Adding...",
                          ]
                        : "Add"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.edit} onHide={this.handleEditClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Edit School</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.data.find(
                (d) => d["id"] === this.state.editId
              )}
              validationSchema={SchoolSchema}
              onSubmit={(values) => {
                this.setState({ editLoading: true });
                admin_api
                  .patch(`/school/${this.state.editId}`, values)
                  .then((res) => {
                    let data = this.state.data.map((d) => {
                      if (d.id === this.state.editId) {
                        return res.data;
                      }
                      return d;
                    });
                    this.setState({
                      edit: false,
                      editId: null,
                      editLoading: false,
                      editSuccess: true,
                      data,
                    });
                  })
                  .catch((err) =>
                    this.setState({
                      edit: false,
                      editId: null,
                      editLoading: false,
                      editError:
                        err?.response?.data?.message || "Error editing school",
                    })
                  );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                values,
                errors,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="School Name"
                    controlId="edit-school.name"
                    className="mb-2"
                  >
                    <FormControl
                      name="name"
                      placeholder="School Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                      required
                    />
                    {touched.name && (
                      <FormControl.Feedback type="invalid">
                        {errors.name}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <Row className="g-2 mb-2">
                    <Col md>
                      <FloatingLabel
                        controlId="new-school.location"
                        label="Location"
                      >
                        <StatesSelect
                          name="location"
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.location && !errors.location}
                          isInvalid={touched.location && !!errors.location}
                          required
                        />
                        {touched.location && (
                          <FormControl.Feedback type="invalid">
                            {errors.location}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        label="Set username"
                        controlId="school.username"
                      >
                        <FormControl
                          name="username"
                          placeholder="username"
                          required={true}
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.username && !errors.username}
                          isInvalid={touched.username && !!errors.username}
                        />
                        {touched.username && (
                          <FormControl.Feedback type="invalid">
                            {errors.username}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <FloatingLabel
                    label="School contact email"
                    controlId="new-school.contactEmail"
                    className="mb-2"
                  >
                    <FormControl
                      type="email"
                      name="contactEmail"
                      placeholder="School contact email"
                      required={true}
                      value={values.contactEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.contactEmail && !errors.contactEmail}
                      isInvalid={touched.contactEmail && !!errors.contactEmail}
                    />
                    {touched.contactEmail && (
                      <FormControl.Feedback type="invalid">
                        {errors.contactEmail}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormSelect
                    className="mb-2"
                    name="type"
                    required={true}
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.type && !errors.type}
                    isInvalid={touched.type && !!errors.type}
                  >
                    <option value="hybrid">Hybrid</option>
                    <option value="online">Online</option>
                  </FormSelect>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleEditClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={this.state.editLoading}
                      className="text-white"
                    >
                      {this.state.editLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Saving...",
                          ]
                        : "Save changes"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.delete} onHide={this.handleDeleteClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Delete this school?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete this school?
            <br />
            <strong className="text-underline">
              This will also delete enrolments and learner accounts created
              during onboarding.
            </strong>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              variant="secondary"
              onClick={this.handleDeleteClose}
            >
              Close
            </Button>
            <Button type="button" variant="danger" onClick={this.handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal show={this.state.addStudent} onHide={this.handleOnboardingClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Onboard students</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                school_id: this.state.editId,
                school: this.state.data.find((s) => s.id === this.state.editId),
                program_id: "",
                data: undefined,
              }}
              validationSchema={OnboardSchema}
              onSubmit={({ school, ...values }) => {
                if (!!values.data && !values.data?.type.match(/csv$/)) {
                  // do nothing
                } else {
                  this.setState({
                    onboardingLoading: true,
                    onboardingError: null,
                  });
                  let values_form_data = new FormData();
                  Object.keys(values)
                    .filter((key) => !!values[key])
                    .forEach((key) =>
                      values_form_data.append(key, values[key])
                    );
                  admin_api
                    .post(`/school/bulk-insert`, values_form_data)
                    .then((res) => {
                      this.setState({
                        edit: false,
                        editId: null,
                        onboardingLoading: false,
                        onboardingSuccess: true,
                        addStudent: false,
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        edit: false,
                        editId: null,
                        onboardingLoading: false,
                        onboardingError:
                          err?.response?.data?.message ||
                          "Error onboarding students",
                        addStudent: false,
                      })
                    );
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setTouched,
                setStatus,
                touched,
                errors,
                values,
                status,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="School Name"
                    controlId="onboard-students.name"
                    className="mb-2"
                  >
                    <FormControl
                      name="school name"
                      placeholder="School Name"
                      value={values.school?.name}
                      disabled
                    />
                  </FloatingLabel>
                  <FormGroup as={Col} className="mb-2">
                    <FloatingLabel
                      controlId="onboard-students.program_id"
                      label="Program"
                    >
                      <FormSelect
                        name="program_id"
                        value={values.program_id}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.program_id && !errors.program_id}
                        isInvalid={touched.program_id && !!errors.program_id}
                        disabled={
                          this.state.getpLoading ||
                          this.state.getpError !== null
                        }
                      >
                        {[
                          <option value="" key="select_a_program">
                            Select a program
                          </option>,
                        ].concat(
                          ...this.state.programs.map((p, index) => (
                            <option key={index} value={p.id}>
                              {p.name}
                            </option>
                          ))
                        )}
                      </FormSelect>
                      {this.state.getpLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {touched.program_id && (
                        <FormControl.Feedback type="invalid">
                          {errors.program_id}
                        </FormControl.Feedback>
                      )}
                      {this.state.getpError && (
                        <span className="text-danger">
                          {this.state.getpError}
                        </span>
                      )}
                    </FloatingLabel>
                  </FormGroup>
                  <FormGroup controlId="onboard-students.data" className="mb-2">
                    <FormLabel>
                      Upload CSV
                      <InfoSpan
                        className="ms-2"
                        as="a"
                        href={exampleCsv}
                        download="onboard-students.csv"
                      >
                        Download exemplary CSV
                      </InfoSpan>
                    </FormLabel>
                    <FormControl
                      name="data"
                      type="file"
                      required
                      accept=".csv"
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("data", file);
                        if (file["type"].match(/.csv$/)) {
                          setStatus({ data: null });
                        } else {
                          setStatus({ data: "File should be CSV" });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, data: true });
                      }}
                      isValid={touched.data && !status?.data}
                      isInvalid={touched.data && !!status?.data}
                    />
                    {touched.data && (
                      <FormControl.Feedback type="invalid">
                        {status?.data}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleOnboardingClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={this.state.onboardingLoading}
                      className="text-white"
                    >
                      {this.state.onboardingLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Onboarding...",
                          ]
                        : "Onboard students"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.showStudentDetails}
          onHide={this.handleCloseStudentDetails}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enrolments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.studentsLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : this.state.studentsError !== null ? (
              <span className="text-danger">{this.state.studentsError}</span>
            ) : (
              <>
                <h5>Total enrolments: {this.state.students.length}</h5>
                <Table
                  hover={true}
                  striped={true}
                  responsive="sm"
                  bordered={true}
                >
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Grade</th>
                      <th>Divison</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.students.map((s, idx) => (
                      <tr key={idx}>
                        <td>{s?.first_name}</td>
                        <td>{s?.class}</td>
                        <td>{s?.division}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.handleCloseStudentDetails}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.handleCloseStudentDetails}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showRatifyModal}
          onHide={this.handleCloseRatifyModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to ratify this school ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseRatifyModal}>
              Close
            </Button>
            <Button variant="primary text-white" onClick={this.handleRatify}>
              Apply Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showRevokeModal}
          onHide={this.handleCloseRevokeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{ revokeReason: "" }}
              validationSchema={RevokeSchoolSchema}
              onSubmit={(values) => {
                this.setState({ accessUpdateLoading: true });
                admin_api
                  .patch(
                    `/school/${this.state.selectedSchoolId}/revoke`,
                    values
                  )
                  .then(() => {
                    let schools = this.state.data.map((s) => {
                      if (s.id === this.state.selectedSchoolId) {
                        return {
                          ...s,
                          isRevoked: true,
                          revokeReason: values.revokeReason,
                          revokedAt: new Date(),
                        };
                      } else return s;
                    });

                    this.setState({
                      showRevokeModal: false,
                      data: schools,
                      selectedSchoolId: null,
                      accessUpdateLoading: false,
                      accessUpdateError: null,
                    });
                  })
                  .catch((err) => {
                    this.setState({
                      accessUpdateLoading: false,
                      accessUpdateError:
                        err?.response?.data?.message || "Error revoking access",
                    });
                  });
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="Reason"
                    controlId="revokeReason"
                    className="mb-2"
                  >
                    <FormControl
                      as="textarea"
                      name="revokeReason"
                      placeholder="Reason"
                      value={values.revokeReason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.revokeReason && !errors.revokeReason}
                      isInvalid={touched.revokeReason && !!errors.revokeReason}
                      style={{ minHeight: "100px" }}
                    />
                    {touched.revokeReason && (
                      <FormControl.Feedback type="invalid">
                        {errors.revokeReason}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.handleCloseRevokeModal}
                    >
                      Close
                    </Button>
                    <Button variant="primary text-white" type="submit">
                      Apply Changes
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showTeacherModal}
          onHide={this.handleCloseTeacherModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Teachers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Accordion defaultActiveKey="list-teachers">
              <AccordionItem eventKey="list-teachers">
                <AccordionHeader>List of teachers</AccordionHeader>
                <AccordionBody>
                  <Table
                    hover={true}
                    striped={true}
                    responsive="sm"
                    bordered={true}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Utkarsh Mehta</td>
                        <td>utk@gmail.com</td>
                        <td>
                          <button
                            type="button "
                            class="btn btn-primary me-2"
                            onclick=""
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            onclick=""
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="crud-teacher">
                <AccordionHeader>C.R.U.D. Teacher</AccordionHeader>
                <AccordionBody>
                  <form action="">
                    <div class="mb-3 mt-3">
                      <label for="email" class="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="Enter first name"
                        name="email"
                      />
                    </div>
                    <div class="mb-3 mt-3">
                      <label for="email" class="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="Enter last name"
                        name="email"
                      />
                    </div>
                    <div class="mb-3 mt-3">
                      <label for="email" class="form-label">
                        Email:
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Enter email"
                        name="email"
                      />
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Enter password"
                          name="email"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Confirm password"
                          name="pswd"
                        />
                      </div>
                    </div>

                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </form>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.handleCloseStudentDetails}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
