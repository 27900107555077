import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalBody from "react-bootstrap/ModalBody";
import Form from "react-bootstrap/Form";
import FormLabel from "react-bootstrap/FormLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormSelect from "react-bootstrap/FormSelect";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Badge from "react-bootstrap/Badge";
import { InfoSpan } from "../../pages/program";
import { UtImg } from "../../atoms/img";
import ReactSlider from "react-slider";
import { Formik } from "formik";
import { ProgramSchema } from "../../atoms/schemas";

export const RegularPrograms = (props) => {
  return (
    <Container fluid={true} className="position-relative">
      <ToastContainer position="top-end" className="pt-2 pe-md-1">
        <Toast
          show={props.addProgramSuccess}
          delay={2000}
          autohide={true}
          onClose={props.closeAddSuccess}
          bg="success"
        >
          <ToastBody className="text-white">
            Program added successfully
          </ToastBody>
        </Toast>
        <Toast
          show={props.editProgramSuccess}
          delay={2000}
          autohide={true}
          onClose={props.closeEditSuccess}
          bg="success"
        >
          <ToastBody className="text-white">
            Program changes saved successfully
          </ToastBody>
        </Toast>

        <Toast
          show={props.addProgramError !== null}
          bg="danger"
          onClose={props.dismissAddProgramError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Adding error</strong>
          </ToastHeader>
          <ToastBody>{props.addProgramError}</ToastBody>
        </Toast>
        <Toast
          show={props.editProgramError !== null}
          bg="danger"
          onClose={props.dismissEditProgramError}
        >
          <ToastHeader closeButton={true}>
            <strong className="me-auto">Editing error</strong>
          </ToastHeader>
          <ToastBody>{props.editProgramError}</ToastBody>
        </Toast>
      </ToastContainer>
      <Button
        type="button"
        className="text-white mb-3"
        onClick={props.handleAddShow}
      >
        Add a new program
      </Button>
      <Table hover={true} striped={true} responsive="sm" bordered={true}>
        <thead>
          <tr>
            <th>Title image</th>
            <th>Name</th>
            <th>Description</th>
            <th>Unit amount</th>
            <th>Validity months</th>
            <th>Learning hours</th>
            <th>Directed at</th>
            <th>Age group</th>
            <th>Creator</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.programs.map((p) => (
            <tr key={p["id"]}>
              <td>
                <UtImg
                  src={p.title_image?.filePath}
                  alt={p.title_image?.file}
                  width="50px"
                  height="auto"
                />
              </td>
              <td>{p["name"]}</td>
              <td>{p["description"]}</td>
              <td>{p["cost"]}</td>
              <td>{p["validity"]}</td>
              <td>{p["learning_hours"]}</td>
              <td>
                <Badge
                  pill={true}
                  bg={
                    p["directed_at"] === "girls"
                      ? "primary"
                      : p["directed_at"] === "boys"
                      ? "secondary"
                      : "success"
                  }
                >
                  {p["directed_at"]}
                </Badge>
              </td>
              <td>{p["age_group"]}</td>
              <td>{p["creator"]}</td>
              <td>
                <div className="d-flex flex-column align-items-start flex-md-row justify-content-md-start">
                  <Button
                    type="button"
                    variant="secondary"
                    className="mb-2 mb-md-0 me-md-2"
                    onClick={() => props.handleEditShow(p["id"])}
                  >
                    Edit
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    className="mb-2 mb-md-0 me-md-auto"
                    onClick={() => props.seeProgram(p["id"])}
                  >
                    Levels
                  </Button>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => props.handleDeleteShow(p["id"])}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={props.addProgram} onHide={props.handleAddClose} size="lg">
        <ModalHeader closeButton={true}>
          <ModalTitle>Add a new program</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              name: "",
              description: "",
              title_image: undefined,
              cost: "",
              validity: "",
              learning_hours: "",
              primary_color: "",
              secondary_color: "",
              directed_at: "girls",
              age_group: "9-12",
              creator: "UnTaboo",
            }}
            validationSchema={ProgramSchema}
            onSubmit={(values, { setStatus }) => {
              if (!values.title_image) {
                setStatus({ title_image: "Title image is required." });
                return null;
              } else if (!values.title_image["type"].match(/image/)) {
                setStatus({
                  title_image: "Title image should be an image",
                });
                return null;
              } else {
                props.handleAdd(values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setTouched,
              setStatus,
              values,
              touched,
              errors,
              status,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Row className="mb-3 g-3">
                  <Col>
                    <FloatingLabel
                      controlId="new-program.name"
                      label="Program name"
                    >
                      <FormControl
                        placeholder="Program name"
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        type="text"
                        isValid={touched.name && !errors.name}
                        isInvalid={touched.name && !!errors.name}
                      />
                      {touched.name && (
                        <FormControl.Feedback type="invalid">
                          {errors.name}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <Col>
                    <FloatingLabel
                      controlId="new-program.description"
                      label="Program description"
                    >
                      <FormControl
                        as="textarea"
                        name="description"
                        placeholder="Program description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.description && !errors.description}
                        isInvalid={touched.description && !!errors.description}
                        style={{ minHeight: "100px" }}
                      />
                      {touched.description && (
                        <FormControl.Feedback type="invalid">
                          {errors.description}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <Col md="4">
                    <FloatingLabel
                      controlId="new-program.cost"
                      label="Program cost"
                    >
                      <FormControl
                        type="number"
                        name="cost"
                        placeholder="Program cost"
                        value={values.cost}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.cost && !errors.cost}
                        isInvalid={touched.cost && !!errors.cost}
                      />
                      {touched.cost && (
                        <FormControl.Feedback type="invalid">
                          {errors.cost}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="4">
                    <FloatingLabel
                      controlId="new-program.validity"
                      label="Program validity in months"
                    >
                      <FormControl
                        type="number"
                        name="validity"
                        placeholder="Program validity in months"
                        value={values.validity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.validity && !errors.validity}
                        isInvalid={touched.validity && !!errors.validity}
                      />
                      {touched.validity && (
                        <FormControl.Feedback type="invalid">
                          {errors.validity}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="4">
                    <FloatingLabel
                      controlId="new-program.learning_hours"
                      label="Program learning hours"
                    >
                      <FormControl
                        type="number"
                        name="learning_hours"
                        placeholder="Program learning hours"
                        value={values.learning_hours}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.learning_hours && !errors.learning_hours
                        }
                        isInvalid={
                          touched.learning_hours && !!errors.learning_hours
                        }
                      />
                      {touched.learning_hours && (
                        <FormControl.Feedback type="invalid">
                          {errors.learning_hours}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <FormGroup as={Col} md="6">
                    <FormLabel htmlFor="new-program.title_image">
                      Title image
                    </FormLabel>
                    <FormControl
                      type="file"
                      accept="image/*"
                      name="title_image"
                      id="new-program.title_image"
                      // value={values.title_image}
                      onChange={(event) => {
                        setFieldValue(
                          "title_image",
                          event.currentTarget.files[0]
                        );
                        if (
                          event.currentTarget.files[0]["type"].match(/image/)
                        ) {
                          setStatus({ title_image: null });
                        } else {
                          setStatus({
                            title_image: "Title image should be an image",
                          });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, title_image: true });
                        if (!values.title_image) {
                          setStatus({
                            title_image: "Title image is required",
                          });
                        }
                      }}
                      required={true}
                      isValid={touched.title_image && !status?.title_image}
                      isInvalid={touched.title_image && !!status?.title_image}
                    />
                    {touched.title_image && (
                      <FormControl.Feedback type="invalid">
                        {status?.title_image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FormGroup as={Col} md="3" xs="6">
                    <FormLabel htmlFor="new-program.primary_color">
                      Primary colour
                    </FormLabel>
                    <FormControl
                      style={{ minWidth: "70px" }}
                      type="color"
                      name="primary_color"
                      id="new-program.primary_color"
                      value={values.primary_color}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.primary_color && !errors.primary_color}
                      isInvalid={
                        touched.primary_color && !!errors.primary_color
                      }
                    />
                    {touched.primary_color && (
                      <FormControl.Feedback type="invalid">
                        {errors.primary_color}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FormGroup as={Col} xs="6" md="3">
                    <FormLabel htmlFor="new-program.secondary_color">
                      Secondary colour
                    </FormLabel>
                    <FormControl
                      style={{ minWidth: "70px" }}
                      type="color"
                      name="secondary_color"
                      id="new-program.secondary-color"
                      value={values.secondary_color}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={
                        touched.secondary_color && !errors.secondary_color
                      }
                      isInvalid={
                        touched.secondary_color && !!errors.secondary_color
                      }
                    />
                    {touched.secondary_color && (
                      <FormControl.Feedback type="invalid">
                        {errors.secondary_color}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                </Row>
                <Row className="mb-3 g-3">
                  <Col md="4">
                    <FloatingLabel
                      controlId="new-program.directed_at"
                      label="Directed at"
                    >
                      <FormSelect
                        name="directed_at"
                        placeholder="Directed at"
                        value={values.directed_at}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.directed_at && !errors.directed_at}
                        isInvalid={touched.directed_at && !!errors.directed_at}
                      >
                        <option value="girls">Girls</option>
                        <option value="boys">Boys</option>
                        <option value="others">Others</option>
                      </FormSelect>
                      {touched.directed_at && (
                        <FormControl.Feedback type="invalid">
                          {errors.directed_at}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="8" className="d-flex flex-column">
                    <label>Age group</label>
                    <ReactSlider
                      className="react-slider-program"
                      thumbClassName="react-slider-program-thumb"
                      trackClassName="react-slider-program-track"
                      value={values.age_group
                        .split("-")
                        .map((n) => parseInt(n))}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      onChange={(e) => {
                        setFieldValue("age_group", e.join("-"));
                      }}
                      onBlur={handleBlur}
                      pearling
                      minDistance={1}
                      min={5}
                      max={25}
                    />
                  </Col>
                </Row>
                <Row className="g-3 mb-3">
                  <Col md="4">
                    <FloatingLabel
                      controlId="new-program.creator"
                      label="Creator of program"
                    >
                      <FormControl
                        type="text"
                        name="creator"
                        placeholder="Creator of program"
                        value={values.creator}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.creator && !errors.creator}
                        isInvalid={touched.creator && !!errors.creator}
                      />
                      {touched.creator && (
                        <FormControl.Feedback type="invalid">
                          {errors.creator}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={props.handleAddClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    type="submit"
                    variant="primary"
                    disabled={props.addProgramLoading}
                  >
                    {props.addProgramLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Adding...",
                        ]
                      : "Add program"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal show={props.editProgram} onHide={props.handleEditClose} size="lg">
        <ModalHeader closeButton={true}>
          <ModalTitle>Edit program</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={props.programs.find(
              (p) => p["id"] === props.editProgramId
            )}
            validationSchema={ProgramSchema}
            onSubmit={(values, { setStatus }) => {
              if (!values.title_image) {
                setStatus({ title_image: "Title image is required." });
                return null;
              } else if (
                !("id" in values.title_image) &&
                !values.title_image["type"].match(/image/)
              ) {
                setStatus({
                  title_image: "Title image should be an image",
                });
                return null;
              } else {
                props.handleEdit(values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setStatus,
              setTouched,
              touched,
              errors,
              values,
              status,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Row className="mb-3 g-3">
                  <Col>
                    <FloatingLabel
                      controlId="edit-program.name"
                      label="Program name"
                    >
                      <FormControl
                        placeholder="Program name"
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        type="text"
                        isValid={touched.name && !errors.name}
                        isInvalid={touched.name && !!errors.name}
                      />
                      {touched.name && (
                        <FormControl.Feedback type="invalid">
                          {errors.name}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <Col>
                    <FloatingLabel
                      controlId="edit-program.description"
                      label="Program description"
                    >
                      <FormControl
                        as="textarea"
                        name="description"
                        placeholder="Program description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.description && !errors.description}
                        isInvalid={touched.description && !!errors.description}
                        style={{ minHeight: "100px" }}
                      />
                      {touched.description && (
                        <FormControl.Feedback type="invalid">
                          {errors.description}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <Col md="4">
                    <FloatingLabel
                      controlId="edit-program.cost"
                      label="Program cost"
                    >
                      <FormControl
                        type="number"
                        name="cost"
                        placeholder="Program cost"
                        value={values.cost}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.cost && !errors.cost}
                        isInvalid={touched.cost && !!errors.cost}
                      />
                      {touched.cost && (
                        <FormControl.Feedback type="invalid">
                          {errors.cost}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="4">
                    <FloatingLabel
                      controlId="edit-program.validity"
                      label="Program validity in months"
                    >
                      <FormControl
                        type="number"
                        name="validity"
                        placeholder="Program validity in months"
                        value={values.validity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.validity && !errors.validity}
                        isInvalid={touched.validity && !!errors.validity}
                      />
                      {touched.validity && (
                        <FormControl.Feedback type="invalid">
                          {errors.validity}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="4">
                    <FloatingLabel
                      controlId="edit-program.learning_hours"
                      label="Program learning hours"
                    >
                      <FormControl
                        type="number"
                        name="learning_hours"
                        placeholder="Program learning hours"
                        value={values.learning_hours}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.learning_hours && !errors.learning_hours
                        }
                        isInvalid={
                          touched.learning_hours && !!errors.learning_hours
                        }
                      />
                      {touched.learning_hours && (
                        <FormControl.Feedback type="invalid">
                          {errors.learning_hours}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <FormGroup as={Col} md="6">
                    <FormLabel htmlFor="edit-program.title_image">
                      Title image
                      <br />
                      {values?.title_image?.file}
                    </FormLabel>
                    {values?.title_image?.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("title_image", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      type="file"
                      accept="image/*"
                      name="title_image"
                      id="edit-program.title_image"
                      // value={values.title_image}
                      onChange={(event) => {
                        setFieldValue(
                          "title_image",
                          event.currentTarget.files[0]
                        );
                        if (
                          event.currentTarget.files[0]["type"].match(/image/)
                        ) {
                          setStatus({ title_image: null });
                        } else {
                          setStatus({
                            title_image: "Title image should be an image",
                          });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, title_image: true });
                        if (!values.title_image) {
                          setStatus({
                            title_image: "Title image is required",
                          });
                        }
                      }}
                      required={true}
                      isValid={touched.title_image && !status?.title_image}
                      isInvalid={touched.title_image && !!status?.title_image}
                    />
                    {touched.title_image && (
                      <FormControl.Feedback
                        type="invalid"
                        controlId="edit-program.title_image"
                      >
                        {status?.title_image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FormGroup as={Col} md="3" xs="6">
                    <FormLabel htmlFor="edit-program.primary_color">
                      Primary colour
                    </FormLabel>
                    <FormControl
                      style={{ minWidth: "70px" }}
                      type="color"
                      name="primary_color"
                      id="edit-program.primary_color"
                      value={values.primary_color}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.primary_color && !errors.primary_color}
                      isInvalid={
                        touched.primary_color && !!errors.primary_color
                      }
                    />
                    {touched.primary_color && (
                      <FormControl.Feedback type="invalid">
                        {errors.primary_color}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FormGroup as={Col} xs="6" md="3">
                    <FormLabel htmlFor="edit-program.secondary_color">
                      Secondary colour
                    </FormLabel>
                    <FormControl
                      style={{ minWidth: "70px" }}
                      type="color"
                      name="secondary_color"
                      id="edit-program.secondary-color"
                      value={values.secondary_color}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={
                        touched.secondary_color && !errors.secondary_color
                      }
                      isInvalid={
                        touched.secondary_color && !!errors.secondary_color
                      }
                    />
                    {touched.secondary_color && (
                      <FormControl.Feedback
                        type="invalid"
                        controlId="edit-program.secondary_color"
                      >
                        {errors.secondary_color}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                </Row>
                <Row className="mb-3 g-3">
                  <Col md="4">
                    <FloatingLabel
                      controlId="edit-program.directed_at"
                      label="Directed at"
                    >
                      <FormSelect
                        name="directed_at"
                        placeholder="Directed at"
                        value={values.directed_at}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.directed_at && !errors.directed_at}
                        isInvalid={touched.directed_at && !!errors.directed_at}
                      >
                        <option value="girls">Girls</option>
                        <option value="boys">Boys</option>
                        <option value="others">Others</option>
                      </FormSelect>
                      {touched.directed_at && (
                        <FormControl.Feedback type="invalid">
                          {errors.directed_at}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md="8" className="d-flex flex-column">
                    <label>Age group</label>
                    <ReactSlider
                      className="react-slider-program"
                      thumbClassName="react-slider-program-thumb"
                      trackClassName="react-slider-program-track"
                      value={values.age_group
                        .split("-")
                        .map((n) => parseInt(n))}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      onChange={(e) => {
                        setFieldValue("age_group", e.join("-"));
                      }}
                      onBlur={handleBlur}
                      pearling
                      minDistance={1}
                      min={5}
                      max={25}
                    />
                  </Col>
                </Row>
                <Row className="mb-3 g-3">
                  <Col md="4">
                    <FloatingLabel
                      controlId="edit-program.creator"
                      label="Creator of program"
                    >
                      <FormControl
                        type="text"
                        name="creator"
                        placeholder="Creator of program"
                        value={values.creator}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.creator && !errors.creator}
                        isInvalid={touched.creator && !!errors.creator}
                      />
                      {touched.creator && (
                        <FormControl.Feedback type="invalid">
                          {errors.creator}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={props.handleEditClose}
                    className="me-2"
                  >
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    type="submit"
                    variant="primary"
                    disabled={props.editProgramLoading}
                  >
                    {props.editProgramLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save changes"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </Container>
  );
};
