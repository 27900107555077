import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Cookie from "js-cookie";

export const ProtectedRoute = () => {
  if (!!Cookie.get("ut_admin")) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};
