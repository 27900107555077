import React, { Component } from "react";
import { admin_api } from "../../atoms/api";
import BtContainer from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormSelect from "react-bootstrap/FormSelect";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import { PromoCodeSchema } from "../../atoms/schemas";
import styled from "styled-components";
import { formatDate } from "../../atoms/formatDate";

const Container = styled(BtContainer)`
  & > .toast-container {
    z-index: 1;
  }
`;

export class PromoCodes extends Component {
  constructor(props) {
    super();
    this.state = {
      add: false,
      addLoading: false,
      addSuccess: false,
      addError: null,
      getLoading: true,
      getError: null,
      data: [],
      edit: false,
      editId: null,
      editLoading: false,
      editSuccess: false,
      editError: null,
      delete: false,
      deleteId: null,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: null,
    };
  }

  componentDidMount() {
    admin_api
      .get(`/promo-code`)
      .then((res) => {
        this.setState({ getLoading: false, data: res.data });
      })
      .catch((err) =>
        this.setState({
          getLoading: false,
          getError: err?.response?.data?.message || "Error",
        })
      );
  }

  closeAddSuccess = () => this.setState({ addSuccess: false });
  closeEditSuccess = () => this.setState({ editSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteSuccess: false });

  dismissAddError = () => this.setState({ addError: null });
  dismissGetError = () => this.setState({ getError: null });
  dismissEditError = () => this.setState({ editError: null });
  dismissDeleteError = () => this.setState({ deleteError: null });

  handleAddOpen = () => this.setState({ add: true });
  handleAddClose = () => this.setState({ add: false });
  handleEditOpen = (id) => this.setState({ edit: true, editId: id });
  handleEditClose = () => this.setState({ edit: false, editId: null });
  handleDeleteOpen = (id) => this.setState({ delete: true, deleteId: id });
  handleDeleteClose = () => this.setState({ delete: false, deleteId: null });

  handleDelete = () => {
    this.setState({ delete: false, deleteLoading: true });
    admin_api
      .delete(`/promo-code/${this.state.deleteId}`)
      .then((res) => {
        let data = this.state.data.filter(
          (d) => d["id"] !== this.state.deleteId
        );
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteSuccess: true,
          data,
        });
      })
      .catch((err) =>
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteError: err?.response?.data?.message || "Error",
        })
      );
  };

  render() {
    return (
      <Container fluid={true} className="position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
          <Toast show={this.state.getLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting promo codes
            </ToastBody>
          </Toast>
          <Toast show={this.state.deleteLoading} bg="warning">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting code
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.addSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeAddSuccess}
          >
            <ToastBody className="text-white">
              Promo code added successfully
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.editSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeEditSuccess}
          >
            <ToastBody className="text-white">
              Changes saved successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteSuccess}
            bg="success"
            onClose={this.closeDeleteSuccess}
            delay={2000}
            autohide={true}
          >
            <ToastBody className="text-white">Deleted successfully.</ToastBody>
          </Toast>
          <Toast
            show={this.state.addError !== null}
            bg="danger"
            onClose={this.dismissAddError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Add error</strong>
            </ToastHeader>
            <ToastBody>{this.state.addError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.getError !== null}
            bg="danger"
            onClose={this.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.editError !== null}
            bg="danger"
            onClose={this.dismissEditError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Edit error</strong>
            </ToastHeader>
            <ToastBody>{this.state.editError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteError !== null}
            bg="danger"
            onClose={this.dismissDeleteError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Delete error</strong>
            </ToastHeader>
            <ToastBody>{this.state.deleteError}</ToastBody>
          </Toast>
        </ToastContainer>
        <Button
          type="button"
          variant="primary"
          className="text-white mb-3"
          onClick={this.handleAddOpen}
        >
          Add promo code
        </Button>
        <br />
        <Table hover={true} striped={true} responsive="sm" bordered={true}>
          <thead>
            <tr>
              <th>Code</th>
              <th>Value</th>
              <th>Expire On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((d, index) => (
              <tr key={index}>
                <td>{d?.code}</td>
                <td>
                  {d?.value + "" + (d?.type === "relative" ? "%" : "INR")}
                </td>
                <td>{formatDate(d?.expires_on, "Does not expire")}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row align-items-start">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => this.handleEditOpen(d["id"])}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="mt-2 mt-md-0 ms-md-2"
                      onClick={() => this.handleDeleteOpen(d["id"])}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={this.state.add} onHide={this.handleAddClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Add a new promo code</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                code: "",
                type: "absolute",
                expires_on: "",
                value: "",
              }}
              validationSchema={PromoCodeSchema}
              onSubmit={(values) => {
                admin_api
                  .post("/promo-code", {
                    ...values,
                    value: parseInt(values.value),
                    expires_on: values.expires_on ? values.expires_on : null,
                  })
                  .then((res) => {
                    let data = this.state.data;
                    data.push(res.data);
                    this.setState({
                      add: false,
                      addLoading: false,
                      addSuccess: true,
                      data,
                    });
                  })
                  .catch((err) => {
                    this.setState({
                      add: false,
                      addLoading: false,
                      addError: err?.response?.data?.message || "Error",
                    });
                  });
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="Promo code name"
                    controlId="add-promo-code.code"
                    className="mb-2"
                  >
                    <FormControl
                      name="code"
                      placeholder="Promo code"
                      required={true}
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.code && !errors.code}
                      isInvalid={touched.code && !!errors.code}
                    />
                    {touched.code && (
                      <FormControl.Feedback type="invalid">
                        {errors.code}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <Row className="mb-2 g-2">
                    <Col>
                      <InputGroup className="mb-2">
                        <FormControl
                          name="value"
                          placeholder="Value of discount"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.value && !errors.value}
                          isInvalid={touched.value && !!errors.value}
                          className="col-10"
                        />
                        <FormSelect
                          name="type"
                          value={values.type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.type && !errors.type}
                          isInvalid={touched.type && !!errors.type}
                          className="col-2"
                        >
                          <option value="relative">%</option>
                          <option value="absolute">INR</option>
                        </FormSelect>
                        {touched.type && (
                          <FormControl.Feedback type="invalid">
                            {errors.type}
                          </FormControl.Feedback>
                        )}
                        {touched.value && (
                          <FormControl.Feedback type="invalid">
                            {errors.value}
                          </FormControl.Feedback>
                        )}
                      </InputGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-2">
                        <FormControl
                          type="date"
                          name="expires_on"
                          value={values.expires_on}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.expires_on && !errors.expires_on}
                          isInvalid={touched.expires_on && !!errors.expires_on}
                        />
                        {touched.expires_on && (
                          <FormControl.Feedback type="invalid">
                            {errors.expires_on}
                          </FormControl.Feedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleAddClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="text-white"
                      disabled={this.state.addLoading}
                    >
                      {this.state.addLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Adding...",
                          ]
                        : "Add"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.edit} onHide={this.handleEditClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Edit code</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.data.find(
                (d) => d["id"] === this.state.editId
              )}
              validationSchema={PromoCodeSchema}
              onSubmit={(values) => {
                admin_api
                  .patch(`/promo-code/${this.state.editId}`, {
                    ...values,
                    value: values.value,
                    expires_on: values.expires_on ? values.expires_on : null,
                  })
                  .then((res) => {
                    let data = this.state.data.map((d) => {
                      if (d.id === this.state.editId) {
                        return res.data;
                      }
                      return d;
                    });
                    this.setState({
                      edit: false,
                      editId: null,
                      editLoading: false,
                      editSuccess: true,
                      data,
                    });
                  })
                  .catch((err) =>
                    this.setState({
                      edit: false,
                      editId: null,
                      editLoading: false,
                      editError: err?.response?.data?.message || "Error",
                    })
                  );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                values,
                errors,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="Promo code name"
                    controlId="edit-promo-code.code"
                    className="mb-2"
                  >
                    <FormControl
                      name="code"
                      placeholder="Promo code name"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.code && !errors.code}
                      isInvalid={touched.code && !!errors.code}
                    />
                    {touched.code && (
                      <FormControl.Feedback type="invalid">
                        {errors.code}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <Row className="mb-2 g-2">
                    <Col>
                      <InputGroup className="mb-2">
                        <FormControl
                          name="value"
                          value={values.value}
                          placeholder="Value of discount"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.value && !errors.value}
                          isInvalid={touched.value && !!errors.value}
                        />
                        <FormSelect
                          name="type"
                          value={values.type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.type && !errors.type}
                          isInvalid={touched.type && !!errors.type}
                        >
                          <option value="relative">%</option>
                          <option value="absolute">INR</option>
                        </FormSelect>
                      </InputGroup>
                    </Col>
                    <Col>
                      <FormControl
                        type="date"
                        name="expires_on"
                        value={values.expires_on}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.expires_on && !errors.expires_on}
                        isInvalid={touched.expires_on && !!errors.expires_on}
                      />
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleEditClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={this.state.editLoading}
                      className="text-white"
                    >
                      {this.state.editLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Saving...",
                          ]
                        : "Save changes"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.delete} onHide={this.handleDeleteClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Delete this code?</ModalTitle>
          </ModalHeader>
          <ModalBody>Are you sure you want to delete this code?</ModalBody>
          <ModalFooter>
            <Button
              type="button"
              variant="secondary"
              onClick={this.handleDeleteClose}
            >
              Close
            </Button>
            <Button tyoe="button" variant="danger" onClick={this.handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
