import React, { useState } from "react";
import { admin_api } from "../../atoms/api";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import * as yup from "yup";
import Cookie from "js-cookie";

export const SignIn = (props) => {
  const navigator = useNavigate();
  const [signInLoading, setSignInLoading] = useState(false);
  const [signInError, setSignInError] = useState(null);

  const signInSchema = yup.object().shape({
    email_id: yup
      .string()
      .email("Invalid email")
      .required("Email ID is required"),
    password: yup.string().required("Password is required"),
  });

  const removeSignInError = () => setSignInError(null);

  return (
    <Formik
      initialValues={{ email_id: "", password: "" }}
      validationSchema={signInSchema}
      onSubmit={(values) => {
        setSignInLoading(true);
        setSignInError(null);
        admin_api
          .post("/auth/admin/login", values)
          .then((res) => {
            setSignInLoading(false);
            Cookie.set("ut_admin", JSON.stringify(res["data"]));
            navigator("/");
          })
          .catch((err) => {
            setSignInLoading(false);
            setSignInError(err?.response?.data?.message || "Error");
          });
      }}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        errors,
        values,
      }) => (
        <Form noValidate={true} onSubmit={handleSubmit}>
          <Alert
            variant="danger"
            show={signInError !== null}
            dismissible={true}
            onClose={removeSignInError}
          >
            {signInError}
          </Alert>
          <FloatingLabel
            className="mb-3"
            controlId="sign-up.email_id"
            label="Email"
          >
            <FormControl
              type="email"
              name="email_id"
              placeholder="Enter your email ID"
              value={values.email_id}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              autoComplete="current-email"
              isValid={touched.email_id && !errors.email_id}
              isInvalid={touched.email_id && !!errors.email_id}
            />
            {touched.email_id && (
              <FormControl.Feedback type="invalid">
                {errors.email_id}
              </FormControl.Feedback>
            )}
          </FloatingLabel>
          <FloatingLabel
            className="mb-3"
            controlId="sign-up.password"
            label="Password"
          >
            <FormControl
              type="password"
              name="password"
              placeholder="Enter your password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              autoComplete="current-password"
              isValid={touched.password && !errors.password}
              isInvalid={touched.password && !!errors.password}
            />
            {touched.password && (
              <FormControl.Feedback type="invalid">
                {errors.password}
              </FormControl.Feedback>
            )}
          </FloatingLabel>
          <Button variant="primary" type="submit" disabled={signInLoading}>
            {signInLoading
              ? [
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />,
                  " Logging in...",
                ]
              : "Login"}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
