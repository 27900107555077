import React, { Component } from "react";
import { admin_api } from "../../atoms/api";
import BtContainer from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import ToastBody from "react-bootstrap/ToastBody";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { FieldArray, Formik } from "formik";
import { InfoSpan } from "../../pages/program";
import {
  DictionarySchema,
  LearnFooter,
  LearnHeading,
  LearnSchema,
} from "../../atoms/schemas";
import { UtImg } from "../../atoms/img";
import styled from "styled-components";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TextEditor } from "../../molecule/editorComponent";

const Container = styled(BtContainer)`
  & > .toast-container {
    z-index: 1;
  }
`;

export class Learn extends Component {
  constructor(props) {
    super();
    this.state = {
      add: false,
      addLoading: false,
      addSuccess: false,
      addError: null,
      getLoading: true,
      getError: null,
      level: {},
      data: [],
      edit: false,
      editId: null,
      editLoading: false,
      editSuccess: false,
      editError: null,
      delete: false,
      deleteId: null,
      deleteLoading: false,
      deleteSuccess: false,
      deleteError: null,
      // dictionary
      getDictionary: true,
      getDictionaryError: null,
      updateDictionary: false,
      updateDictionarySuccess: false,
      updateDictionaryError: null,
      showDictionary: false,
      dictionaryWords: [],
    };
  }

  componentDidMount() {
    admin_api
      .get(`/levels/${this.props.levelId}/learn`)
      .then((res) => {
        let { learn, ...level } = res.data;
        learn = learn.sort(
          (a, b) =>
            level.learn_sequence.indexOf(a["id"]) -
            level.learn_sequence.indexOf(b["id"])
        );
        this.setState({ getLoading: false, level, data: learn });
      })
      .catch((err) =>
        this.setState({
          getLoading: false,
          getError: err?.response?.data?.message || "Error",
        })
      );
    admin_api
      .get(`/dictionary/level/${this.props.levelId}`)
      .then((res) =>
        this.setState({ getDictionary: false, dictionaryWords: res.data })
      )
      .catch((err) =>
        this.setState({
          getDictionary: false,
          getDictionaryError: err?.response?.data?.message || "Error",
        })
      );
  }

  closeAddSuccess = () => this.setState({ addSuccess: false });
  closeEditSuccess = () => this.setState({ editSuccess: false });
  closeDeleteSuccess = () => this.setState({ deleteSuccess: false });
  closeDictionaryUpdateSuccess = () =>
    this.setState({ updateDictionarySuccess: false });

  dismissAddError = () => this.setState({ addError: null });
  dismissGetError = () => this.setState({ getError: null });
  dismissEditError = () => this.setState({ editError: null });
  dismissDeleteError = () => this.setState({ deleteError: null });
  dismissGetDictionaryError = () => this.setState({ getDictionaryError: null });
  dismissUpdateDictionaryError = () =>
    this.setState({ updateDictionaryError: null });

  handleAddOpen = () => this.setState({ add: true });
  handleAddClose = () => this.setState({ add: false });
  handleEditOpen = (id) => this.setState({ edit: true, editId: id });
  handleEditClose = () => this.setState({ edit: false, editId: null });
  handleDeleteOpen = (id) => this.setState({ delete: true, deleteId: id });
  handleDeleteClose = () => this.setState({ delete: false, deleteId: null });

  handleDelete = () => {
    this.setState({ delete: false, deleteLoading: true });
    admin_api
      .delete(`/learn/${this.state.deleteId}`)
      .then((res) => {
        let data = this.state.data.filter(
          (l) => l["id"] !== this.state.deleteId
        );
        let learn_sequence = this.state.level?.learn_sequence || [];
        let index = learn_sequence.indexOf(this.state.deleteId);
        learn_sequence = learn_sequence.splice(index, 1);
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteSuccess: true,
          data,
          level: {
            ...this.state.level,
            learn_sequence,
          },
        });
      })
      .catch((err) =>
        this.setState({
          deleteId: null,
          deleteLoading: false,
          deleteError: err?.response?.data?.message || "Error",
        })
      );
  };

  handleDictionaryOpen = () => this.setState({ showDictionary: true });

  handleDictionaryClose = () => this.setState({ showDictionary: false });

  dragStartHandler = (e) => {
    e.dataTransfer.setData("learnId", e.target.getAttribute("data-learnid"));
    e.dataTransfer.setData("pickIndex", e.target.getAttribute("data-rowindex"));
    e.dataTransfer.dropEffect = "move";
  };

  dragOverHandler = (e) => {
    e.preventDefault();
  };

  dropHandler = (e) => {
    e.preventDefault();
    this.setState({ editLoading: true });
    const dropRow = e.currentTarget.getAttribute("data-rowindex");
    const learnId = e.dataTransfer.getData("learnId");
    admin_api
      .patch(
        `/levels/${this.props.levelId}/learn-sequence/learn/${learnId}/index/${dropRow}`
      )
      .then((res) => {
        let newLearnSequence = res.data;
        this.setState({
          editLoading: false,
          editSuccess: true,
          level: {
            ...this.state.level,
            learn_sequence: newLearnSequence,
          },
          data: this.state.data.sort(
            (a, b) =>
              newLearnSequence.indexOf(a["id"]) -
              newLearnSequence.indexOf(b["id"])
          ),
        });
      })
      .catch((err) =>
        this.setState({
          editLoading: false,
          editError: err?.response?.data?.message || "Error",
        })
      );
  };

  render() {
    return (
      <Container fluid={true} className="pt-3 position-relative">
        <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
          <Toast show={this.state.getLoading} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting sections info
            </ToastBody>
          </Toast>
          <Toast show={this.state.deleteLoading} bg="warning">
            <ToastBody>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting section
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.addSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeAddSuccess}
          >
            <ToastBody className="text-white">
              Section added successfully
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.editSuccess}
            bg="success"
            delay={2000}
            autohide={true}
            onClose={this.closeEditSuccess}
          >
            <ToastBody className="text-white">
              Changes saved successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteSuccess}
            bg="success"
            onClose={this.closeDeleteSuccess}
            delay={2000}
            autohide={true}
          >
            <ToastBody className="text-white">Deleted successfully.</ToastBody>
          </Toast>
          <Toast
            show={this.state.addError !== null}
            bg="danger"
            onClose={this.dismissAddError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Add error</strong>
            </ToastHeader>
            <ToastBody>{this.state.addError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.getError !== null}
            bg="danger"
            onClose={this.dismissGetError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.editError !== null}
            bg="danger"
            onClose={this.dismissEditError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Edit error</strong>
            </ToastHeader>
            <ToastBody>{this.state.editError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.deleteError !== null}
            bg="danger"
            onClose={this.dismissDeleteError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Delete error</strong>
            </ToastHeader>
            <ToastBody>{this.state.deleteError}</ToastBody>
          </Toast>
          <Toast show={this.state.getDictionary} bg="info">
            <ToastBody className="text-white">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Getting dictionary words
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.updateDictionarySuccess}
            bg="success"
            onClose={this.closeDictionaryUpdateSuccess}
            delay={2000}
            autohide={true}
          >
            <ToastBody className="text-white">
              Dictionary words updated successfully.
            </ToastBody>
          </Toast>
          <Toast
            show={this.state.getDictionaryError !== null}
            bg="danger"
            onClose={this.dismissGetDictionaryError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Fetch dictionary words error</strong>
            </ToastHeader>
            <ToastBody>{this.state.getDictionaryError}</ToastBody>
          </Toast>
          <Toast
            show={this.state.updateDictionaryError !== null}
            bg="danger"
            onClose={this.dismissUpdateDictionaryError}
          >
            <ToastHeader closeButton={true}>
              <strong className="me-auto">Update dictionary words error</strong>
            </ToastHeader>
            <ToastBody>{this.state.updateDictionaryError}</ToastBody>
          </Toast>
        </ToastContainer>
        <Button
          type="button"
          variant="primary"
          className="text-white mb-2 mb-sm-0 me-2"
          onClick={this.handleAddOpen}
        >
          Add learn section
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={this.handleDictionaryOpen}
        >
          Dictionary words
        </Button>
        <br />
        <Formik
          initialValues={{ learnHeading: this.state.level?.learnHeading || "" }}
          validationSchema={LearnHeading}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.setState({ editLoading: true });
            admin_api
              .patch(`/levels/${this.state.level?.id}`, values)
              .then((res) =>
                this.setState({
                  editLoading: false,
                  editSuccess: true,
                  level: res.data,
                })
              )
              .catch((err) =>
                this.setState({
                  editLoading: false,
                  editError: err?.response?.data?.message || "Error",
                })
              );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            initialValues,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate={true} onSubmit={handleSubmit} className="mt-3">
              <FloatingLabel
                label="Learn section heading"
                controlId="level-learnHeading"
                className="mb-2"
              >
                <FormControl
                  as="textarea"
                  style={{ minHeight: "100px" }}
                  name="learnHeading"
                  value={values.learnHeading}
                  placeholder="Learn section heading"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  isValid={touched.learnHeading && !errors.learnHeading}
                  isInvalid={touched.learnHeading && !!errors.learnHeading}
                />
                {touched.learnHeading && (
                  <FormControl.Feedback type="invalid">
                    {errors.learnHeading}
                  </FormControl.Feedback>
                )}
              </FloatingLabel>
              {initialValues.learnHeading !== values.learnHeading && (
                <>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="me-2"
                    disabled={this.state.editLoading}
                  >
                    {this.state.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save"}
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        "learnHeading",
                        initialValues.learnHeading || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
        <InfoSpan>Drag row to change section sequence</InfoSpan>
        <Table hover={true} striped={true} responsive="sm" bordered={true}>
          <thead>
            <tr>
              <th>Heading</th>
              <th>Content</th>
              <th>Image</th>
              <th>Bg color</th>
              <th>Bg accent color</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((l, index) => (
              <tr
                key={index}
                draggable={true}
                data-rowindex={index}
                data-learnid={l["id"]}
                onDragStartCapture={this.dragStartHandler}
                onDragOverCapture={this.dragOverHandler}
                onDropCapture={this.dropHandler}
              >
                <td>{l.heading}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: l?.text,
                  }}
                ></td>
                <td>
                  <UtImg
                    src={l.image?.filePath}
                    alt={l.image?.file}
                    width="100px"
                    height="auto"
                  />
                </td>
                <td>
                  <FormControl
                    type="color"
                    disabled={true}
                    value={l.background_color}
                  />
                </td>
                <td>
                  <FormControl
                    type="color"
                    disabled={true}
                    value={l?.background_accent_color}
                  />
                </td>
                <td>
                  <div className="d-flex flex-column flex-md-row align-items-start">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => this.handleEditOpen(l["id"])}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="mt-2 mt-md-0 ms-md-2"
                      onClick={() => this.handleDeleteOpen(l["id"])}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Formik
          initialValues={{ learnFooter: this.state.level?.learnFooter || "" }}
          validationSchema={LearnFooter}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.setState({ editLoading: true });
            admin_api
              .patch(`/levels/${this.state.level?.id}`, values)
              .then((res) =>
                this.setState({
                  editLoading: false,
                  editSuccess: true,
                  level: res.data,
                })
              )
              .catch((err) =>
                this.setState({
                  editLoading: false,
                  editError: err?.response?.data?.message || "Error",
                })
              );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            initialValues,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate={true} onSubmit={handleSubmit}>
              <FloatingLabel
                label="Learn section footer"
                controlId="level-learnFooter"
                className="mb-2"
              >
                <FormControl
                  as="textarea"
                  style={{ minHeight: "100px" }}
                  name="learnFooter"
                  value={values.learnFooter}
                  placeholder="Learn section footer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  isValid={touched.learnFooter && !errors.learnFooter}
                  isInvalid={touched.learnFooter && !!errors.learnFooter}
                />
                {touched.learnFooter && (
                  <FormControl.Feedback type="invalid">
                    {errors.learnFooter}
                  </FormControl.Feedback>
                )}
              </FloatingLabel>
              {initialValues.learnFooter !== values.learnFooter && (
                <>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="me-2"
                    disabled={this.state.editLoading}
                  >
                    {this.state.editLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Saving...",
                        ]
                      : "Save"}
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        "learnFooter",
                        initialValues.learnFooter || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
        <Modal show={this.state.add} onHide={this.handleAddClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Add a new learn section</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                level_id: this.props.levelId,
                heading: "",
                text: "",
                image: undefined,
                background_color: "",
                background_accent_color: "",
              }}
              validationSchema={LearnSchema}
              onSubmit={(values) => {
                if (!!values.image && !values.image?.type.match(/image/)) {
                  // do nothing
                } else {
                  this.setState({ addLoading: true });
                  let values_form_data = new FormData();
                  Object.keys(values).forEach((key) =>
                    values_form_data.append(key, values[key])
                  );
                  admin_api
                    .post("/learn", values_form_data)
                    .then((res) => {
                      let data = this.state.data;
                      data.push(res.data);
                      let learn_sequence = this.state.level?.learn_sequence;
                      learn_sequence.push(res.data?.id);
                      this.setState({
                        add: false,
                        addLoading: false,
                        addSuccess: true,
                        data,
                        level: {
                          ...this.state.level,
                          learn_sequence,
                        },
                      });
                    })
                    .catch((err) => {
                      this.setState({
                        add: false,
                        addLoading: false,
                        addError: err?.response?.data?.message || "Error",
                      });
                    });
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setTouched,
                setStatus,
                touched,
                errors,
                values,
                status,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FloatingLabel
                    label="Section heading"
                    controlId="add-learn.heading"
                    className="mb-2"
                  >
                    <FormControl
                      name="heading"
                      placeholder="Section heading"
                      value={values.heading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.heading && !errors.heading}
                      isInvalid={touched.heading && !!errors.heading}
                    />
                    {touched.heading && (
                      <FormControl.Feedback type="invalid">
                        {errors.heading}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  {/* <FloatingLabel
                    label="Section content"
                    controlId="add-learn.text"
                    className="mb-2"
                  >
                    <FormControl
                      style={{ minHeight: "100px" }}
                      as="textarea"
                      name="text"
                      placeholder="Section content"
                      value={values.text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.text && !errors.text}
                      isInvalid={touched.text && !!errors.text}
                    />
                    {touched.text && (
                      <FormControl.Feedback type="invalid">
                        {errors.text}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel> */}
                  <FloatingLabel controlId="add-learn.text" className="mb-2">
                    <TextEditor
                      placeholder="Section content"
                      value={values.text}
                      setFieldValue={(val) => {
                        setFieldValue("text", val);
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, text: true });
                      }}
                    />
                    {touched.text && (
                      <FormControl.Feedback type="invalid">
                        {errors.text}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormGroup controlId="add-learn.image" className="mb-2">
                    <FormLabel>Section image</FormLabel>
                    <FormControl
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("image", file);
                        if (file["type"].match(/image/)) {
                          setStatus({ image: null });
                        } else {
                          setStatus({ image: "File should be an image" });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, image: true });
                      }}
                      isValid={touched.image && !status?.image}
                      isInvalid={touched.image && !!status?.image}
                    />
                    {touched.image && (
                      <FormControl.Feedback type="invalid">
                        {status?.image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FormGroup controlId="edit-learn.audio" className="mb-2">
                    <FormLabel>
                      Audio
                      <br />
                      {values?.Audio?.file}
                    </FormLabel>
                    {values?.image?.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("audio", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      type="file"
                      name="audio"
                      accept="audio/*"
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("audio", file);
                        if (
                          ("type" in file && file.type.match(/audio/)) ||
                          ("fileType" in file && file.fileType.match(/audio/))
                        ) {
                          setStatus({ image: null });
                        } else {
                          setStatus({
                            audio: "File should be an audio",
                          });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, audio: true });
                      }}
                      isValid={touched.audio && !status?.audio}
                      isInvalid={touched.audio && !!status?.audio}
                    />
                    {touched.audio && (
                      <FormControl.Feedback type="invalid">
                        {status?.audio}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <Row className="mb-2 g-2">
                    <Col>
                      <FormGroup controlId="add-learn.background_color">
                        <FormLabel>Background</FormLabel>
                        <FormControl
                          style={{ minWidth: "70px" }}
                          type="color"
                          name="background_color"
                          value={values.background_color}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={
                            touched.background_color && !errors.background_color
                          }
                          isInvalid={
                            touched.background_color &&
                            !!errors.background_color
                          }
                        />
                        {touched.background_color && (
                          <FormControl.Feedback type="invalid">
                            {errors.background_color}
                          </FormControl.Feedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup controlId="add-learn.background_accent_color">
                        <FormLabel>Background accent</FormLabel>
                        <FormControl
                          style={{ minWidth: "70px" }}
                          name="background_accent_color"
                          type="color"
                          value={values.background_accent_color}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={
                            touched.background_accent_color &&
                            !errors.background_accent_color
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleAddClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="text-white"
                      disabled={this.state.addLoading}
                    >
                      {this.state.addLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Adding...",
                          ]
                        : "Add"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.edit} onHide={this.handleEditClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Edit section</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.data.find(
                (l) => l["id"] === this.state.editId
              )}
              validationSchema={LearnSchema}
              onSubmit={(values, { setStatus }) => {
                if (
                  (!!values?.image &&
                    "type" in values.image &&
                    !values.image?.type.match(/image/)) ||
                  (!!values?.image &&
                    "fileType" in values &&
                    !values.image?.fileType.match(/image/))
                ) {
                  // do nothing
                } else {
                  this.setState({ editLoading: true });
                  let values_form_data = new FormData();
                  Object.keys(values).forEach((key) =>
                    values_form_data.append(key, values[key])
                  );
                  admin_api
                    .put(`/learn/${this.state.editId}`, values_form_data)
                    .then((res) => {
                      let data = this.state.data.map((l) => {
                        if (l.id === this.state.editId) {
                          return res.data;
                        }
                        return l;
                      });
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editSuccess: true,
                        data,
                      });
                    })
                    .catch((err) =>
                      this.setState({
                        edit: false,
                        editId: null,
                        editLoading: false,
                        editError: err?.response?.data?.message || "Error",
                      })
                    );
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setStatus,
                setTouched,
                touched,
                values,
                errors,
                status,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  {console.log(errors)}
                  <FloatingLabel
                    label="Section heading"
                    controlId="edit-learn.heading"
                    className="mb-2"
                  >
                    <FormControl
                      name="heading"
                      placeholder="Section heading"
                      value={values.heading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.heading && !errors.heading}
                      isInvalid={touched.heading && !!errors.heading}
                    />
                    {touched.heading && (
                      <FormControl.Feedback type="invalid">
                        {errors.heading}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  {/* <FloatingLabel
                    label="Section content"
                    controlId="edit-learn.text"
                    className="mb-2"
                  >
                    <FormControl
                      style={{ minHeight: "100px" }}
                      name="text"
                      placeholder="Section content"
                      as="textarea"
                      value={values.text}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.text && !errors.text}
                      isInvalid={touched.text && !!errors.text}
                    />
                    {touched.text && (
                      <FormControl.Feedback type="invalid">
                        {errors.text}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel> */}
                  <FloatingLabel controlId="edit-learn.text" className="mb-2">
                    <TextEditor
                      placeholder="Section content"
                      value={values.text}
                      setFieldValue={(val) => {
                        setFieldValue("text", val);
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, text: true });
                      }}
                    />
                    {touched.text && (
                      <FormControl.Feedback type="invalid">
                        {errors.text}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <FormGroup controlId="edit-learn.image" className="mb-2">
                    <FormLabel>
                      Image
                      <br />
                      {values?.image?.file}
                    </FormLabel>
                    {values?.image?.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("image", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      type="file"
                      name="image"
                      onChange={(e) => {
                        let file = e.currentTarget.files[0];
                        setFieldValue("image", file);
                        if (
                          ("type" in file && file.type.match(/image/)) ||
                          ("fileType" in file && file.fileType.match(/image/))
                        ) {
                          setStatus({ image: null });
                        } else {
                          setStatus({ image: "File should be an image" });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, image: true });
                      }}
                      isValid={touched.image && !status?.image}
                      isInvalid={touched.image && !!status?.image}
                    />
                    {touched.image && (
                      <FormControl.Feedback type="invalid">
                        {status?.image}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <FormGroup controlId="edit-learn.audio" className="mb-2">
                    <FormLabel>
                      Audio
                      <br />
                      {values?.audio?.file}
                    </FormLabel>
                    {values?.audio?.file && (
                      <InfoSpan
                        onClick={() => {
                          setFieldValue("audio", "");
                        }}
                      >
                        &nbsp;Clear
                      </InfoSpan>
                    )}
                    <FormControl
                      type="file"
                      name="audio"
                      accept="audio/*"
                      onChange={(e) => {
                        // done
                        let file = e.currentTarget.files[0];
                        setFieldValue("audio", file);
                        if (
                          ("type" in file && file.type.match(/audio/)) ||
                          ("fileType" in file && file.fileType.match(/audio/))
                        ) {
                          setStatus({ audio: null });
                        } else {
                          setStatus({
                            audio: "File should be an audio",
                          });
                        }
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, audio: true });
                      }}
                      isValid={touched.audio && !status?.audio}
                      isInvalid={touched.audio && !!status?.audio}
                    />
                    {touched.audio && (
                      <FormControl.Feedback type="invalid">
                        {status?.audio}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  <Row className="mb-2 g-2">
                    <FormGroup as={Col} controlId="edit-learn.background_color">
                      <FormLabel>Background</FormLabel>
                      <FormControl
                        style={{ minWidth: "70px" }}
                        type="color"
                        name="background_color"
                        value={values.background_color}
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.background_color && !errors.background_color
                        }
                        isInvalid={
                          touched.background_color && !!errors.background_color
                        }
                      />
                      {touched.background_color && (
                        <FormControl.Feedback type="invalid">
                          {errors.background_color}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup
                      as={Col}
                      controlId="edit-learn.background_accent_color"
                    >
                      <FormLabel>Background</FormLabel>
                      <FormControl
                        style={{ minWidth: "70px" }}
                        type="color"
                        name="background_accent_color"
                        value={values.background_accent_color}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.background_accent_color &&
                          !errors.background_accent_color
                        }
                        isInvalid={
                          touched.background_accent_color &&
                          !!errors.background_accent_color
                        }
                      />
                      {touched.background_accent_color && (
                        <FormControl.Feedback type="invalid">
                          {errors.background_accent_color}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={this.handleEditClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={this.state.editLoading}
                      className="text-white"
                    >
                      {this.state.editLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            " Saving...",
                          ]
                        : "Save changes"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal show={this.state.delete} onHide={this.handleDeleteClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Delete this section?</ModalTitle>
          </ModalHeader>
          <ModalBody>Are you sure you want to delete this section?</ModalBody>
          <ModalFooter>
            <Button
              type="button"
              variant="secondary"
              onClick={this.handleDeleteClose}
            >
              Close
            </Button>
            <Button tyoe="button" variant="danger" onClick={this.handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          show={this.state.showDictionary}
          onHide={this.handleDictionaryClose}
          size="lg"
        >
          <ModalHeader closeButton={true}>
            <ModalTitle>Dictionary words</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              onSubmit={(values) => {
                this.setState({ updateDictionary: true });
                admin_api
                  .post("/dictionary/bulk", values)
                  .then((res) =>
                    this.setState({
                      updateDictionary: false,
                      updateDictionarySuccess: true,
                      dictionaryWords: res.data,
                    })
                  )
                  .catch((err) =>
                    this.setState({
                      updateDictionary: false,
                      updateDictionaryError:
                        err?.response?.data?.message || "Error",
                    })
                  );
              }}
              initialValues={{
                level_id: parseInt(this.props.levelId),
                words: this.state.dictionaryWords,
              }}
              enableReinitialize={true}
              validationSchema={DictionarySchema}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                touched,
                errors,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <FieldArray
                    name="words"
                    render={({ insert, remove, push }) => (
                      <div>
                        {values.words && values.words.length > 0 ? (
                          values.words.map((word, index) => {
                            return (
                              <Row className="g-2 mb-3" key={index}>
                                <FormGroup as={Col} md="2">
                                  <FloatingLabel
                                    label="Word"
                                    controlId={`words.${index}.word`}
                                  >
                                    <FormControl
                                      name={`words.${index}.word`}
                                      placeholder="Word"
                                      required={true}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={word.word}
                                      isValid={
                                        "words" in touched &&
                                        touched.words[index]?.word &&
                                        (!("words" in errors) ||
                                          ("words" in errors &&
                                            !errors.words[index]?.word))
                                      }
                                      isInvalid={
                                        "words" in touched &&
                                        touched.words[index]?.word &&
                                        "words" in errors &&
                                        !!errors.words[index]?.word
                                      }
                                    />
                                    {"words" in touched &&
                                      touched.words[index]?.word && (
                                        <FormControl.Feedback type="invalid">
                                          {"words" in errors &&
                                            errors.words[index]?.word}
                                        </FormControl.Feedback>
                                      )}
                                  </FloatingLabel>
                                </FormGroup>
                                <FormGroup as={Col} md="6">
                                  <FloatingLabel
                                    label="Meaning"
                                    controlId={`words.${index}.meaning`}
                                  >
                                    <FormControl
                                      name={`words.${index}.meaning`}
                                      placeholder="Meaning"
                                      required={true}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={word.meaning}
                                      isValid={
                                        "words" in touched &&
                                        touched.words[index]?.meaning &&
                                        (!("words" in errors) ||
                                          ("words" in errors &&
                                            !errors.words[index]?.meaning))
                                      }
                                      isInvalid={
                                        "words" in touched &&
                                        touched.words[index]?.meaning &&
                                        "words" in errors &&
                                        !!errors.words[index]?.meaning
                                      }
                                    />
                                    {"words" in touched &&
                                      touched.words[index]?.meaning && (
                                        <FormControl.Feedback type="invalid">
                                          {"words" in errors &&
                                            errors.words[index]?.meaning}
                                        </FormControl.Feedback>
                                      )}
                                  </FloatingLabel>
                                </FormGroup>
                                <Col md="4">
                                  <Button
                                    variant="secondary"
                                    type="button"
                                    className="me-2"
                                    onClick={() =>
                                      insert(index + 1, {
                                        word: "",
                                        meaning: "",
                                      })
                                    }
                                  >
                                    Add
                                  </Button>
                                  <Button
                                    variant="danger"
                                    type="button"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Col>
                              </Row>
                            );
                          })
                        ) : (
                          <>
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={() =>
                                push({
                                  word: "",
                                  meaning: "",
                                })
                              }
                              className="mb-2"
                            >
                              Add new word
                            </Button>
                            <br />
                          </>
                        )}
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={this.state.updateDictionary}
                        >
                          {this.state.updateDictionary
                            ? [
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />,
                                " Saving...",
                              ]
                            : "Save changes"}
                        </Button>
                      </div>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}
